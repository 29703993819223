import React, { KeyboardEventHandler, PropsWithChildren } from 'react';
import {
  AutocompleteInput,
  Datagrid,
  DateField,
  Filter,
  List,
  ReferenceField,
  ReferenceInput,
  SelectField,
  SelectInput,
  ShowButton,
  TextField,
  TextInput,
  FunctionField,
  Record,
} from 'react-admin';
import { SaleAmount } from '~business/sale/SaleAmount';
import { SalePaymentMethodField } from '~business/sale/SalePaymentMethodField';
import { SaleStatusField } from '~business/sale/SaleStatusField';
import {
  paymentMethodChoices,
  saleStatusChoices,
  saleTypeChoices,
  sportChoices,
} from '~business/sale/common';
import { SaleField } from '~technical/filters/sale';
import { UserField } from '~technical/filters/user';

const SaleFilter = ({ children, ...props }: PropsWithChildren<{}>) => {
  const handleKeyPress: KeyboardEventHandler = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  };

  return (
    /* TODO: fix types */
    /* @ts-ignore */
    <Filter {...props} onKeyPress={handleKeyPress}>
      <ReferenceInput
        reference="user"
        source="user"
        filterToQuery={(search: string) => ({
          [UserField.USERNAME]: search,
        })}
      >
        <AutocompleteInput optionText="username" optionValue="id" />
      </ReferenceInput>
      <SelectInput label="Status" source="status" choices={saleStatusChoices} />
      <SelectInput label="Type" source="type" choices={saleTypeChoices} />
      <SelectInput label="Sport" source="sport" choices={sportChoices} />
      <SelectInput
        label="Method"
        source="paymentMethod"
        choices={paymentMethodChoices}
      />
      <TextInput
        source={SaleField.PAYMENT_INTENT_ID}
        label="Payment intent (hash or id)"
      />
      {children}
    </Filter>
  );
};

export const SaleList = (props: {}) => (
  <List
    {...props}
    filters={<SaleFilter />}
    sort={{ field: 'createdAt', order: 'DESC' }}
    bulkActionButtons={false}
  >
    <Datagrid>
      <SaleStatusField label="Status" />
      <SelectField source="sport" choices={sportChoices} />
      <SelectField source="type" choices={saleTypeChoices} />

      <ReferenceField source="user" reference="user">
        <TextField source="username" />
      </ReferenceField>

      <SalePaymentMethodField label="Method" />

      <SaleAmount label="Amount" />

      <DateField showTime source="createdAt" />

      <FunctionField
        label="First error"
        render={(record?: Record) => {
          const message = record?.saleErrors?.[0]?.message;
          if (!message) {
            return null;
          }
          const croppedMessage = message.substr(0, 200);
          return `${croppedMessage}${
            croppedMessage.length < message.length ? '...' : ''
          }`;
        }}
      />

      <ShowButton />
    </Datagrid>
  </List>
);
