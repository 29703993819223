import { processGenericFilter, Value } from './generic';

export enum TeamField {
  JOKER = 'q',
  NAME = 'name',
  LICENSED = 'licensed',
  MISSING_DATA = 'missing_data',
}

export function processTeamFilter(field: TeamField | string, value: Value) {
  switch (field) {
    case TeamField.JOKER:
      return {
        $or: [{ name: { $regex: value, $options: 'i' } }, { code: value }],
      };
    case TeamField.NAME:
      return { name: { $regex: value, $options: 'i' } };
    case TeamField.MISSING_DATA:
      return {
        logo: null,
      };
    default:
      return processGenericFilter(field, value);
  }
}
