import React from 'react';
import { Show, SimpleShowLayout } from 'react-admin';
import styled from 'styled-components';

import { BlockchainButton } from './buttons/BlockchainButton';
import { IPFSButton } from './buttons/IPFSButton';
import { MetadataButton } from './buttons/MetadataButton';
import { SyncButton } from './buttons/SyncButton';
import { TransferNftButton } from './buttons/TransferNftButton';
import { IRecordNFT, IGeneratedCard, isGeneratedCard } from './interfaces';
import { CardItem } from './item';

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 2rem 1rem;

  & > * {
    margin: 0.3rem;
    max-width: 280px;
  }
`;

interface IGeneratedCardActionsProps {
  card: IGeneratedCard;
}

const GeneratedCardActions = ({ card }: IGeneratedCardActionsProps) => (
  <ButtonsContainer>
    {card.mint && <SyncButton cardId={card.id} />}
    {card.mint && <TransferNftButton cardId={card.id} nft={card.mint.nft} />}
    <MetadataButton card={card} />
    <IPFSButton card={card} />
    <BlockchainButton card={card} />
  </ButtonsContainer>
);

export const CardShowImageWrapper = styled.div``;

export const Wrapper = ({ record }: { record: IRecordNFT }) => {
  if (!record) {
    return null;
  }
  return (
    <>
      {isGeneratedCard(record) && <GeneratedCardActions card={record} />}
      <CardShowImageWrapper>
        <CardItem card={record} />
      </CardShowImageWrapper>
    </>
  );
};

export const NFTCardShow = (props: any) => (
  <Show {...props} hasEdit={false}>
    <SimpleShowLayout>
      {/* @ts-ignore */}
      <Wrapper />
    </SimpleShowLayout>
  </Show>
);
