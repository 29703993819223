import { Typography } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 0.5rem;
  padding: 0.5rem 0;
  width: 100%;
  color: #4949dd;
  background-color: #d3eaff;

  & svg {
    margin-left: 1.5rem;
    opacity: 0.5;
  }
`;
const Content = styled.ul``;
const Line = styled.li``;

export const Notice = () => (
  <Container>
    <InfoIcon fontSize="large" />
    <Content>
      <Line>
        <Typography variant="body1">
          A <b>required</b> position means the athletes are scoring like regular
          athletes on the field.
        </Typography>
      </Line>
      <Line>
        <Typography variant="body1">
          When substitutes are <b>not required</b>, they will replace a DNP
          athlete.
        </Typography>
      </Line>
    </Content>
  </Container>
);
