import Dialog from '@material-ui/core/Dialog';
import React, { useCallback, useState } from 'react';
import { Button, useListContext, useNotify, useUnselectAll } from 'react-admin';
import { IUserCohort } from '~business/usercohort/types';
import { fetchApi, prepareSubmitRequestInit } from '~technical/api';

import { CohortSelector } from './CohortSelector';

export const AddToCohortButton = () => {
  const notify = useNotify();
  const { resource, selectedIds, refetch } = useListContext();
  const unselectAll = useUnselectAll();
  const [modalVisible, setModalVisible] = useState<boolean>(false);

  const submit = useCallback(
    async (cohortId: IUserCohort['id']) => {
      try {
        await fetchApi(
          `/backoffice/user/add-to-cohort`,
          prepareSubmitRequestInit(
            {
              cohortId,
              users: selectedIds,
            },
            'PATCH'
          )
        );
        notify('Operation successful 👍', { type: 'success' });
        setModalVisible(false);
        unselectAll(resource);
        refetch();
      } catch (error: any) {
        notify(`Failed to add users to cohort! ${error.message || error}`, {
          type: 'error',
        });
      }
    },
    [selectedIds, notify, unselectAll, refetch, resource]
  );

  return (
    <>
      <Button
        label="Add to cohort"
        disabled={false}
        onClick={() => {
          setModalVisible(true);
        }}
      />
      <Dialog
        open={modalVisible}
        onClose={() => {
          setModalVisible(false);
        }}
      >
        <CohortSelector onSubmit={submit} />
      </Dialog>
    </>
  );
};
