import { Typography } from '@material-ui/core';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import React from 'react';
import {
  BooleanField,
  Datagrid,
  Filter,
  List,
  ReferenceField,
  TextField,
  TextInput,
} from 'react-admin';
import { PackConfigField } from '~technical/filters/packConfig';
import { QuickFilter } from '~ui/QuickFilter';

import { PackConfigActions } from './actions';
import { PackConfigBulkActions } from './bulk-actions';
import { useMonetization } from './hooks';
import { IPackConfig } from './types';

interface IPackSupplyFieldProps {
  record: IPackConfig;
}

const PackSupplyField = ({ record }: IPackSupplyFieldProps) => {
  const { count } = useMonetization(record);

  return (
    <Typography component="span" variant="body2">
      {count === undefined ? (
        <HourglassEmptyIcon />
      ) : (
        `${count.available}/${count.created}/${
          record.monetization?.supply ? `${record.monetization.supply}` : 'x'
        }`
      )}
    </Typography>
  );
};

export const PackConfigList = (props: {}) => (
  <List
    {...props}
    bulkActionButtons={<PackConfigBulkActions />}
    sort={{ field: '_id', order: 'DESC' }}
    filters={
      <Filter>
        <TextInput source={PackConfigField.NAME} label="Name" />
        <QuickFilter
          source={PackConfigField.IS_ARCHIVE}
          label="Archive"
          defaultValue
        />
        <QuickFilter
          source={PackConfigField.IS_GIVEAWAY}
          label="Giveaway"
          defaultValue
        />
        <QuickFilter
          source={PackConfigField.IS_MONETIZATION_ENABLED}
          label="Monetization Enabled"
          defaultValue
        />
      </Filter>
    }
  >
    <Datagrid>
      <TextField source="name" />
      <ReferenceField source="lootTable" reference="lootTable">
        <TextField source="title" />
      </ReferenceField>
      <BooleanField
        source="isMonetizationEnabled"
        label="Monetization enabled"
      />
      <BooleanField source="isArchive" label="Archive" />
      <BooleanField source="monetization.isGiveaway" label="Giveaway" />
      {/* @ts-ignore record prop is injected by react-admin */}
      <PackSupplyField label="Available/Created/Config" sortable={false} />
      <PackConfigActions />
    </Datagrid>
  </List>
);
