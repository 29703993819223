import { processGenericFilter, Value } from './generic';

export enum FilterField {
  SPORT = 'sport',
  REFERENCE = 'reference',
}

export function processFilterFilter(field: FilterField | string, value: Value) {
  switch (field) {
    case FilterField.SPORT:
      return { 'query.sport': value };
    case FilterField.REFERENCE:
      return { reference: { $regex: value, $options: 'i' } };
    default:
      return processGenericFilter(field, value);
  }
}
