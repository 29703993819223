import { processGenericFilter, Value } from './generic';

export enum CompetitionFilterFields {
  Name = 'name',
}

export function processCompetitionFilter(
  field: CompetitionFilterFields | string,
  value: Value
) {
  switch (field) {
    case CompetitionFilterFields.Name:
      return { name: { $regex: value, $options: 'i' } };
    default:
      return processGenericFilter(field, value);
  }
}
