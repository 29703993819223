import { Box } from '@material-ui/core';
import React, { PropsWithChildren } from 'react';
import { ArrayInput, SimpleFormIterator, TextInput } from 'react-admin';
import { FieldArrayRenderProps } from 'react-final-form-arrays';
import styled from 'styled-components';
import { IInputPositionAssets } from '~business/common/assetTypes';
import { StorageImageInput } from '~business/storage/StorageImageInput';

/* Styled Components */

const PositionAssetsWrapper = styled.div`
  margin: 0.2em 0 0.5em;
  padding: 0.5em 1em;
  border: 1px solid #959595;
  background-color: white;
`;

const StorageImageInputContainer = styled(Box)`
  & > *:not(:first-child) {
    margin-left: 8px;
  }
`;

const PositionLabel = styled(TextInput)`
  & .muifilledinput-underline:before {
    border: 0;
  }
  & input {
    padding: 0.6em;
    font-weight: bold;
    color: grey;
    border: 0;
  }
`;

/* Main Component */

const PositionsInputIterator = ({
  fields,
}: PropsWithChildren<
  FieldArrayRenderProps<IInputPositionAssets, HTMLDivElement>
>) =>
  fields.map((value) => (
    <PositionAssetsWrapper key={value}>
      <PositionLabel
        disabled
        source={`${value}.position`}
        label=""
        format={(v: string) => `${v} ASSETS`}
      />
      <StorageImageInputContainer display="flex">
        <ArrayInput source={`${value}.cardAssets`} label="">
          <SimpleFormIterator>
            <StorageImageInput label="Asset" source="file" />
          </SimpleFormIterator>
        </ArrayInput>
      </StorageImageInputContainer>
    </PositionAssetsWrapper>
  ));

export default PositionsInputIterator;
