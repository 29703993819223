import React, { KeyboardEventHandler, PropsWithChildren } from 'react';
import {
  AutocompleteInput,
  BooleanField,
  ChipField,
  Datagrid,
  EditButton,
  Filter,
  FunctionField,
  List,
  Pagination,
  ReferenceArrayField,
  ReferenceField,
  ReferenceInput,
  SingleFieldList,
  TextField,
  TextInput,
  usePermissions,
} from 'react-admin';
import styled from 'styled-components';
import { User } from 'ultimate-league-common';
import { AddressField } from '~business/wallet/AddressField';
import { UserField } from '~technical/filters/user';
import { QuickFilter } from '~ui/QuickFilter';

import { GivePackButton } from './actions/GivePackButton';
import { AddToCohortButton } from './actions/cohort/add-to-cohort';
import { RemoveFromCohortButton } from './actions/cohort/remove-from-cohort';
import {
  IUserWorkflow,
  ViewTransactionsButton,
} from './actions/kyc/ViewTransactionsButton';

const StyledList = styled(List)`
  & > .MuiToolbar-root {
    margin-bottom: 3.2rem;
  }
`;

const BulkButtons = styled.div`
  & > button {
    margin: 0 10px;
  }
`;

const ActionsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  & > * {
    margin-left: 1.5em;
  }
`;

const UserFilter = ({ children, ...props }: PropsWithChildren<{}>) => {
  const handleKeyPress: KeyboardEventHandler = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  };

  return (
    /* TODO: fix types */
    /* @ts-ignore */
    <Filter {...props} onKeyPress={handleKeyPress}>
      <TextInput source={UserField.USERNAME} label="Username" />
      <TextInput source={UserField.EMAIL} label="Email" />
      <TextInput
        source={UserField.STRIPE_CUSTOMER_ID}
        label="Stripe customer id"
      />
      <ReferenceInput
        reference="usercohort"
        source={UserField.COHORTS}
        filterToQuery={(search: string) => ({
          name: { $regex: search, $options: 'i' },
        })}
      >
        <AutocompleteInput optionText="name" optionValue="id" />
      </ReferenceInput>
      <QuickFilter source={UserField.DELETED} label="Deleted" defaultValue />
      {children}
    </Filter>
  );
};

const BulkActionButtons = () => (
  <BulkButtons>
    <GivePackButton />
    <AddToCohortButton />
    <RemoveFromCohortButton />
  </BulkButtons>
);

const Actions = (props: any) => (
  <ActionsWrapper>
    <EditButton {...props} />
    <ViewTransactionsButton {...props} />
  </ActionsWrapper>
);

function renderKycVerified(record?: IUserWorkflow) {
  if (!record?.kyc) {
    return '';
  }

  if (record.kyc.allowBypass) {
    return 'bypass';
  }

  return record.kyc.workflows.some(({ decision }) => decision === 'PASSED')
    ? 'yes'
    : 'no';
}

export const UserList = (props: {}) => {
  const { permissions } = usePermissions();
  return (
    <StyledList
      {...props}
      filters={<UserFilter />}
      pagination={<Pagination rowsPerPageOptions={[25, 50, 100, 200]} />}
      perPage={25}
      bulkActionButtons={
        permissions === User.Role.MODERATOR ? <BulkActionButtons /> : false
      }
      {...(permissions === User.Role.COMMUNITY && { exporter: false })}
    >
      <Datagrid>
        <TextField source="username" />
        <ReferenceField source="wallet" reference="wallet" link="show">
          <AddressField source="address" short />
        </ReferenceField>
        <TextField source="role" />
        <TextField source="messaging.email" label="email" />
        <ReferenceArrayField reference="usercohort" source="cohorts">
          <SingleFieldList>
            <ChipField source="name" />
          </SingleFieldList>
        </ReferenceArrayField>
        <FunctionField<IUserWorkflow>
          label="Verified"
          render={renderKycVerified}
        />
        <BooleanField source="deleted" />
        <Actions />
      </Datagrid>
    </StyledList>
  );
};
