import React, { useLayoutEffect, useRef } from 'react';
import { NumberInput } from 'react-admin';

export const SafeNumberInput: typeof NumberInput = (props) => {
  const inputRef = useRef<HTMLElement>(null);

  useLayoutEffect(() => {
    const listener = (e: Event) => {
      e.preventDefault();
    };
    const input = inputRef.current;
    input?.addEventListener('wheel', listener);
    return () => {
      input?.removeEventListener('wheel', listener);
    };
  }, []);

  return <NumberInput {...props} inputRef={inputRef} />;
};
