import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import { HourglassEmpty } from '@material-ui/icons';
import React, { useCallback, useEffect, useState } from 'react';
import { Button, useNotify } from 'react-admin';
import styled from 'styled-components';
import {
  IPackConfig,
  IPackConfigWithPacksCount,
} from '~business/pack/pack-config/types';
import { buildQuery, fetchApi } from '~technical/api';

import { SPORT_CONTEXT } from '../../constant';
import { PackConfigElement } from './PackConfigElement';

const Panel = styled.div`
  flex-shrink: 1;
  padding: 0 1rem 1rem;
  overflow: auto;
`;
const WaitWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 1rem 0 1.5rem;
`;
const ButtonRow = styled.div`
  display: flex;
  justify-content: center;
  margin: 1rem;
`;

interface IProps {
  amountNeeded: number;
  onSubmit: (packConfigId: IPackConfigWithPacksCount['id']) => void;
}

export const PackConfigSelector = ({ amountNeeded, onSubmit }: IProps) => {
  const notify = useNotify();
  const [loading, setLoading] = useState<boolean>(true);
  const [packConfigs, setPackConfigs] = useState<IPackConfigWithPacksCount[]>();
  const [selectedPackConfigId, setSelectedPackConfigId] =
    useState<IPackConfig['id']>();
  const [submitting, setSubmitting] = useState<boolean>(false);

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const response = await fetchApi(
          `/backoffice/pack-config/available-giveaway?${buildQuery({
            sport: SPORT_CONTEXT,
          })}`
        );
        setPackConfigs(await response.json());
      } catch (e) {
        notify('Failed to fetch available pack configs for giveaway', {
          type: 'warning',
        });
      }
      setLoading(false);
    })();
  }, [notify, setLoading, setPackConfigs]);

  const givePack = useCallback(async () => {
    if (!selectedPackConfigId) {
      notify('No packConfig selected', { type: 'info' });
      return;
    }
    setSubmitting(true);
    await onSubmit(selectedPackConfigId);
    setSubmitting(false);
  }, [selectedPackConfigId, notify, onSubmit]);

  return (
    <>
      <DialogTitle>Select pack config</DialogTitle>
      <Panel>
        {loading && (
          <WaitWrapper>
            <HourglassEmpty />
          </WaitWrapper>
        )}
        {!loading && (!packConfigs || packConfigs.length === 0) && (
          <Typography variant="body2">
            No pack config available for giveaway.
          </Typography>
        )}
        {!loading && (
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Visual</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell align="center">Available supply</TableCell>
                  <TableCell align="center">Giveaway flagged</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {packConfigs?.map((packConfig) => (
                  <PackConfigElement
                    key={packConfig.id}
                    packConfig={packConfig}
                    onSelect={setSelectedPackConfigId}
                    isSelected={packConfig.id === selectedPackConfigId}
                    hasEnoughSupply={
                      packConfig.availablePacksCount >= amountNeeded
                    }
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Panel>
      <ButtonRow>
        <Button
          size="large"
          label={`Give selected pack to ${amountNeeded} user${
            amountNeeded > 1 ? 's' : ''
          }`}
          onClick={selectedPackConfigId ? givePack : undefined}
          disabled={!selectedPackConfigId || submitting}
        />
      </ButtonRow>
    </>
  );
};
