import React, { useMemo } from 'react';
import {
  AutocompleteArrayInput,
  InputProps,
  required,
  SaveButton,
  SimpleForm,
  SimpleFormProps,
  TextInput,
  Toolbar,
  useGetList,
} from 'react-admin';
import { useField } from 'react-final-form';
import { Loots } from '~business/pack/loot-table/inputs/Loot';

import { SPORT_CONTEXT } from '../../../constant';

const TagsInput = ({
  createdTags,
  ...props
}: Omit<InputProps, 'source'> & { createdTags: string[] }) => {
  const { input } = useField<string[]>('tags');

  const choices = useMemo(() => {
    const set = new Set(createdTags);
    input.value.forEach?.((id) => set.add(id));
    return Array.from(set).map((name) => ({
      id: name,
      name,
    }));
  }, [createdTags, input.value]);

  return (
    <AutocompleteArrayInput
      source="tags"
      translateChoice={false}
      choices={choices}
      createLabel="+ Create tag"
      onCreate={(tag) => {
        const name = tag || global.prompt('Create a new tag') || '';
        return { id: name, name };
      }}
      {...props}
    />
  );
};

export const LootTableForm = (props: SimpleFormProps) => {
  const { ids: createdTags, loading } = useGetList('lootTableTag') as {
    ids: string[];
    loading: boolean;
  };

  const handleSave = async (
    values: { tags: string[] },
    ...params: Parameters<SimpleFormProps['save']>
  ) => {
    const tags = values.tags
      .filter((t) => !!t)
      .map(
        (tag) =>
          createdTags.find(
            (createdTag?: string) =>
              createdTag?.toLowerCase() === tag.toLowerCase()
          ) || tag
      );
    const set = new Set(tags);

    return props.save(
      {
        ...values,
        tags: Array.from(set),
      },
      ...params
    );
  };

  if (loading) {
    return null;
  }

  return (
    <SimpleForm
      {...props}
      initialValues={{
        title: '',
        loots: [],
        tags: [],
        sport: SPORT_CONTEXT,
      }}
      save={handleSave}
      toolbar={
        <Toolbar>
          <SaveButton />
        </Toolbar>
      }
    >
      <TextInput validate={required()} source="title" />
      {/* @ts-ignore */}
      <TagsInput createdTags={createdTags} />
      <Loots />
    </SimpleForm>
  );
};
