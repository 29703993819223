import React from 'react';
import { Edit } from 'react-admin';

import NftBatchForm from './NftBatchForm';

export const NftBatchEdit = (props: {}) => (
  <Edit {...props}>
    {/* @ts-ignore */}
    <NftBatchForm />
  </Edit>
);
