import { useCallback, useEffect } from 'react';
import { useDelete, useNotify, useRedirect } from 'react-admin';

export const useDeleteBatch = () => {
  const [deleteOne, { data, error }] = useDelete();
  const redirect = useRedirect();
  const notify = useNotify();

  const deleteFn = useCallback(
    (batchId: string) => {
      deleteOne('nftbatch', batchId);
    },
    [deleteOne]
  );

  useEffect(() => {
    if (error) {
      notify(
        `Failed to delete batch: ${
          error.message ? error.message : JSON.stringify(error)
        }`,
        { type: 'error' }
      );
    }
  }, [error, notify]);

  useEffect(() => {
    if (data && !error) {
      redirect('list', '/nftbatch');
    }
  }, [data, error, redirect]);

  return deleteFn;
};
