import React from 'react';
import {
  AutocompleteInput,
  DateTimeInput,
  Edit,
  EditProps,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextField,
} from 'react-admin';
import { enumToArray, SoccerData } from 'ultimate-league-common';

const matchStatusChoices = enumToArray(SoccerData.Match.MatchStatus).map(
  (type) => ({
    id: type,
    name: type,
  })
);

export const MatchEdit = (props: EditProps) => (
  <Edit {...props}>
    <SimpleForm>
      <DateTimeInput source="date" />

      <ReferenceField source="homeContestant" reference="team" link={false}>
        <TextField source="name" />
      </ReferenceField>

      <ReferenceField source="awayContestant" reference="team" link={false}>
        <TextField source="name" />
      </ReferenceField>

      <SelectInput source="status" choices={matchStatusChoices} />

      <ReferenceInput
        resettable
        source="gameWeek"
        reference="gameweek"
        filterToQuery={(search: string) => ({ position: search })}
      >
        <AutocompleteInput
          optionText="position"
          optionValue="id"
          matchSuggestion={(filter: string, choice: { position: number }) =>
            `${choice.position}`.match(filter)
          }
        />
      </ReferenceInput>
    </SimpleForm>
  </Edit>
);
