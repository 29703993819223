import { Box, Button, Slider, Typography } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import { isEmpty } from 'lodash';
import React, {
  cloneElement,
  KeyboardEventHandler,
  ReactElement,
  SetStateAction,
  useCallback,
  useState,
} from 'react';
import {
  AutocompleteArrayInput,
  Filter,
  FilterPayload,
  List,
  Record,
  ReferenceArrayInput,
  SelectArrayInput,
  SelectInput,
  TextInput,
  TopToolbar,
  useCreate,
  useListContext,
  useNotify,
  useRedirect,
} from 'react-admin';
import styled from 'styled-components';
import { Blockchain, SoccerData } from 'ultimate-league-common';
import { athletePositionChoices, tierChoices } from '~business/athlete/common';
import {
  conditionChoice,
  rarityLevelChoice,
} from '~business/common/assetTypes';
import { reactAdminCardFilterToQuery } from '~business/filter/service';
import { AthleteField } from '~technical/filters/athlete';
import { CardField } from '~technical/filters/nftCard';
import { TeamField } from '~technical/filters/team';
import { QuickFilter } from '~ui/QuickFilter';

import { SPORT_CONTEXT } from '../../constant';
import { SaveFiltersDialog } from './SaveFiltersDialog';
import { NFTCardGridList } from './grid-list/NFTCardGridList';
import { KeyValueObject } from './interfaces';

const StyledTier = styled(SelectArrayInput)`
  width: 100px;
`;

const SliderField = ({
  source,
  toText,
  max,
  step,
  label,
  description,
}: any) => {
  const { filterValues, displayedFilters, setFilters } = useListContext();
  const [value, setValue] = useState(filterValues[source] || [0, 100]);

  const handleChange = useCallback(
    (_, newValue) => {
      setValue(newValue);

      setFilters(
        {
          ...filterValues,
          [source]: newValue,
        },
        displayedFilters
      );
    },
    [setFilters, filterValues, displayedFilters, source]
  );

  return (
    <Box width={300}>
      <Typography>{description ?? label}</Typography>
      <Slider
        onChange={handleChange}
        value={value}
        valueLabelDisplay="auto"
        valueLabelFormat={toText}
        max={max}
        step={step ?? 1}
      />
    </Box>
  );
};

interface IListActionsProps {
  setSaveFiltersDialogVisibility: (visibilityStatus: boolean) => void;
  setFiltersConfig: (filtersConfigValueAction: SetStateAction<any>) => void;
  filters?: ReactElement;
}

interface ICardFilterProps {}

interface ICardListProps {
  filter?: FilterPayload;
  resource?: string;
  basePath?: string;
}

interface IAthlete {
  firstName: string;
  lastName: string;
  matchName: string;
}

const ListActions = (props: IListActionsProps) => {
  const listContext = useListContext();

  const { filters, setSaveFiltersDialogVisibility, setFiltersConfig } = props;
  const isAddFiltersButtonVisible =
    !isEmpty(listContext.filterValues) &&
    typeof listContext.filterValues[CardField.SEARCH_LEVEL] === 'number';

  return (
    <TopToolbar>
      {isAddFiltersButtonVisible ? (
        <Button
          onClick={() => {
            setSaveFiltersDialogVisibility(true);
            setFiltersConfig(listContext.filterValues);
          }}
        >
          Save filters
        </Button>
      ) : null}
      {filters ? cloneElement(filters, { context: 'button' }) : null}
    </TopToolbar>
  );
};

const CardFilter = (props: ICardFilterProps) => {
  const handleKeyPress: KeyboardEventHandler = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  };

  return (
    // TODO: fix types
    // @ts-ignore
    <Filter {...props} onKeyPress={handleKeyPress}>
      <TextInput source="_id" label="id" />
      <TextInput source="nft" label="nft" />

      <SelectInput
        label="Rarity level"
        source={CardField.SEARCH_LEVEL}
        choices={rarityLevelChoice}
      />

      <ReferenceArrayInput
        label="Athlete"
        source={CardField.SEARCH_ATHLETE}
        reference="athlete"
        filterToQuery={(search: string) => ({ [AthleteField.JOKER]: search })}
      >
        <AutocompleteArrayInput
          optionText="matchName"
          optionValue="id"
          matchSuggestion={(filter: string, choice: Partial<IAthlete>) =>
            !filter ||
            choice.firstName?.includes(filter) ||
            choice.lastName?.includes(filter) ||
            choice.matchName?.includes(filter)
          }
        />
      </ReferenceArrayInput>

      <SelectArrayInput
        label="Athlete position"
        source={CardField.SEARCH_ATHLETE_POSITION}
        choices={athletePositionChoices}
      />

      <StyledTier
        label="Athlete tier"
        source={CardField.SEARCH_TIER}
        choices={tierChoices}
      />

      <SliderField
        label="Athlete age"
        source={CardField.SEARCH_ATHLETE_AGE}
        toText={(age: number) => ` ${age}yo `}
      />

      <SliderField
        label="Athlete draft price"
        description="Athlete draft price (Shows only unreserved & undistributed cards)"
        source={CardField.SEARCH_FTUE_PRICE}
        toText={(price: number) => ` ${price} `}
        max={(() => {
          switch (SPORT_CONTEXT) {
            case SoccerData.Sport.SOCCER:
              return 20;
            case SoccerData.Sport.BASKETBALL:
              return 500;
            default:
              return undefined;
          }
        })()}
        step={SPORT_CONTEXT === SoccerData.Sport.BASKETBALL ? 10 : 1}
      />

      <ReferenceArrayInput
        label="Team"
        source={CardField.SEARCH_TEAM}
        reference="team"
        filterToQuery={(search: string) => ({ [TeamField.NAME]: search })}
      >
        <AutocompleteArrayInput optionText="name" optionValue="id" />
      </ReferenceArrayInput>

      <ReferenceArrayInput
        label="Season"
        source={CardField.SEARCH_SEASON}
        reference="nftcardseason"
        filterToQuery={(search: string) => ({ title: search, isLive: true })}
      >
        <AutocompleteArrayInput optionText="title" optionValue="id" />
      </ReferenceArrayInput>

      <ReferenceArrayInput
        label="Batch"
        source={CardField.SEARCH_BATCH}
        reference="nftbatch"
        filterToQuery={(search: string) => ({ label: search, isLive: true })}
      >
        <AutocompleteArrayInput optionText="label" optionValue="id" />
      </ReferenceArrayInput>

      <ReferenceArrayInput
        label="Special edition"
        source={CardField.SEARCH_SPECIAL_EDITION}
        reference="nftspecialedition"
      >
        <AutocompleteArrayInput optionText="name" optionValue="id" />
      </ReferenceArrayInput>

      <ReferenceArrayInput
        label="Competition"
        source={CardField.SEARCH_COMPETITION}
        reference="competition"
        filterToQuery={(search: string) => ({ name: search })}
      >
        <AutocompleteArrayInput optionText="name" optionValue="id" />
      </ReferenceArrayInput>

      {Blockchain.BrandConfig.enableConditionBonus && (
        <SelectArrayInput
          label="Condition"
          source={CardField.SEARCH_CONDITION}
          choices={conditionChoice}
        />
      )}

      <QuickFilter
        label="Auctionable"
        source={CardField.AUCTIONABLE}
        defaultValue
      />
    </Filter>
  );
};

const InfoRow = styled.div`
  display: flex;
  align-items: center;
  align-self: end;

  & p {
    margin-left: 6px;
  }
  & ul {
    padding-left: 5px;
    list-style: none;
  }
`;

export const NFTCardList = (props: ICardListProps) => {
  const [saveFiltersDialogVisibility, setSaveFiltersDialogVisibility] =
    useState(false);
  const [filtersConfig, setFiltersConfig] = useState<FilterPayload>();
  const notify = useNotify();
  const redirect = useRedirect();
  const [create] = useCreate('filter');

  const submitFilters = (formValues: KeyValueObject) => {
    if (!filtersConfig) {
      throw new Error('Invalid state: No filters to process');
    }

    create(
      {
        payload: {
          data: {
            ...formValues,
            query: reactAdminCardFilterToQuery(filtersConfig),
          },
        },
      },
      {
        onSuccess: ({ data: { id, reference } }: { data: Record }) => {
          notify(`Filter ${reference} created`, 'info');
          redirect('edit', 'filter', id);
        },
        onFailure: () => {
          notify('Filters saving error', 'error');
        },
      }
    );
  };

  return (
    <>
      <InfoRow>
        <InfoIcon color="primary" />
        <Typography variant="body2">
          <ul>
            <li>To save a filter, &apos;level&apos; is mandatory.</li>
            <li>
              If no &apos;special edition&apos; filter is enabled, then special
              edition cards are ignored.
            </li>
          </ul>
        </Typography>
      </InfoRow>
      <List
        {...props}
        actions={
          <ListActions
            setFiltersConfig={setFiltersConfig}
            setSaveFiltersDialogVisibility={setSaveFiltersDialogVisibility}
          />
        }
        exporter={false}
        sort={{ field: '_id', order: 'ASC' }}
        filters={<CardFilter />}
      >
        <NFTCardGridList />
      </List>
      <SaveFiltersDialog
        submitFilters={submitFilters}
        filtersConfig={filtersConfig}
        saveFiltersDialogVisibility={saveFiltersDialogVisibility}
        setSaveFiltersDialogVisibility={setSaveFiltersDialogVisibility}
      />
    </>
  );
};
