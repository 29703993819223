import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import React, { useMemo } from 'react';
import {
  AutocompleteInput,
  BooleanInput,
  SimpleForm,
  TextInput,
} from 'react-admin';

import { SPORT_CONTEXT } from '../../constant';
import { useModifiers } from './useModifiers.hook';

export const NftSpecialEditionForm = (props: any) => {
  const { loading: modifierMatrixLoading, editionModifiers } = useModifiers();

  const modifierChoices = useMemo(
    () =>
      editionModifiers.map(({ edition, modifierValue }) => ({
        id: edition,
        name: `${edition} (modifier: ${modifierValue})`,
      })),
    [editionModifiers]
  );

  return (
    <SimpleForm initialValues={{ sport: SPORT_CONTEXT }} {...props}>
      <TextInput source="name" label="Name (internal)" />
      <TextInput source="displayName" />
      <BooleanInput source="searchable" />
      {modifierMatrixLoading ? (
        <HourglassEmptyIcon />
      ) : (
        <AutocompleteInput
          resettable
          source="modifier"
          choices={modifierChoices}
        />
      )}
    </SimpleForm>
  );
};
