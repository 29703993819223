import {
  Blockchain,
  generateNftIngredients,
  INftIngredients,
} from 'ultimate-league-common';
import { IAssetOverride, IAthlete } from '~business/athlete/types';
import {
  ISavedCardAsset,
  ISavedRaritySupply,
} from '~business/common/assetTypes';

import { SPORT_CONTEXT } from '../../../../constant';
import { ISavedNftBatch, ISavedNftSeason } from '../../types';
import { getAssetPath, getAssetsWithPath } from './asset-helpers';
import { getFontsWithPath } from './font-helpers';

const getAssetRarity = (
  rarities: ISavedRaritySupply[],
  rarityLevel: Blockchain.NFTCard.RarityLevel
) => rarities?.find((rarityAsset) => rarityAsset.level === rarityLevel);

export const mergeRarities = (batch: ISavedNftBatch, season: ISavedNftSeason) =>
  season.rarities
    .map((seasonRarity) => {
      const batchRarity = getAssetRarity(batch.rarities, seasonRarity.level);
      if (batchRarity) {
        return batchRarity;
      }
      return seasonRarity;
    })
    .filter((r) => r.supply > 0)
    .sort((r1, r2) => r1.level - r2.level);

async function getAssetsStorageId(
  athlete: IAthlete,
  rarity: ISavedRaritySupply
) {
  const athleteAssetOverride = athlete.nftTemplateOverride?.athleteAsset?.find(
    ({ rarities }) => rarities.includes(rarity.level)
  );

  const athleteAssetId = (
    athleteAssetOverride
      ? athleteAssetOverride.file
      : athlete.assets?.main?.mint
  ) as string;

  if (!athleteAssetId) {
    throw new Error(
      `Athlete has no asset for rarity ${
        Blockchain.NFTCard.RarityLevel[rarity.level]
      }`
    );
  }

  const clubAssetOverride = athlete.club?.nftTemplateOverride?.clubAsset?.find(
    ({ rarities }) => rarities.includes(rarity.level)
  );

  const clubLogoAssetId = (
    clubAssetOverride
      ? clubAssetOverride.file
      : athlete.club?.assets?.logo?.mint
  ) as string;

  if (!clubLogoAssetId) {
    throw new Error(`Club has no logo`);
  }

  const competitionAssetId = athlete.club?.competition?.asset?.mint as string;

  if (!competitionAssetId) {
    throw new Error(`Competition has no logo`);
  }

  const cardAssetsForPosition = rarity.assetsPerPosition.find(
    (app) => app.position === athlete.position
  )?.cardAssets;
  if (!cardAssetsForPosition) {
    throw new Error(
      `Could not find card assets for position ${athlete.position}`
    );
  }

  return {
    athleteAssetId,
    clubLogoAssetId,
    competitionAssetId,
    assets: cardAssetsForPosition,
  };
}

interface IParsedIngredients {
  ingredients: INftIngredients;
  assetIds: {
    athleteAssetId: string;
    clubLogoAssetId: string;
    competitionAssetId: string;
    assets: ISavedCardAsset[];
  };
}

const getAssetFileByRarity = (
  assets: IAssetOverride[],
  rarity: Blockchain.NFTCard.RarityLevel
) => assets?.find((asset) => asset.rarities.includes(rarity))?.file;

export async function prepareIngredients(
  edition: number,
  athlete: IAthlete,
  season: ISavedNftSeason,
  currentRarity: ISavedRaritySupply,
  templateValues: string[]
): Promise<IParsedIngredients> {
  const errors: string[] = [];

  if (!athlete.firstName) {
    errors.push('First name is missing');
  }
  if (!athlete.shortFirstName) {
    errors.push('Short first name is missing');
  }
  if (!athlete.lastName) {
    errors.push('Last name is missing');
  }
  if (!athlete.shortLastName) {
    errors.push('Short last name is missing');
  }
  if (!athlete.club) {
    errors.push('Club is missing');
  } else if (!athlete.club.code) {
    errors.push(`Club team '${athlete.club.name}' has no code`);
  }
  if (!athlete.national) {
    errors.push('National is missing');
  } else if (!athlete.national.code) {
    errors.push(`National team '${athlete.national.name}' has no code`);
  }
  if (!athlete.country) {
    errors.push('Country is missing');
  } else if (!athlete.country.isoAlpha2Code) {
    errors.push(`Country '${athlete.country.name}' has no isoAlpha2Code`);
  }
  if (!athlete.birth) {
    errors.push('Birth date is missing');
  }
  if (!athlete.position) {
    errors.push('Athlete position is missing');
  }

  Blockchain.BrandConfig.activatedRarities.forEach((rarity) => {
    const asset =
      getAssetFileByRarity(
        athlete.assetOverrides?.main,
        Blockchain.NFTCard.RarityLevel.FUNGIBLE
      ) || athlete?.assets?.main;

    if (!asset) {
      errors.push(
        `Missing asset for rarity ${Blockchain.NFTCard.RarityLevel[rarity]}`
      );
    }
  });

  if (errors.length) {
    throw new Error(errors.map((e) => `- ${e}`).join('\r\n'));
  }

  const { athleteAssetId, clubLogoAssetId, competitionAssetId, assets } =
    await getAssetsStorageId(athlete, currentRarity);

  const [athleteAssetPath, clubAssetPath, competitionAssetPath] =
    await Promise.all(
      [athleteAssetId, clubLogoAssetId, competitionAssetId].map((id: string) =>
        getAssetPath(id)
      )
    );

  const athleteTemplateValuesOverride =
    athlete.nftTemplateOverride?.templateValues?.find(({ rarities }) =>
      rarities.includes(currentRarity.level)
    )?.values;

  return {
    ingredients: {
      edition,
      ...generateNftIngredients({
        supply: currentRarity.supply,
        rarityLevel: currentRarity.level,
        // eslint-disable-next-line no-underscore-dangle
        athleteId: String(athlete._id),
        athleteName: athlete.matchName,
        athleteFirstName: athlete.firstName,
        athleteShortFirstName: athlete.shortFirstName,
        athleteLastName: athlete.lastName,
        athleteShortLastName: athlete.shortLastName,
        athleteBirth: athlete.birth,
        athletePosition: String(athlete.position),
        athleteAsset: athleteAssetPath,
        seasonStartDate: season.startDate,
        seasonTitle: season.title,
        clubAsset: clubAssetPath,
        clubCode: athlete.club.code!,
        clubName: athlete.club.name,
        clubShortName: athlete.club.shortName,
        nationalTeamName: athlete.national!.name,
        nationalTeamCode: athlete.national!.code!,
        countryCode: athlete.country!.isoAlpha2Code!,
        competitionName: athlete.club.competition.name,
        competitionIdOriginal: athlete.club.competition.idOriginal,
        competitionAsset: competitionAssetPath,
        assets: await getAssetsWithPath(assets),
        fonts: await getFontsWithPath(season.cardFonts),
        cardTemplate: 'not used in this scenario',
        templateValues:
          [athleteTemplateValuesOverride, templateValues].find(
            (v) => v?.length
          ) ?? [],
        sport: SPORT_CONTEXT,
      }),
    },
    assetIds: {
      athleteAssetId,
      clubLogoAssetId,
      competitionAssetId,
      assets,
    },
  };
}
