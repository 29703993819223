import { useEffect, useState } from 'react';
import { useDataProvider, useNotify } from 'react-admin';
import { IUserCohort } from '~business/usercohort/types';

export const useCohorts = (cohortIds?: IUserCohort['id'][]) => {
  const notify = useNotify();
  const dataProvider = useDataProvider();

  const [loading, setLoading] = useState<boolean>(true);
  const [cohorts, setCohorts] = useState<IUserCohort[]>();

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        let data: IUserCohort[] = [];

        if (cohortIds && cohortIds.length > 0) {
          const result = await dataProvider.getMany<IUserCohort>('UserCohort', {
            ids: cohortIds,
          });
          if (result) {
            data = result.data;
          }
        } else {
          const result = await dataProvider.getList<IUserCohort>('UserCohort', {
            filter: {},
            pagination: {
              page: 1,
              perPage: 1000,
            },
            sort: { field: 'name', order: 'ASC' },
          });
          if (result) {
            data = result.data;
          }
        }

        setCohorts(data);
      } catch (e) {
        notify('Failed to fetch cohorts', {
          type: 'warning',
        });
      }
      setLoading(false);
    })();
  }, [notify, setLoading, setCohorts, cohortIds, dataProvider]);

  return {
    loading,
    cohorts,
  };
};
