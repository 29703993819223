import get from 'lodash/get';
import * as React from 'react';
import { SVGProps } from 'react';
import { BooleanField, Record } from 'react-admin';

const ShirtSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 38 38"
    {...props}
  >
    <path
      d="M0 18.25 4.071 6.107l4.75 13.571L0 18.25ZM38 18.25 33.929 6.107l-4.75 13.571L38 18.25Z"
      style={{ filter: 'brightness(0.6)' }}
      fill="currentColor"
    />
    <path
      d="m4.071 6.107 4.322 14.39-.786 13.562L19 35.964l11.393-1.905-.786-13.563L33.93 6.107 23.714 2.036l-1.704 2.66h-6.02l-1.704-2.66L4.07 6.107Z"
      fill="currentColor"
    />
  </svg>
);

interface IProps {
  source: string;
  label?: string;
  record?: Record;
}

export const TShirtField = ({ source, record, ...props }: IProps) => {
  if (!record) {
    return null;
  }

  const shirtColor = get(record, source);
  if (!shirtColor) {
    return (
      <BooleanField
        source={source}
        record={{ ...record, [source]: false }}
        {...props}
      />
    );
  }

  return <ShirtSVG color={shirtColor} height={24} width={24} {...props} />;
};
