import React from 'react';
import { BulkDeleteButton } from 'react-admin';
import styled from 'styled-components';

import { ClonePackConfigButton } from './ClonePackConfigButton';

const BulkButtons = styled.div`
  & > button {
    margin: 0 10px;
  }
`;

export const PackConfigBulkActions = () => (
  <BulkButtons>
    <ClonePackConfigButton />
    <BulkDeleteButton />
  </BulkButtons>
);
