import { enumToArray, Payment, SoccerData } from 'ultimate-league-common';

export const saleTypeChoices = [
  { id: Payment.Sale.SaleType.MARKETPLACE_MIDDLEMAN, name: 'Marketplace' },
  { id: Payment.Sale.SaleType.PACK, name: 'Pack' },
  { id: Payment.Sale.SaleType.CHAMP, name: 'Champ' },
  { id: Payment.Sale.SaleType.CARD_BOOST, name: 'Card Boost' },
  { id: Payment.Sale.SaleType.AUCTION_BID, name: 'Auction Bid' },
];

export const saleStatusChoices = enumToArray(Payment.Sale.SaleStatus).map(
  (status) => ({
    id: status,
    name: status,
  })
);

export const paymentMethodChoices = [
  {
    id: Payment.Method.NFCHAMP_TOKEN,
    name: 'NFCHAMP',
  },
  {
    id: Payment.Method.UNA_TOKEN,
    name: 'CHAMP',
  },
  {
    id: Payment.Method.ULC_TOKEN,
    name: 'MGC',
  },
  {
    id: Payment.Method.FIAT,
    name: 'Stripe',
  },
];

export const sportChoices = SoccerData.Sports.map((sport) => ({
  id: sport,
  name: sport,
}));
