import { Button } from '@material-ui/core';
import React from 'react';
import { Datagrid, EditButton, List, TextField } from 'react-admin';
import { Link } from 'react-router-dom';
import { queryToReactAdminFilter } from '~business/filter/service';

const Actions = (props: any) => {
  const {
    record: { query },
  } = props;

  return (
    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
      <EditButton {...props} />
      <Button
        color="primary"
        component={Link}
        to={{
          pathname: 'nftcard',
          search: `filter=${JSON.stringify(queryToReactAdminFilter(query))}`,
        }}
      >
        Show filtered NFTs
      </Button>
    </div>
  );
};

export const FilterList = (props: {}) => (
  <List {...props} exporter={false}>
    <Datagrid>
      <TextField source="reference" />
      <Actions />
    </Datagrid>
  </List>
);
