import { DefaultTheme, makeStyles } from '@material-ui/styles';
import * as React from 'react';
import { useEffect, useState } from 'react';
import {
  ImageField as BaseImageField,
  ImageInput,
  InputProps,
} from 'react-admin';
import { useField } from 'react-final-form';
import { fetchGetURL, getUploadSource } from '~business/storage/service';

const useStyles = makeStyles<DefaultTheme, { hideDropZone: boolean }>({
  dropZone: {
    display: ({ hideDropZone }) => (hideDropZone ? 'none' : 'block'),
  },
  root: {
    '& .previews': {
      display: 'flex',
      flexWrap: 'wrap',
    },
  },
  removeButton: {
    '& button': {
      position: 'absolute',
      transform: 'translate(-40%, -40%)',
    },
  },
});

const ImageField = ({ record, source, ...props }: any) => {
  const { storage } = record;
  const src = record[source];

  const [pRecord, setPRecord] = useState(() =>
    typeof record.storage === 'string' ? { [source]: undefined } : record
  );
  useEffect(() => {
    if (typeof storage === 'string') {
      fetchGetURL({ id: storage }).then(({ url }) =>
        setPRecord({ [source]: url })
      );
    } else {
      setPRecord({ [source]: src });
    }
  }, [storage, src, source]);

  return <BaseImageField source={source} record={pRecord} {...props} />;
};

function formatRecord(record: string | object) {
  return typeof record === 'string' ? { storage: record } : record;
}

export const StorageImageInput = ({
  validate,
  source,
  label,
  multiple,
}: {
  validate?: InputProps['validate'];
  source: string;
  label?: string;
  multiple?: boolean;
}) => {
  const uploadSource = getUploadSource(source);
  const field = useField(uploadSource);
  const classes = useStyles({
    hideDropZone: Boolean(
      field.input.value && !(field.input.value instanceof Array)
    ),
  });

  return (
    <ImageInput
      validate={validate}
      source={uploadSource}
      accept="image/png"
      classes={classes}
      label={`${label} - ⚠️ LESS THAN 2MB`}
      format={(record: string | object | Array<string | object>) => {
        if (record instanceof Array) {
          return record.map(formatRecord);
        }
        return formatRecord(record);
      }}
      multiple={multiple}
      maxSize={2 * 1024 * 1024 /* 2MB */}
    >
      <ImageField source="src" />
    </ImageInput>
  );
};
