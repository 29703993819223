/* eslint-disable no-underscore-dangle */
import moment from 'moment';
import React, {
  cloneElement,
  KeyboardEventHandler,
  PropsWithChildren,
} from 'react';
import {
  ArrayField,
  BooleanField,
  CreateButton,
  Datagrid,
  DateField,
  ExportButton,
  Filter,
  FunctionField,
  List,
  NumberField,
  NumberInput,
  ReferenceField,
  SelectInput,
  ShowButton,
  SingleFieldList,
  TextField,
  TextInput,
  TopToolbar,
} from 'react-admin';
import { Link } from 'react-router-dom';
import { Blockchain, enumToArray, t } from 'ultimate-league-common';
import { DateRangeInput } from '~business/common/components/DateRangeInput';
import { AuctionField, AuctionStatus } from '~technical/filters/auction';

import { AuctionBulkCreate } from './AuctionBulkCreate';

const AuctionFilter = ({ children, ...props }: PropsWithChildren<{}>) => {
  const handleKeyPress: KeyboardEventHandler = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  };

  return (
    /* @ts-ignore */
    <Filter {...props} onKeyPress={handleKeyPress}>
      <TextInput source={AuctionField.CARD_ID} label="Card ID" />
      <NumberInput source={AuctionField.NFT} label="NFT" />
      <SelectInput
        source={AuctionField.STATUS}
        choices={enumToArray(AuctionStatus).map((name) => ({
          id: name,
          name,
        }))}
      />
      <DateRangeInput source="announcedAt" type="datetime-local" />
      <DateRangeInput source="startsAt" type="datetime-local" />
      <DateRangeInput source="expiresAt" type="datetime-local" />
      {children}
    </Filter>
  );
};

const AuctionListActions = ({ filters }: any) => (
  <TopToolbar>
    {cloneElement(filters, { context: 'button' })}
    <CreateButton />
    <AuctionBulkCreate />
    <ExportButton />
  </TopToolbar>
);

export const AuctionList = (props: any) => (
  <List
    actions={<AuctionListActions />}
    filters={<AuctionFilter />}
    sort={{ field: '_id', order: 'DESC' }}
    {...props}
  >
    <Datagrid>
      <ArrayField source="cards">
        <SingleFieldList>
          <FunctionField
            label="Card"
            render={(card: any) => {
              const firstName =
                card.athlete.shortFirstName ?? card.athlete.firstName;
              const lastName =
                card.athlete.shortLastName ?? card.athlete.lastName;
              return (
                <Link
                  to={{ pathname: `/nftcard/${card._id}/show` }}
                >{`${firstName} ${lastName} (${t(
                  Blockchain.NFTCard.rarityI18NKeys[card.level]
                )})`}</Link>
              );
            }}
          />
        </SingleFieldList>
      </ArrayField>
      <FunctionField
        label="Status"
        render={(record: any) => {
          if (moment(record.startsAt).isAfter(moment())) {
            return AuctionStatus.UPCOMING;
          }
          if (moment().isBetween(record.startsAt, record.expiresAt)) {
            return AuctionStatus.LIVE;
          }
          return AuctionStatus.EXPIRED;
        }}
      />
      <DateField source="announcedAt" showTime />
      <DateField source="startsAt" showTime />
      <DateField source="expiresAt" showTime />
      <NumberField label="Starting Amount" source="startingAmount.fiat" />
      <NumberField source="reservedBidCount" label="Bids" />
      <ReferenceField
        source="highestReservedBid.user._id"
        reference="user"
        label="Highest Bidder"
      >
        <TextField source="username" />
      </ReferenceField>
      <TextField
        source="highestReservedBid.amount.eth"
        label="Highest Bid (Champ)"
      />
      <BooleanField source="isProcessed" />
      <ShowButton />
    </Datagrid>
  </List>
);
