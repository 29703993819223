import { Link, Typography } from '@material-ui/core';
import get from 'lodash/get';
import React from 'react';
import {
  ArrayField,
  BooleanField,
  Datagrid,
  DateField,
  ReferenceField,
  Show,
  SimpleShowLayout,
  TextField,
  usePermissions,
} from 'react-admin';
import { Brand, User } from 'ultimate-league-common';

function getUrl(card: string) {
  if (Brand.BRAND === 'UC') {
    const isDevEnv =
      window.location.hostname.startsWith('dev') ||
      window.location.hostname.startsWith('backoffice-dev');

    return `https://${
      isDevEnv ? 'dev.' : 'beta.'
    }ultimate-champions.com/card/${card}`;
  }

  if (Brand.BRAND === 'LFP') {
    if (window.location.hostname.startsWith('bo-goat-staging')) {
      return `https://goat-staging.uwl.fr/card/${card}`;
    }

    return `https://coach.ligue1.fr/card/${card}`;
  }

  return '';
}

const NFTField = ({ record }: any) => {
  const value = get(record, 'nftCard');

  return (
    <Link href={getUrl(value)} target="_blank" variant="body2">
      CARD
    </Link>
  );
};

const TokenField = ({ record }: any) => {
  const token = get(record, 'dropType');
  const amount = get(record, 'tokens');

  return (
    <Typography variant="body2">
      {amount} x {token}
    </Typography>
  );
};

const Item = (props: any) => {
  const { record } = props;

  if (record.dropType === 'CARD') {
    return <NFTField {...props} />;
  }

  return <TokenField {...props} />;
};

export const PackShow = (props: {}) => {
  const { permissions } = usePermissions();
  return (
    <Show {...props}>
      <SimpleShowLayout>
        <ArrayField source="items">
          <Datagrid>
            <Item />
          </Datagrid>
        </ArrayField>
        <ReferenceField
          source="packConfig"
          reference="packConfig"
          link={permissions === User.Role.MODERATOR}
        >
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField source="owner" reference="user">
          <TextField source="username" />
        </ReferenceField>
        <DateField showTime source="updatedAt" />
        <BooleanField source="open" />
        <DateField showTime source="openedAt" />
        <BooleanField source="distributed" />
      </SimpleShowLayout>
    </Show>
  );
};
