import { Button } from '@material-ui/core';
import SyncIcon from '@material-ui/icons/Sync';
import React, { useCallback } from 'react';
import { useNotify } from 'react-admin';
import { useBoolean } from 'usehooks-ts';
import { fetchApi } from '~technical/api';

import { ButtonLabel } from './ButtonLabel';

const label = 'Sync';

export const SyncButton = ({ cardId }: { cardId: string }) => {
  const {
    value: loading,
    setTrue: startLoading,
    setFalse: stopLoading,
  } = useBoolean(false);
  const notify = useNotify();

  const handleSync = useCallback(async () => {
    startLoading();
    try {
      await fetchApi(`/backoffice/card/${cardId}/sync`, { method: 'POST' });
      notify('Sync success', { type: 'success' });
    } catch (e) {
      notify(e.message, { type: 'warning' });
    }
    stopLoading();
  }, [cardId, notify, startLoading, stopLoading]);

  return (
    <Button
      title={label}
      color="primary"
      onClick={handleSync}
      disabled={loading}
      size="medium"
      variant="contained"
    >
      <SyncIcon />
      <ButtonLabel label={label} />
    </Button>
  );
};
