import { styled as muiStyled } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Error, fetchEnd, fetchStart, Loading } from 'react-admin';
import { useDispatch } from 'react-redux';
import { fetchGetURL } from '~business/storage/service';

interface IProps {
  source?: string;
  record?: any;
}

const Image = muiStyled('img')({
  maxWidth: 180,
});

export const StorageField = ({ source, record }: IProps) => {
  const storageId = source ? record && record[source] : record;
  const [url, setUrl] = useState('');
  const [error, setError] = useState<Error>();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!storageId) {
      return;
    }

    dispatch(fetchStart());
    fetchGetURL({ id: storageId })
      .then(({ url: sUrl }) => {
        setUrl(sUrl);
        dispatch(fetchEnd());
      })
      .catch((e: Error) => {
        setError(e);
        dispatch(fetchEnd());
      });
  }, [storageId, setError, setUrl, dispatch]);

  if (!storageId) {
    return null;
  }

  if (error) {
    // TODO: Fix types
    // @ts-ignore
    return <Error error={error} errorInfo={{}} />;
  }

  if (!url) {
    return <Loading />;
  }

  return <Image src={url} alt="" />;
};
