import { Button, Dialog, DialogTitle, Typography } from '@material-ui/core';
import RedoIcon from '@material-ui/icons/Redo';
import React, { useCallback, useState } from 'react';
import {
  AutocompleteInput,
  ReferenceInput,
  SimpleForm,
  useNotify,
} from 'react-admin';
import styled from 'styled-components';
import { fetchApi } from '~technical/api';
import { UserField } from '~technical/filters/user';

import { ButtonLabel } from './ButtonLabel';

const Separator = styled.div`
  display: flex;
  margin: 2rem 0 1rem;
  font-weight: bold;
  color: #979797;

  & hr {
    width: 100px;
    margin: 0.5rem;
    height: 0;
    border: 0;
    border-top: 1px solid #cbcbcb;
  }
`;
const Panel = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem 0.6rem 1rem;
  overflow: auto;
`;

interface ITransferDialogProps {
  cardId: string;
  nft: number;
  isVisible: boolean;
  onClose: () => void;
}

const TransferDialog = ({
  cardId,
  nft,
  isVisible,
  onClose,
}: ITransferDialogProps) => {
  const notify = useNotify();

  const transferToRootUser = useCallback(async () => {
    try {
      await fetchApi(`/backoffice/nftcard/${cardId}/give-card/root`, {
        method: 'POST',
      });
      notify('Transfer order sent to BCI server', 'info');
      onClose();
    } catch (e) {
      notify(
        `Failed to transfer NFT to root user ${
          e.message ? `(${e.message})` : ''
        }`,
        'error'
      );
    }
  }, [cardId, onClose, notify]);

  const transferToUser = useCallback(
    async ({ user }: { user: string }) => {
      try {
        await fetchApi(`/backoffice/nftcard/${cardId}/give-card/${user}`, {
          method: 'POST',
        });
        notify('Transfer order sent to BCI server', 'info');
        onClose();
      } catch (e) {
        notify(
          `Failed to transfer NFT to user ${e.message ? `(${e.message})` : ''}`,
          'error'
        );
      }
    },
    [cardId, onClose, notify]
  );

  return (
    <Dialog open={isVisible} onClose={onClose}>
      <DialogTitle>NFT {nft} Transfer</DialogTitle>
      <Panel>
        <Button
          color="primary"
          onClick={transferToRootUser}
          variant="contained"
        >
          Transfer to root user
        </Button>

        <Separator>
          <hr />
          <Typography variant="body2">OR</Typography>
          <hr />
        </Separator>

        <Typography variant="h6">👇 Transfer to user 👇</Typography>
        <SimpleForm save={transferToUser}>
          <ReferenceInput
            label="User name"
            source="user"
            reference="user"
            filterToQuery={(search: string) => ({
              [UserField.USERNAME]: search,
            })}
          >
            <AutocompleteInput optionText="username" optionValue="id" />
          </ReferenceInput>
        </SimpleForm>
      </Panel>
    </Dialog>
  );
};

const label = 'Transfer NFT';

interface IProps {
  cardId: string;
  nft: number;
}

export const TransferNftButton = ({ cardId, nft }: IProps) => {
  const [dialogVisible, setDialogVisible] = useState<boolean>(false);

  return (
    <>
      <Button
        title={label}
        color="primary"
        onClick={() => setDialogVisible(true)}
        disabled={dialogVisible}
        size="medium"
        variant="contained"
      >
        <RedoIcon />
        <ButtonLabel label={label} />
      </Button>

      <TransferDialog
        cardId={cardId}
        nft={nft}
        isVisible={dialogVisible}
        onClose={() => {
          setDialogVisible(false);
        }}
      />
    </>
  );
};
