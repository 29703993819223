import React from 'react';
import { AutocompleteArrayInput, InputProps } from 'react-admin';
import { useField } from 'react-final-form';

export const TemplateValuesInput = ({ source, ...props }: InputProps) => {
  const { input } = useField<string[] | string>(source);

  return (
    <AutocompleteArrayInput
      {...props}
      source={source}
      translateChoice={false}
      choices={
        input.value instanceof Array
          ? input.value.map((value) => ({
              id: value,
              name: value,
            }))
          : []
      }
      createLabel="New value"
      onCreate={(value) => {
        const name = value || global.prompt('New value:') || '';
        return { id: name, name };
      }}
    />
  );
};
