import { Input, InputLabel, Slider } from '@material-ui/core';
import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1em;
`;

export const TopRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
`;

export const AthletesCount = styled.div`
  display: flex;
  align-items: center;
  margin: 0 1rem;

  & svg {
    margin-right: 0.3rem;
  }
`;

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 1em;
  padding: 0 0.5em;
  background-color: #efefef;

  & > * {
    margin: 0.5em;
  }
`;

export const StyledInputLabel = styled(InputLabel)`
  white-space: nowrap;
`;

export const StyledSlider = styled(Slider)`
  width: 50px;
`;

export const StyledInput = styled(Input)`
  width: 50px;
`;

export const Button = styled.div`
  cursor: pointer;
`;

export const PreviewCount = styled.div`
  display: flex;
  align-items: center;
  margin: 0 1em;
  padding: 9px;
  background-color: #efefef;

  & > * {
    margin: 0 0.3rem;
    line-height: 10px;
  }
`;
export const Count = styled.div``;
export const MaxButton = styled.div`
  padding: 0.3rem;
  color: white;
  font-weight: bold;
  background-color: #2196f3;
  border-radius: 7px;
`;

export const PreviewsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
`;
