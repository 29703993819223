import * as httpStatus from 'http-status';
import { HttpError } from 'react-admin';
import { Authorization as AuthorizationCommon } from 'ultimate-league-common';
import * as Logger from '~technical/logger';

import { Authorization } from './authorization';

const { SERVER_BACKOFFICE } = process.env;
const { API } = process.env;

if (!API) {
  throw new Error('Missing env API');
}

export const API_URL = (SERVER_BACKOFFICE || '') + API;
export const DEFAULT_ERROR_MESSAGE = 'An unknown error occurred';

// eslint-disable-next-line no-undef
type IRequestInit = RequestInit;

export function buildQuery(query: object) {
  return Object.keys(query)
    .filter((key) => query[key] !== undefined)
    .map((key) => {
      const value = query[key];
      const eKey = encodeURIComponent(key);

      if (value instanceof Array) {
        const eValue = value.map(encodeURIComponent).join(`&${eKey}[]=`);
        return eValue && `${eKey}[]=${eValue}`;
      }
      return `${eKey}=${encodeURIComponent(value)}`;
    })
    .join('&');
}

export function prepareSubmitRequestInit(
  data: any,
  methodOrRequestInit: IRequestInit['method'] | IRequestInit = 'POST'
): IRequestInit {
  const defaultRequestInit = {
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
    },
  };

  if (typeof methodOrRequestInit === 'string') {
    return {
      ...defaultRequestInit,
      method: methodOrRequestInit,
    };
  }

  return {
    method: 'POST',
    ...defaultRequestInit,
    ...methodOrRequestInit,
    headers: {
      ...defaultRequestInit.headers,
      ...methodOrRequestInit?.headers,
    },
  };
}

let refreshing: Promise<string> | undefined;
export async function refreshToken(
  authorization: Authorization
): Promise<string> {
  if (refreshing) {
    return refreshing;
  }

  refreshing = (async () => {
    try {
      const refresh = await fetch(
        `${API_URL}/auth/refresh`,
        prepareSubmitRequestInit(authorization.getCredentials())
      );

      if (refresh.status === httpStatus.UNAUTHORIZED) {
        await authorization.destroy();
        return undefined;
      }

      const { jwt } = await refresh.json();
      await authorization.setJWT(jwt);
      return jwt;
    } finally {
      refreshing = undefined;
    }
  })();

  return refreshing;
}

/**
 * Generic function to fetch API.
 */
export async function fetchApi(
  endpoint: string,
  init: IRequestInit = { method: 'GET' },
  authOverwrite?: AuthorizationCommon.IAuthorization
) {
  let { headers } = init;
  const authorization = Authorization.getInstance();

  const credentials =
    authOverwrite || (authorization && authorization.getCredentials());

  if (credentials) {
    headers = {
      ...headers,
      Authorization: `bearer ${credentials.jwt}`,
    };
  }

  const endpointURL = API_URL + endpoint;

  let response = await fetch(endpointURL, { ...init, headers });

  if (
    response.status === httpStatus.UNAUTHORIZED &&
    authorization &&
    credentials
  ) {
    headers = {
      ...headers,
      Authorization: `bearer ${await refreshToken(authorization)}`,
    };

    response = await fetch(endpointURL, { ...init, headers });
  }

  if (response.status === httpStatus.UNPROCESSABLE_ENTITY) {
    throw new HttpError(
      'Unprocessable entity',
      response.status,
      await response.json()
    );
  }

  if (response.status === httpStatus.UNAUTHORIZED) {
    throw new HttpError('Unauthorized', response.status);
  }

  if (response.status >= 300) {
    let jsonResponse;
    try {
      jsonResponse = await response.json();
    } catch (e) {
      /* ignored, response can be empty */
    }
    throw new HttpError(
      jsonResponse?.error || DEFAULT_ERROR_MESSAGE,
      response.status,
      jsonResponse
    );
  }

  if (response.headers.get('Deprecated')) {
    const deprecatedURL = new URL(response.url);
    Logger.error(
      new Error(
        `${deprecatedURL.origin}${deprecatedURL.pathname} is deprecated.`
      )
    );
  }

  return response;
}

/**
 * Submit data to server and handle response status.
 */
export async function submitData(
  endpoint: string,
  data: any,
  methodOrRequestInit?: IRequestInit['method'] | IRequestInit
) {
  const init = prepareSubmitRequestInit(data, methodOrRequestInit);
  const response = await fetchApi(endpoint, init);
  if (response.status !== httpStatus.NO_CONTENT) {
    return response.json();
  }
  return null;
}
