import { processGenericFilter, Value } from './generic';

export enum CountryField {
  JOKER = 'q',
}

export function processCountryFilter(
  field: CountryField | string,
  value: Value
) {
  switch (field) {
    case CountryField.JOKER:
      return {
        $or: [
          { name: { $regex: value, $options: 'i' } },
          { code: { $regex: value, $options: 'i' } },
        ],
      };

    default:
      return processGenericFilter(field, value);
  }
}
