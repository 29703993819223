import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { AddCircleOutline, RemoveCircleOutline } from '@material-ui/icons';
import React from 'react';
import {
  ArrayInput,
  Button,
  required,
  SimpleFormIteratorProps,
  TextInput,
} from 'react-admin';
import styled from 'styled-components';
import { Drops } from '~business/pack/loot-table/inputs/Drop';

const StyledSummary = styled(AccordionSummary)`
  background: silver;
`;

const StyledDetails = styled(AccordionDetails)`
  border: 5px solid silver;
  display: flex;
  flex-direction: column;
`;

const CloneButton = styled(Button)`
  margin-left: auto;
  padding: 2px 6px 0 0;
`;

const useStyles = makeStyles((theme) => ({
  removeButton: {
    color: theme.palette.error.main,
    borderColor: theme.palette.error.main,
    '&:hover': {
      background: 'none',
      color: 'darkred',
    },
    margin: 'auto',
  },
  addButton: {
    marginTop: '12px',
    '&:hover': {
      background: 'none',
      color: 'darkblue',
    },
  },
}));

const LootIterator = ({ fields, source }: SimpleFormIteratorProps) => {
  const classes = useStyles();

  const handleAdd = () =>
    fields?.push({
      name: '',
      drops: [],
    });

  const handleRemove = (index: number) => () => {
    fields?.remove(index);
  };

  const cloneLoot = (index: number) => {
    if (!fields) {
      return;
    }
    const { name, ...restLoot } = fields.value[index];
    fields?.push({ ...restLoot, name: `${name} cloned` });
  };

  return (
    <Box display="flex" flexDirection="column">
      {fields?.value.map((loot, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <Accordion key={`${index}`}>
          <StyledSummary>
            <Typography variant="h6">{loot.name}</Typography>
          </StyledSummary>
          <StyledDetails>
            <Box display="flex" flexDirection="column">
              <Box display="flex" flexDirection="row">
                <CloneButton
                  label="Clone"
                  variant="outlined"
                  onClick={() => cloneLoot(index)}
                />
                <Button
                  startIcon={<RemoveCircleOutline />}
                  label={`Remove ${loot.name || 'loot'}`}
                  onClick={handleRemove(index)}
                  className={classes.removeButton}
                  variant="outlined"
                />
              </Box>
              <TextInput
                validate={required()}
                source={`${source}[${index}].name`}
                label="Name"
              />
              <Drops source={`${source}[${index}]`} />
            </Box>
          </StyledDetails>
        </Accordion>
      ))}
      <Button
        startIcon={<AddCircleOutline />}
        label="Add loot"
        onClick={handleAdd}
        className={classes.addButton}
      />
    </Box>
  );
};

export const Loots = () => (
  <ArrayInput source="loots" label="">
    {
      // @ts-ignore
      <LootIterator />
    }
  </ArrayInput>
);
