import { FilterPayload } from 'react-admin';
import { Blockchain, Database, SoccerData } from 'ultimate-league-common';
import { CardField } from '~technical/filters/nftCard';

import { SPORT_CONTEXT } from '../../constant';

interface ICardQuery {
  sport: SoccerData.Sport;
  level: Blockchain.NFTCard.RarityLevel;
  athlete?: Database.DocumentId[];
  athletePosition?: SoccerData.Athlete.AthletePosition[];
  athleteAge?: {
    minAge: number;
    maxAge: number;
  };
  ftuePrice?: {
    minPrice: number;
    maxPrice: number;
  };
  team?: Database.DocumentId[];
  season?: Database.DocumentId[];
  batch?: Database.DocumentId[];
  specialEdition?: Database.DocumentId[];
  competition?: Database.DocumentId[];
}

enum CardQueryField {
  QUERY_SPORT = 'sport',
  QUERY_LEVEL = 'level',
  QUERY_ATHLETE = 'athlete',
  QUERY_ATHLETE_POSITION = 'athletePosition',
  QUERY_ATHLETE_AGE = 'athleteAge',
  QUERY_FTUE_PRICE = 'ftuePrice',
  QUERY_TEAM = 'team',
  QUERY_SEASON = 'season',
  QUERY_BATCH = 'batch',
  QUERY_SPECIAL_EDITION = 'specialEdition',
  QUERY_COMPETITION = 'competition',
  QUERY_CONDITION = 'condition',
  QUERY_TIER = 'athleteTier',
  QUERY_AUCTIONABLE = 'auctionable',
}

const FilterQueryMap: Record<CardField, CardQueryField> = {
  [CardField.SEARCH_SPORT]: CardQueryField.QUERY_SPORT,
  [CardField.SEARCH_LEVEL]: CardQueryField.QUERY_LEVEL,
  [CardField.SEARCH_ATHLETE]: CardQueryField.QUERY_ATHLETE,
  [CardField.SEARCH_ATHLETE_POSITION]: CardQueryField.QUERY_ATHLETE_POSITION,
  [CardField.SEARCH_ATHLETE_AGE]: CardQueryField.QUERY_ATHLETE_AGE,
  [CardField.SEARCH_FTUE_PRICE]: CardQueryField.QUERY_FTUE_PRICE,
  [CardField.SEARCH_TEAM]: CardQueryField.QUERY_TEAM,
  [CardField.SEARCH_SEASON]: CardQueryField.QUERY_SEASON,
  [CardField.SEARCH_BATCH]: CardQueryField.QUERY_BATCH,
  [CardField.SEARCH_SPECIAL_EDITION]: CardQueryField.QUERY_SPECIAL_EDITION,
  [CardField.SEARCH_COMPETITION]: CardQueryField.QUERY_COMPETITION,
  [CardField.SEARCH_CONDITION]: CardQueryField.QUERY_CONDITION,
  [CardField.SEARCH_TIER]: CardQueryField.QUERY_TIER,
  [CardField.AUCTIONABLE]: CardQueryField.QUERY_AUCTIONABLE,
};

export function reactAdminCardFilterToQuery(filter: FilterPayload) {
  const computedQuery: ICardQuery = {
    sport: SPORT_CONTEXT,
    level: filter[CardField.SEARCH_LEVEL],
  };

  const filterAthleteAge = filter[CardField.SEARCH_ATHLETE_AGE];
  if (filterAthleteAge) {
    computedQuery.athleteAge = {
      minAge: filterAthleteAge[0],
      maxAge: filterAthleteAge[1],
    };
  }

  const filterFtuePrice = filter[CardField.SEARCH_FTUE_PRICE];
  if (filterFtuePrice) {
    computedQuery.ftuePrice = {
      minPrice: filterFtuePrice[0],
      maxPrice: filterFtuePrice[1],
    };
  }

  return Object.keys(filter).reduce((query, key) => {
    const value = filter[key];

    if (value instanceof Array && value.length === 0) {
      return query;
    }

    return {
      [FilterQueryMap[key]]: value,
      ...query,
    };
  }, computedQuery);
}

const QueryFilterMap: Record<CardQueryField, CardField> = Object.keys(
  FilterQueryMap
).reduce(
  (mapping, key) => ({
    ...mapping,
    [FilterQueryMap[key]]: key,
  }),
  {} as Record<CardQueryField, CardField>
);

export function queryToReactAdminFilter(query: ICardQuery) {
  const computedFilter: FilterPayload = {
    [CardField.SEARCH_LEVEL]: query.level,
  };

  if (query.athleteAge) {
    computedFilter[CardField.SEARCH_ATHLETE_AGE] = [
      query.athleteAge.minAge,
      query.athleteAge.maxAge,
    ];
  }

  if (query.ftuePrice) {
    computedFilter[CardField.SEARCH_FTUE_PRICE] = [
      query.ftuePrice.minPrice,
      query.ftuePrice.maxPrice,
    ];
  }

  return Object.keys(query).reduce((filter, key) => {
    if (key === CardQueryField.QUERY_SPORT) {
      return filter;
    }

    const value = query[key];

    if (value instanceof Array && value.length === 0) {
      return filter;
    }

    return {
      [QueryFilterMap[key]]: value,
      ...filter,
    };
  }, computedFilter);
}
