import React, { useEffect, useRef } from 'react';
import { useField } from 'react-final-form';
import {
  AthleteListInContext,
  useAthletesListController,
} from '~business/athlete/AthleteList';

const AthletesSelector = () => {
  const {
    input: { onChange, value },
  } = useField<string[]>('athletes');

  const athletesController = useAthletesListController({
    onToggleItem: (toggledId: string) => {
      const index = value.findIndex((id) => id === toggledId);
      onChange(
        index >= 0
          ? [...value.slice(0, index), ...value.slice(index + 1)]
          : [...value, toggledId]
      );
    },
    selectedIds: value,
    sort: {
      field: '_id',
      order: 'ASC',
    },
  });

  const effectDependencies = useRef({ onChange, value });

  useEffect(() => {
    if (effectDependencies.current.value) {
      return;
    }
    effectDependencies.current.onChange([]);
  }, [effectDependencies]);

  return (
    <>
      <p style={{ fontWeight: 'bold', textAlign: 'center' }}>
        {`${athletesController.selectedIds.length} athletes selected.`}
      </p>
      <AthleteListInContext contextValue={athletesController} />
    </>
  );
};

export default AthletesSelector;
