import { Typography } from '@material-ui/core';
import React, { FC } from 'react';
import {
  Show,
  Tab,
  TabbedShowLayout,
  TextField,
  Toolbar,
  usePermissions,
  useShowController,
} from 'react-admin';
import ShowNftButton from '~business/common/components/ShowNftButton';
import ShowRarityAssets from '~business/common/components/ShowRarityAssets';

import AthletesCount from './components/AthletesCount';
import CustomAssetsSummary from './components/CustomAssetsSummary';
import CustomTemplatesSummary from './components/CustomTemplatesSummary';
import SeasonTitle from './components/SeasonTitle';
import CardPreview from './components/card-preview';
import { Label, ShowRow, Value } from './components/styles';
import { ISavedNftBatch } from './types';

const NO_TEMPLATE_LEGEND = 'No custom template. The season template is used.';

const AvailableCards = ({ record: { id, isLive } }: any) => (
  <>
    {!isLive ? (
      <Typography variant="subtitle1">Batch not published yet</Typography>
    ) : (
      <Toolbar>
        <ShowNftButton filter={{ batch: [id] }} />
      </Toolbar>
    )}
  </>
);

interface IProps {
  record?: ISavedNftBatch;
}

const IsLive: FC<IProps> = ({ record }: IProps) => (
  <ShowRow>
    <Typography variant="body2" component="div">
      <Label>Is live?</Label>
      <Value>{record!.isLive === true ? 'Yes' : 'No'}</Value>
    </Typography>
  </ShowRow>
);

export const NftBatchShow = (props: any) => {
  const { record } = useShowController(props);
  const { permissions } = usePermissions();
  return (
    <Show {...props} hasEdit={false}>
      <TabbedShowLayout>
        <Tab label="summary">
          <TextField source="label" />
          <SeasonTitle permissions={permissions} />
          <AthletesCount />
          <CustomAssetsSummary />
          <CustomTemplatesSummary />
          <IsLive />
        </Tab>
        <Tab label="template">
          <TextField source="cardTemplate" emptyText={NO_TEMPLATE_LEGEND} />
          <TextField source="cardTemplateValues" />
        </Tab>
        <Tab label="Overriden rarity assets">
          <ShowRarityAssets rarities={record?.rarities} />
        </Tab>
        <Tab label="preview">
          <CardPreview />
        </Tab>
        <Tab label="Cards">
          <AvailableCards />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};
