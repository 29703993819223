import {
  Authorization as AuthorizationCommon,
  User,
} from 'ultimate-league-common';

export type CurrentUser = Pick<User.IUser, 'id' | 'username' | 'role'>;

export class Authorization {
  private static instance: Authorization;

  public static getInstance() {
    if (!Authorization.instance) {
      Authorization.instance = new Authorization();
    }

    return Authorization.instance;
  }

  private constructor() {
    this.parseState();
  }

  private credentials?: AuthorizationCommon.IAuthorization;

  private user?: CurrentUser;

  public setState(
    credentials: AuthorizationCommon.IAuthorization,
    { id, username, role }: CurrentUser
  ): void {
    this.credentials = credentials;
    this.user = {
      id,
      username,
      role,
    };
    this.storeState();
  }

  public destroy() {
    this.credentials = undefined;
    this.user = undefined;
    localStorage.removeItem('__AUTH_STATE__');
  }

  public setJWT(jwt: string): void {
    if (!this.credentials) {
      throw new Error('Missing credentials');
    }

    this.credentials.jwt = jwt;
    this.storeState();
  }

  public getCredentials() {
    return this.credentials;
  }

  public getUser() {
    return this.user;
  }

  private storeState() {
    localStorage.setItem(
      '__AUTH_STATE__',
      JSON.stringify({
        credentials: this.credentials,
        user: this.user,
      })
    );
  }

  private parseState() {
    const rawState = localStorage.getItem('__AUTH_STATE__');
    if (!rawState) {
      return;
    }

    const { credentials, user } = JSON.parse(rawState);
    if (!credentials || !user) {
      return;
    }

    this.credentials = credentials;
    this.user = user;
  }
}
