import React from 'react';
import { BulkDeleteButton } from 'react-admin';
import styled from 'styled-components';

import { CloneGameWeekButton } from './CloneGameWeekButton';

const BulkButtons = styled.div`
  & > button {
    margin: 0 10px;
  }
`;

export const GameWeekBulkActions = () => (
  <BulkButtons>
    <CloneGameWeekButton />
    <BulkDeleteButton />
  </BulkButtons>
);
