import SendIcon from '@material-ui/icons/Send';
import React from 'react';
import { Button, Confirm } from 'react-admin';
import { useBoolean } from 'usehooks-ts';

import { useMonetization } from '../hooks';
import { IPackConfig } from '../types';

const noop = () => {};

export const CreatePacksButton = (props: { record: IPackConfig }) => {
  const { record } = props;
  const { canCreatePacks, createPacks, isMonetizationEnabled } =
    useMonetization(record);
  const {
    value: mustConfirm,
    setTrue: setMustConfirmTrue,
    setFalse: setMustConfirmFalse,
  } = useBoolean(false);

  if (!isMonetizationEnabled) {
    return null;
  }

  return (
    <>
      <Button
        disabled={!canCreatePacks}
        label={
          record.monetization?.hasInfiniteSupply
            ? 'Create 1 pack'
            : 'Fill packs'
        }
        onClick={!canCreatePacks ? noop : setMustConfirmTrue}
      >
        <SendIcon />
      </Button>

      <Confirm
        isOpen={mustConfirm}
        title="Packs creation"
        content="Please confirm packs creation."
        onConfirm={() => {
          setMustConfirmFalse();
          createPacks();
        }}
        onClose={setMustConfirmFalse}
      />
    </>
  );
};
