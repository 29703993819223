import { processGenericFilter, Value } from './generic';

export enum UserField {
  USERNAME = 'username',
  EMAIL = 'email',
  STRIPE_CUSTOMER_ID = 'stripe_customerId',
  COHORTS = 'cohorts',
  DELETED = 'deleted',
}

export function processUserFilter(field: UserField | string, value: Value) {
  switch (field) {
    case UserField.USERNAME:
      return { username: { $regex: value, $options: 'i' } };

    case UserField.EMAIL:
      return { 'messaging.email': { $regex: value, $options: 'i' } };

    case UserField.COHORTS:
      return { cohorts: { $in: value } };

    case UserField.STRIPE_CUSTOMER_ID:
      return { 'payments.stripeCustomerId': value };

    default:
      return processGenericFilter(field, value);
  }
}
