import {
  Blockchain,
  enumToArray,
  SoccerData,
  Storage,
} from 'ultimate-league-common';

export const rarityLevelChoice = Blockchain.BrandConfig.activatedRarities.map(
  (level) => ({
    id: level,
    name: Blockchain.NFTCard.RarityLevel[level],
  })
);

export const conditionChoice = enumToArray(
  Blockchain.NFTCard.ConditionBonus.ConditionBonus
).map((condition) => ({
  id: condition,
  name: condition,
}));

export type LocalFile = {
  rawFile: File;
};

export type IAsset = LocalFile | Storage.IStorage['id'];

export interface IInputCardAsset {
  file: IAsset;
  mediaType?: string;
}
export interface ISavedCardAsset {
  file: string;
  mediaType: string;
}

interface IBaseRaritySupply {
  level: Blockchain.NFTCard.RarityLevel;
}

export interface IInputPositionAssets {
  position: SoccerData.Athlete.AthletePosition;
  cardAssets: IInputCardAsset[];
}
export interface ISavedPositionAssets {
  position: SoccerData.Athlete.AthletePosition;
  cardAssets: ISavedCardAsset[];
}

export interface IInputRaritySupply extends IBaseRaritySupply {
  supply?: number;
  assetsPerPosition: IInputPositionAssets[];
}
export interface ISavedRaritySupply extends IBaseRaritySupply {
  supply: number;
  assetsPerPosition: ISavedPositionAssets[];
}
