import React from 'react';
import { Edit, EditProps } from 'react-admin';

import { CountryForm } from './CountryForm';

export const CountryEdit = (props: EditProps) => (
  <Edit {...props}>
    {/* @ts-ignore */}
    <CountryForm />
  </Edit>
);
