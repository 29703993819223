import { makeStyles, TableCell } from '@material-ui/core';
import TableRow from '@material-ui/core/TableRow';
import { DefaultTheme } from '@material-ui/styles';
import React from 'react';
import styled from 'styled-components';
import { defaultLang } from 'ultimate-league-common';
import { IPackConfigWithPacksCount } from '~business/pack/pack-config/types';

const { GOOGLE_PUBLIC_BUCKET_NAME } = process.env;

if (!GOOGLE_PUBLIC_BUCKET_NAME) {
  throw new Error('Missing env GOOGLE_PUBLIC_BUCKET_NAME');
}

const GOOGLE_PUBLIC_BUCKET_URL = `https://storage.googleapis.com/${GOOGLE_PUBLIC_BUCKET_NAME}`;

interface IVisualProps {
  path: string;
}
const Visual = styled.div<IVisualProps>`
  width: 80px;
  height: 80px;
  background-image: ${({ path }) => `url('${path}')`};
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
`;

function getRowBackgroundColor(isSelected: boolean, hasEnoughSupply: boolean) {
  if (isSelected) {
    return '#caffca';
  }
  if (!hasEnoughSupply) {
    return 'lightgrey';
  }
  return 'transparent';
}

const useStyles = makeStyles<
  DefaultTheme,
  { isSelected: boolean; hasEnoughSupply: boolean }
>({
  row: {
    backgroundColor: ({ isSelected, hasEnoughSupply }) =>
      getRowBackgroundColor(isSelected, hasEnoughSupply),
    opacity: ({ hasEnoughSupply }) => (hasEnoughSupply ? 1 : 0.5),
    cursor: ({ hasEnoughSupply }) => (hasEnoughSupply ? 'pointer' : 'default'),

    '& > td': {
      padding: '6px 16px',
    },
  },
});

interface IProps {
  packConfig: IPackConfigWithPacksCount;
  onSelect: (packConfigId: IPackConfigWithPacksCount['id']) => void;
  isSelected: boolean;
  hasEnoughSupply: boolean;
}

export const PackConfigElement = ({
  packConfig,
  onSelect,
  isSelected,
  hasEnoughSupply,
}: IProps) => {
  const classes = useStyles({ isSelected, hasEnoughSupply });

  return (
    <TableRow
      className={classes.row}
      onClick={!hasEnoughSupply ? undefined : () => onSelect(packConfig.id)}
    >
      <TableCell>
        {packConfig.visuals[0] && (
          <Visual
            path={`${GOOGLE_PUBLIC_BUCKET_URL}/${packConfig.visuals[0]}`}
          />
        )}
      </TableCell>
      <TableCell>{packConfig.displayName[defaultLang]}</TableCell>
      <TableCell align="center">{packConfig.availablePacksCount}</TableCell>
      <TableCell align="center">
        {packConfig.monetization?.isGiveaway ? 'yes 🎁' : 'no'}
      </TableCell>
    </TableRow>
  );
};
