const { IPFS_GATEWAY } = process.env;
if (!IPFS_GATEWAY) {
  throw new Error('Missing env IPFS_GATEWAY');
}

export function extractCID(uri: string) {
  const url = new URL(uri);
  if (url.protocol !== 'ipfs:') {
    throw new Error(`Invalid protocol. ${uri} should be an ipfs:// url`);
  }

  return uri.substr('ipfs://'.length);
}

export function buildURL(cid: string) {
  return `${IPFS_GATEWAY}/ipfs/${cid}`;
}
