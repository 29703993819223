import { SoccerData } from 'ultimate-league-common';

export const SPORT_CONTEXT = (() => {
  const sportFromURL = SoccerData.Sports.find((sport) =>
    global.location.hostname.includes(sport.toLowerCase())
  );
  if (sportFromURL) {
    return sportFromURL;
  }

  return process.env.SPORT_CONTEXT as SoccerData.Sport;
})();
if (!SPORT_CONTEXT || SoccerData.Sports.indexOf(SPORT_CONTEXT) < 0) {
  throw new Error(`Invalid env SPORT_CONTEXT (${SPORT_CONTEXT})`);
}
