import { TextField, Typography } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import { useListContext } from 'react-admin';
import styled from 'styled-components';

const FieldWrapper = styled.div`
  margin-right: 1rem;
`;

interface IValues {
  dateMin: string;
  dateMax: string;
}

const labels = {
  dateMin: 'Date min',
  dateMax: 'Date max',
};

const DEFAULT_TYPE = 'date';

interface IProps {
  source: string;
  type?: 'date' | 'datetime-local';
}

export const DateRangeInput = ({ source, type }: IProps) => {
  const { filterValues, displayedFilters, setFilters } = useListContext();

  const isVisible = !!displayedFilters[source];
  const [values, setValues] = useState<IValues>({
    dateMin: '',
    dateMax: '',
  });

  const onChangeHandler = useCallback(
    (event) => {
      const { id, value } = event.target;

      setValues({
        ...values,
        [id]: value,
      });
    },
    [values]
  );

  useEffect(() => {
    setFilters(
      {
        ...filterValues,
        [source]: !isVisible ? {} : values,
      },
      displayedFilters
    );
  }, [values, source, isVisible, setFilters, filterValues, displayedFilters]);

  return !isVisible ? null : (
    <>
      {Object.keys(values).map((id) => (
        <FieldWrapper key={id}>
          <Typography variant="body2">{labels[id]}</Typography>
          <TextField
            key={id}
            id={id}
            type={type ?? DEFAULT_TYPE}
            value={values[id]}
            onChange={onChangeHandler}
          />
        </FieldWrapper>
      ))}
    </>
  );
};
