import { Button } from '@material-ui/core';
import LinkIcon from '@material-ui/icons/Link';
import React from 'react';
import { Blockchain } from 'ultimate-league-common';
import { SCAN_URL } from '~business/nft-card/service';

import { IGeneratedCard } from '../interfaces';
import { ButtonLabel } from './ButtonLabel';

const label = 'See NFT on the blockchain';

interface IProps {
  card: IGeneratedCard;
}

export const BlockchainButton = ({ card }: IProps) =>
  !card.mint ? null : (
    <Button
      title={label}
      color="secondary"
      href={`${SCAN_URL[card.blockchain]}/token/${
        Blockchain.get(card.blockchain).ULTIMATE_LEAGUE_NFT_ADDRESS
      }?a=${card.mint.nft}`}
      target="_blank"
      size="small"
      variant="contained"
    >
      <LinkIcon />
      <ButtonLabel label={label} />
    </Button>
  );
