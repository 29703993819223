import { Box, Typography } from '@material-ui/core';
import React, { FC } from 'react';
import { ArrayInput, FormDataConsumer, NumberInput } from 'react-admin';
import styled from 'styled-components';

import PositionsInputIterator from '../../common/components/PositionsInputIterator';

const RarityLabel = styled.div`
  margin-top: 0;
  padding: 0.4em;
  text-align: center;
  letter-spacing: 2px;
  background-color: #cfcfcf;
`;
const StyledNumberInput = styled(NumberInput)`
  input {
    border: 1px solid #b9b9b9;
    background-color: white;
  }
`;
const StyledArrayInput = styled(ArrayInput)`
  margin-top: 4px;
`;

const NoticeWrapper = styled.div`
  margin-bottom: 1em;
  font-style: italic;
  color: grey;
`;

const NoOverride = ({ rarityKey }: any) => (
  <NoticeWrapper>
    <Typography variant="body2">
      No override (= season assets will be used for level {rarityKey})
    </Typography>
  </NoticeWrapper>
);

const NoCardForRarity = ({ rarityKey }: any) => (
  <NoticeWrapper>
    <Typography variant="body2">
      <b>
        {'No card will be generated for level '}
        {rarityKey}
      </b>
    </Typography>
  </NoticeWrapper>
);

interface IProps {
  rarityKey: string;
}

const RarityInputs: FC<IProps> = ({ rarityKey }: IProps) => (
  <Box
    title={rarityKey}
    border="2px solid grey"
    margin="6px 0"
    padding="10px 10px 0 10px"
  >
    <RarityLabel>
      <Typography variant="h6">{rarityKey} ASSETS</Typography>
    </RarityLabel>

    <StyledNumberInput
      label={`${rarityKey.toLowerCase()} supply`}
      source={`rarities.${rarityKey}.supply`}
    />

    <FormDataConsumer>
      {({ formData }) => {
        const raritySupply = formData.rarities[rarityKey];

        if (typeof raritySupply?.supply !== 'number') {
          return <NoOverride rarityKey={rarityKey} />;
        }
        if (raritySupply.supply === 0) {
          return <NoCardForRarity rarityKey={rarityKey} />;
        }
        return (
          <StyledArrayInput
            source={`rarities.${rarityKey}.assetsPerPosition`}
            label=""
          >
            {/* @ts-ignore */}
            <PositionsInputIterator />
          </StyledArrayInput>
        );
      }}
    </FormDataConsumer>
  </Box>
);

export default RarityInputs;
