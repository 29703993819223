import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { useListContext } from 'react-admin';

import { IRecordNFT } from '../interfaces';
import { CardItem } from '../item';

/* Styled Components */

const useCardGridListStyles = makeStyles(() => ({
  grid: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      margin: 12,
      padding: 24,
      boxSizing: 'border-box',
      borderRadius: 10,
      height: 300,
      width: 200,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
      position: 'relative',
    },
    '& .actions': {
      opacity: 0,
      position: 'absolute',
      bottom: 0,
      left: 0,
      right: 0,
      display: 'flex',
      justifyContent: 'flex-end',
      padding: 12,
      transition: 'all 0.3s ease',
    },
    '& .minting': {
      opacity: 1,
    },
    '& > *:hover .actions': {
      opacity: 1,
    },
    '& .actions > button': {
      background: 'white',
    },
  },
}));

/* Utility Components */

const LoadingGridList = () => {
  const classes = useCardGridListStyles();
  return (
    <div className={classes.grid}>
      {Array.from(Array(20)).map((_, key) => (
        // eslint-disable-next-line react/no-array-index-key
        <div key={key} />
      ))}
    </div>
  );
};

const LoadedGridList = () => {
  const { ids, data } = useListContext<IRecordNFT>();
  const classes = useCardGridListStyles();

  if (!ids || !data) return null;

  return (
    <div className={classes.grid}>
      {ids.map((id) => (
        <CardItem key={id} redirectOnClick card={data[id]} />
      ))}
    </div>
  );
};

/* Main Component */

export const NFTCardGridList = () => {
  const { loaded } = useListContext();
  return loaded ? <LoadedGridList /> : <LoadingGridList />;
};
