import React from 'react';
import { Create, CreateProps } from 'react-admin';

import { FormationForm } from './FormationForm';

export const FormationCreate = (props: CreateProps) => (
  <Create {...props}>
    {
      // @ts-ignore
      <FormationForm />
    }
  </Create>
);
