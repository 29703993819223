import FileCopy from '@material-ui/icons/FileCopy';
import React, { useCallback } from 'react';
import {
  Button,
  Confirm,
  useListContext,
  useNotify,
  useRefresh,
  useUnselectAll,
} from 'react-admin';
import { useBoolean } from 'usehooks-ts';
import { fetchApi, prepareSubmitRequestInit } from '~technical/api';

export const ClonePackConfigButton = () => {
  const {
    value: mustConfirm,
    setTrue: setMustConfirmTrue,
    setFalse: setMustConfirmFalse,
  } = useBoolean(false);

  const { resource, selectedIds } = useListContext();

  const refresh = useRefresh();
  const notify = useNotify();
  const unselectAll = useUnselectAll();

  const handleClone = useCallback(async () => {
    try {
      await fetchApi(
        `/backoffice/pack-config/clone`,
        prepareSubmitRequestInit({
          packConfigs: selectedIds,
        })
      );

      unselectAll(resource);
      refresh();
      notify('Pack Config(s) cloned', 'info');
    } catch (e) {
      notify(`Pack Config(s) not cloned (${(e as Error).message})`, 'warning');
    }
  }, [resource, selectedIds, notify, refresh, unselectAll]);

  return (
    <>
      <Button label="Clone" onClick={setMustConfirmTrue}>
        <FileCopy />
      </Button>
      <Confirm
        isOpen={mustConfirm}
        title="Pack Config cloning"
        content="Please confirm pack config cloning."
        onConfirm={() => {
          setMustConfirmFalse();
          handleClone();
        }}
        onClose={setMustConfirmFalse}
      />
    </>
  );
};
