import { Typography } from '@material-ui/core';
import React from 'react';
import {
  ArrayInput,
  NumberInput,
  SelectInput,
  SimpleFormIterator,
} from 'react-admin';
import styled from 'styled-components';
import { rarityLevelChoice } from '~business/common/assetTypes';

const GroupArea = styled.div`
  margin: 1rem 1rem 1rem 0;
  padding: 0.6rem 1rem;
  border: 1px solid #d9d9d9;
`;

const StyledSelectInput = styled(SelectInput)``;
const StyledNumberInput = styled(NumberInput)``;

const StyledSimpleFormIterator = styled(SimpleFormIterator)`
  li section {
    display: flex;
  }

  ${StyledSelectInput}, ${StyledNumberInput} {
    width: 88px;
    margin-right: 24px;
  }
`;

export const DivisionRequirementInput = ({ source }: { source: string }) => (
  <GroupArea>
    <Typography variant="subtitle1">Divisions requirements</Typography>
    <ArrayInput source={source} label="">
      <StyledSimpleFormIterator getItemLabel={() => ''}>
        <StyledSelectInput
          label="Rarity"
          source="rarity"
          choices={rarityLevelChoice}
        />
        <StyledNumberInput label="Min" source="min" step={1} />
        <StyledNumberInput label="Max" source="max" step={1} />
      </StyledSimpleFormIterator>
    </ArrayInput>
  </GroupArea>
);
