export type DateRangeValue = {
  dateMin?: string;
  dateMax?: string;
};

export type Value = string | number | object | Value[] | DateRangeValue;

function isDateRangeValue(value: Value): value is DateRangeValue {
  const dateRangeValue = value as DateRangeValue;
  return !!(dateRangeValue.dateMin || dateRangeValue.dateMax);
}

type DateRangeFilter = {
  $gt?: string;
  $lt?: string;
};

function parseDateRangeFilter({ dateMin, dateMax }: DateRangeValue) {
  if (!dateMin && !dateMax) {
    return {};
  }
  const filter: DateRangeFilter = {};
  if (dateMin) {
    filter.$gt = dateMin;
  }
  if (dateMax) {
    filter.$lt = dateMax;
  }
  return filter;
}

export function processGenericFilter(field: string, value: Value) {
  if (isDateRangeValue(value)) {
    return {
      [field]: parseDateRangeFilter(value),
    };
  }

  return {
    [field]: value,
  };
}
