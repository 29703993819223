import Button from '@material-ui/core/Button';
import FeaturedPlayListIcon from '@material-ui/icons/FeaturedPlayList';
import React from 'react';

import { IGeneratedCard } from '../interfaces';
import { ButtonLabel } from './ButtonLabel';

const { IPFS_GATEWAY } = process.env;

if (!IPFS_GATEWAY) {
  throw new Error('Missing env IPFS_GATEWAY');
}

const label = 'See metadata on IPFS';

export const MetadataButton = ({ card }: { card: IGeneratedCard }) => (
  <Button
    title={label}
    color="secondary"
    href={`${IPFS_GATEWAY}/ipfs/${card.metadata.CID}`}
    target="_blank"
    size="small"
    variant="contained"
  >
    <FeaturedPlayListIcon />
    <ButtonLabel label={label} />
  </Button>
);
