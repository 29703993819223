import { processGenericFilter, Value } from './generic';

export enum SeasonFilterFields {
  Title = 'title',
}

export function processNftSeasonFilter(
  field: SeasonFilterFields | string,
  value: Value
) {
  switch (field) {
    case SeasonFilterFields.Title:
      return { title: { $regex: value, $options: 'i' } };
    default:
      return processGenericFilter(field, value);
  }
}
