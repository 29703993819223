import React from 'react';
import {
  Edit,
  EditProps,
  SelectInput,
  SimpleForm,
  TextInput,
} from 'react-admin';
import { Blockchain, t } from 'ultimate-league-common';
import { rarityLevelChoice } from '~business/common/assetTypes';

import { RarityIcons } from './assets';

function optionText({ id }: { id: Blockchain.NFTCard.RarityLevel }) {
  const Icon = RarityIcons[id];

  return Icon ? (
    <>
      <Icon height={14} style={{ marginRight: 6 }} />
      {t(Blockchain.NFTCard.rarityI18NKeys[id])}
    </>
  ) : (
    <></>
  );
}

export const FilterEdit = (props: EditProps) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput source="reference" />
      <SelectInput
        label="Level"
        source="query.level"
        choices={rarityLevelChoice}
        optionText={optionText}
        translateChoice={false}
      />
    </SimpleForm>
  </Edit>
);
