import React from 'react';
import { EditButton } from 'react-admin';
import styled from 'styled-components';

import { ClearPacksButton } from './ClearPacksButton';
import { CreatePacksButton } from './CreatePacksButton';

const ActionsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  & > * {
    margin-left: 1.5em;
  }
`;

export const PackConfigActions = (props: any) => (
  <ActionsWrapper>
    <EditButton {...props} />
    <CreatePacksButton {...props} />
    <ClearPacksButton {...props} />
  </ActionsWrapper>
);
