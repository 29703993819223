import React, { KeyboardEventHandler, PropsWithChildren } from 'react';
import {
  AutocompleteInput,
  Datagrid,
  DateField,
  Filter,
  List,
  NumberField,
  ReferenceField,
  ReferenceInput,
  SelectField,
  SelectInput,
  TextField,
} from 'react-admin';
import { Link } from 'react-router-dom';
import { enumToArray } from 'ultimate-league-common';
import { UserField } from '~technical/filters/user';

enum TransferType {
  SALE = 'SALE',
  PACK = 'PACK',
}

const ulcTransferTypeChoices = enumToArray(TransferType).map((id) => ({
  id,
  name: id,
}));

const ulcTransferStatusChoices = [
  { id: 'SUCCESS', name: 'Success' },
  { id: 'ERROR', name: 'Error' },
];

const UlcTransferFilter = ({ children, ...props }: PropsWithChildren<{}>) => {
  const handleKeyPress: KeyboardEventHandler = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  };

  return (
    /* TODO: fix types */
    /* @ts-ignore */
    <Filter {...props} onKeyPress={handleKeyPress}>
      <ReferenceInput
        reference="user"
        source="user"
        filterToQuery={(search: string) => ({
          [UserField.USERNAME]: search,
        })}
      >
        <AutocompleteInput optionText="username" optionValue="id" />
      </ReferenceInput>
      <SelectInput
        label="Reference"
        source="type"
        choices={ulcTransferTypeChoices}
      />
      <SelectInput
        label="Status"
        source="status"
        choices={ulcTransferStatusChoices}
      />
      {children}
    </Filter>
  );
};

const TransferReferenceField = ({
  record,
}: {
  // eslint-disable-next-line react/no-unused-prop-types
  label?: string;
  record?: { transferType: TransferType; reference: string };
}) => (
  <Link to={`/${record?.transferType.toLowerCase()}/${record?.reference}/show`}>
    {record?.transferType}
  </Link>
);

const ULCField = ({
  record,
}: {
  // eslint-disable-next-line react/no-unused-prop-types
  label?: string;
  record?: { id: string; incUlcCent: number };
}) => (
  <NumberField
    source="ulc"
    record={record && { ...record, ulc: (record.incUlcCent / 100).toFixed(2) }}
  />
);

export const ULCTransferList = (props: {}) => (
  <List
    {...props}
    filters={<UlcTransferFilter />}
    sort={{ field: 'createdAt', order: 'DESC' }}
    bulkActionButtons={false}
  >
    <Datagrid>
      <DateField source="createdAt" showTime />
      <ReferenceField source="user" reference="user">
        <TextField source="username" />
      </ReferenceField>
      <ULCField label="MGCs" />
      <TransferReferenceField label="Reference" {...props} />
      <SelectField source="status" choices={ulcTransferStatusChoices} />
    </Datagrid>
  </List>
);
