import React from 'react';
import { Edit, EditProps } from 'react-admin';

import { TranslationForm } from './TranslationForm';

export const TranslationEdit = (props: EditProps) => (
  <Edit {...props}>
    {/* @ts-ignore */}
    <TranslationForm edit />
  </Edit>
);
