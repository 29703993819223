import { FlagProvider } from '@unleash/proxy-client-react';
import React from 'react';
import { Admin, Resource } from 'react-admin';
import { SoccerData } from 'ultimate-league-common';
import { ConfigProvider } from '~business/ConfigContext';
import { AthleteEdit } from '~business/athlete/AthleteEdit';
import { AthleteList } from '~business/athlete/AthleteList';
import { CompetitionEdit } from '~business/competition/CompetitionEdit';
import { CompetitionList } from '~business/competition/CompetitionList';
import { CompetitionShow } from '~business/competition/CompetitionShow';
import { CronList } from '~business/cron/CronList';
import { CronShow } from '~business/cron/CronShow';
import { FilterEdit } from '~business/filter/FilterEdit';
import { FilterList } from '~business/filter/FilterList';
import { FormationEdit } from '~business/formation/FormationEdit';
import { FormationList } from '~business/formation/FormationList';
import { FormationShow } from '~business/formation/FormationShow';
import { GameWeekCreate } from '~business/gameweek/GameWeekCreate';
import { GameWeekEdit } from '~business/gameweek/GameWeekEdit';
import { GameWeekList } from '~business/gameweek/GameWeekList';
import { Layout } from '~business/layout';
import { MatchEdit } from '~business/match/MatchEdit';
import { MatchList } from '~business/match/MatchList';
import { NftBatchCreate } from '~business/nft-batch/NftBatchCreate';
import { NftBatchEdit } from '~business/nft-batch/NftBatchEdit';
import { NftBatchList } from '~business/nft-batch/NftBatchList';
import { NftBatchShow } from '~business/nft-batch/NftBatchShow';
import { NFTCardList } from '~business/nft-card/NFTCardList';
import { LootTableCreate } from '~business/pack/loot-table/LootTableCreate';
import { LootTableEdit } from '~business/pack/loot-table/LootTableEdit';
import { LootTableList } from '~business/pack/loot-table/LootTableList';
import { PackConfigCreate } from '~business/pack/pack-config/PackConfigCreate';
import { PackConfigEdit } from '~business/pack/pack-config/PackConfigEdit';
import { PackConfigList } from '~business/pack/pack-config/PackConfigList';
import { PackList } from '~business/pack/pack/PackList';
import { PackShow } from '~business/pack/pack/PackShow';
import { SaleList } from '~business/sale/SaleList';
import { SaleShow } from '~business/sale/SaleShow';
import { SeasonCreate } from '~business/season/SeasonCreate';
import { SeasonEdit } from '~business/season/SeasonEdit';
import { SeasonList } from '~business/season/SeasonList';
import { SeasonShow } from '~business/season/SeasonShow';
import { TeamEdit } from '~business/team/TeamEdit';
import { TeamList } from '~business/team/TeamList';
import { TranslationCreate } from '~business/translation/TranslationCreate';
import { TranslationEdit } from '~business/translation/TranslationEdit';
import { TranslationList } from '~business/translation/TranslationList';
import { UserEdit } from '~business/user/UserEdit';
import { UserList } from '~business/user/UserList';
import { WalletShow } from '~business/wallet/WalletShow';
import { ULCTransferList } from '~business/wallet/ulc-transfer/UlcTransferList';
import { authProvider } from '~technical/authProvider';
import { dataProvider } from '~technical/dataProvider';
import { unleashConfig } from '~technical/feature-flags';

import { SPORT_CONTEXT } from '../constant';
import { FirebaseLoginPage } from './FirebaseLoginPage';
import { AuctionCreate } from './auction/AuctionCreate';
import { AuctionEdit } from './auction/AuctionEdit';
import { AuctionList } from './auction/AuctionList';
import { CountryCreate } from './country/CountryCreate';
import { CountryEdit } from './country/CountryEdit';
import { CountryList } from './country/CountryList';
import { FormationCreate } from './formation/FormationCreate';
import { NFTCardShow } from './nft-card/NFTCardShow';
import { NftSpecialEditionCreate } from './nft-special-edition/NftSpecialEditionCreate';
import { NftSpecialEditionEdit } from './nft-special-edition/NftSpecialEditionEdit';
import { NftSpecialEditionList } from './nft-special-edition/NftSpecialEditionList';
import { UserCohortCreate } from './usercohort/UserCohortCreate';
import { UserCohortEdit } from './usercohort/UserCohortEdit';
import { UserCohortList } from './usercohort/UserCohortList';

export const App = () => {
  if (!SoccerData.isActive(SPORT_CONTEXT)) {
    return (
      <div>
        <h1>Sport {SPORT_CONTEXT} is not activated.</h1>
      </div>
    );
  }

  return (
    <ConfigProvider>
      <FlagProvider config={unleashConfig}>
        <Admin
          loginPage={FirebaseLoginPage}
          /* TODO: Fix types */
          /* @ts-ignore */
          dataProvider={dataProvider}
          authProvider={authProvider}
          layout={Layout}
        >
          <Resource
            name="competition"
            list={CompetitionList}
            edit={CompetitionEdit}
            show={CompetitionShow}
          />
          <Resource
            name="country"
            list={CountryList}
            create={CountryCreate}
            edit={CountryEdit}
          />
          <Resource
            name="gameweek"
            create={GameWeekCreate}
            list={GameWeekList}
            edit={GameWeekEdit}
          />
          <Resource
            name="formation"
            show={FormationShow}
            list={FormationList}
            edit={FormationEdit}
            create={FormationCreate}
          />
          <Resource name="StackingPenaltiesConfig" />
          <Resource name="match" list={MatchList} edit={MatchEdit} />
          <Resource
            name="nftcardseason"
            list={SeasonList}
            create={SeasonCreate}
            edit={SeasonEdit}
            show={SeasonShow}
          />
          <Resource
            name="nftbatch"
            list={NftBatchList}
            create={NftBatchCreate}
            edit={NftBatchEdit}
            show={NftBatchShow}
          />
          <Resource
            name="nftspecialedition"
            create={NftSpecialEditionCreate}
            list={NftSpecialEditionList}
            edit={NftSpecialEditionEdit}
          />
          <Resource name="modifiermatrix" />
          <Resource name="team" list={TeamList} edit={TeamEdit} />
          <Resource name="storage" />
          <Resource name="athlete" list={AthleteList} edit={AthleteEdit} />
          <Resource name="NftCard" list={NFTCardList} show={NFTCardShow} />
          <Resource name="Filter" list={FilterList} edit={FilterEdit} />
          <Resource name="cron" list={CronList} show={CronShow} />
          <Resource name="pack" list={PackList} show={PackShow} />
          <Resource
            name="packConfig"
            list={PackConfigList}
            create={PackConfigCreate}
            edit={PackConfigEdit}
          />
          <Resource
            name="auction"
            list={AuctionList}
            edit={AuctionEdit}
            create={AuctionCreate}
          />
          <Resource
            name="lootTable"
            list={LootTableList}
            create={LootTableCreate}
            edit={LootTableEdit}
          />
          <Resource name="lootTableTag" />
          <Resource name="user" list={UserList} edit={UserEdit} />
          <Resource
            name="usercohort"
            create={UserCohortCreate}
            list={UserCohortList}
            edit={UserCohortEdit}
          />
          <Resource name="wallet" show={WalletShow} />
          <Resource name="ulctransfer" list={ULCTransferList} />
          <Resource name="sale" list={SaleList} show={SaleShow} />
          <Resource
            name="translation"
            list={TranslationList}
            create={TranslationCreate}
            edit={TranslationEdit}
          />
          <Resource name="translationTag" />
        </Admin>
      </FlagProvider>
    </ConfigProvider>
  );
};
