import { Typography } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import React, { useCallback, useEffect, useState } from 'react';
import {
  Button,
  useDataProvider,
  useListContext,
  useNotify,
  useUnselectAll,
} from 'react-admin';
import styled from 'styled-components';
import { IUserWithCohorts } from '~business/user/type';
import { IUserCohort } from '~business/usercohort/types';
import { fetchApi, prepareSubmitRequestInit } from '~technical/api';

import { CohortSelector } from './CohortSelector';

const MessageWrapper = styled.div`
  margin: 1.5rem 2rem;
`;

export const RemoveFromCohortButton = () => {
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const { resource, selectedIds, refetch } = useListContext();
  const unselectAll = useUnselectAll();
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [commonCohortIds, setCommonCohortIds] = useState<IUserCohort['id'][]>();

  useEffect(() => {
    (async () => {
      try {
        const result = await dataProvider.getMany<IUserWithCohorts>('user', {
          ids: selectedIds,
        });
        const users = result?.data || [];

        // List users cohort ids
        const cohortIds = new Set<IUserCohort['id']>();
        // users.map(({ cohorts }) => cohorts).flat()); // no Array.flat method available :/
        users.forEach((user) => {
          (user.cohorts || []).forEach((cohortId) => {
            cohortIds.add(cohortId);
          });
        });

        // Identify common cohort ids
        const commonCohorts: IUserCohort['id'][] = [];
        cohortIds.forEach((cohortId) => {
          if (users.every((user) => (user.cohorts || []).includes(cohortId))) {
            commonCohorts.push(cohortId);
          }
        });
        setCommonCohortIds(commonCohorts);
      } catch (e: any) {
        notify(
          `Failed to determine common cohort(s) from selected users (${
            e.message || e
          })`,
          { type: 'warning' }
        );
      }
    })();
  }, [dataProvider, selectedIds, notify]);

  const submit = useCallback(
    async (cohortId: IUserCohort['id']) => {
      try {
        await fetchApi(
          `/backoffice/user/remove-from-cohort`,
          prepareSubmitRequestInit(
            {
              cohortId,
              users: selectedIds,
            },
            'PATCH'
          )
        );
        notify('Operation successful 👍', { type: 'success' });
        setModalVisible(false);
        unselectAll(resource);
        refetch();
      } catch (error: any) {
        notify(
          `Failed to remove users from cohort! ${error.message || error}`,
          {
            type: 'error',
          }
        );
      }
    },
    [selectedIds, notify, unselectAll, resource, refetch]
  );

  return (
    <>
      <Button
        label="Remove from cohort"
        disabled={false}
        onClick={() => {
          setModalVisible(true);
        }}
      />
      <Dialog
        open={modalVisible}
        onClose={() => {
          setModalVisible(false);
        }}
      >
        {Array.isArray(commonCohortIds) && (
          <>
            {commonCohortIds.length === 0 ? (
              <MessageWrapper>
                <Typography variant="body2">
                  Selected users have no common cohort.
                </Typography>
              </MessageWrapper>
            ) : (
              <CohortSelector cohortIds={commonCohortIds} onSubmit={submit} />
            )}
          </>
        )}
      </Dialog>
    </>
  );
};
