import FileCopy from '@material-ui/icons/FileCopy';
import React, {
  KeyboardEventHandler,
  PropsWithChildren,
  useCallback,
} from 'react';
import {
  ArrayField,
  AutocompleteArrayInput,
  Button,
  ChipField,
  Confirm,
  Datagrid,
  EditButton,
  Filter,
  List,
  ReferenceArrayInput,
  SingleFieldList,
  TextField,
  TextInput,
  useListContext,
  useNotify,
  useRefresh,
  useUnselectAll,
} from 'react-admin';
import styled from 'styled-components';
import { useBoolean } from 'usehooks-ts';
import { fetchApi, prepareSubmitRequestInit } from '~technical/api';
import { FilterField } from '~technical/filters/filter';
import { LootTableField } from '~technical/filters/lootTable';
import { PrimitiveField } from '~ui/PrimitiveField';

import { SPORT_CONTEXT } from '../../../constant';

const BulkButtons = styled.div`
  & > button {
    margin: 0 10px;
  }
`;

const CloneLootTableButton = () => {
  const {
    value: mustConfirm,
    setTrue: setMustConfirmTrue,
    setFalse: setMustConfirmFalse,
  } = useBoolean(false);

  const { resource, selectedIds } = useListContext();

  const refresh = useRefresh();
  const notify = useNotify();
  const unselectAll = useUnselectAll();

  const handleClone = useCallback(async () => {
    try {
      await fetchApi(
        `/backoffice/loottable/clone`,
        prepareSubmitRequestInit({
          lootTables: selectedIds,
        })
      );

      unselectAll(resource);
      refresh();
      notify('Loot table(s) cloned', 'info');
    } catch (e) {
      notify(`Loot table(s) not cloned (${(e as Error).message})`, 'warning');
    }
  }, [selectedIds, unselectAll, resource, refresh, notify]);

  return (
    <>
      <Button label="Clone" onClick={setMustConfirmTrue}>
        <FileCopy />
      </Button>
      <Confirm
        isOpen={mustConfirm}
        title="Loot table cloning"
        content={`Please confirm ${selectedIds.length} Loot table(s) cloning.`}
        onConfirm={() => {
          setMustConfirmFalse();
          handleClone();
        }}
        onClose={setMustConfirmFalse}
      />
    </>
  );
};

export const LootTableBulkActions = () => (
  <BulkButtons>
    <CloneLootTableButton />
  </BulkButtons>
);

const LootTableFilter = ({ children, ...props }: PropsWithChildren<{}>) => {
  const handleKeyPress: KeyboardEventHandler = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  };

  return (
    /* TODO: fix types */
    /* @ts-ignore */
    <Filter {...props} onKeyPress={handleKeyPress}>
      <TextInput source="title" label="Title (internal)" />
      <ReferenceArrayInput label="Tags" source="tags" reference="lootTableTag">
        <AutocompleteArrayInput optionText="name" optionValue="name" />
      </ReferenceArrayInput>
      <ReferenceArrayInput
        label="Filters"
        source={LootTableField.NFT_CARD_FILTER}
        reference="Filter"
        filterToQuery={(search: string) => ({
          reference: search,
          [FilterField.SPORT]: SPORT_CONTEXT,
        })}
      >
        <AutocompleteArrayInput optionText="reference" optionValue="id" />
      </ReferenceArrayInput>
      {children}
    </Filter>
  );
};

export const LootTableList = (props: {}) => (
  <List
    filters={<LootTableFilter />}
    sort={{ field: '_id', order: 'DESC' }}
    exporter={false}
    bulkActionButtons={<LootTableBulkActions />}
    {...props}
  >
    <Datagrid>
      <TextField source="title" />
      <ArrayField source="tags">
        <SingleFieldList linkType={false}>
          <PrimitiveField component={ChipField} />
        </SingleFieldList>
      </ArrayField>
      <TextField sortable={false} source="loots.length" label="Loots" />
      <EditButton />
    </Datagrid>
  </List>
);
