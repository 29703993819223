import { Typography } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';
import { Blockchain } from 'ultimate-league-common';
import { ISavedRaritySupply } from '~business/common/assetTypes';
import { StorageField } from '~business/storage/StorageField';

const RarityWrapper = styled.div`
  margin-bottom: 1.5em;
  padding: 0.7em 1em;
  border: 1px solid grey;
`;
const CardsWrapper = styled.div`
  img {
    margin-right: 1em;
  }
`;
const PositionLabel = styled.h4`
  margin-bottom: 0.5em;
`;

interface IProps {
  rarities: ISavedRaritySupply[];
}

const ShowRarityAssets = ({ rarities }: IProps) => (
  <Typography variant="body1">
    {Array.isArray(rarities) !== true || rarities.length === 0
      ? 'No rarities'
      : rarities.map((rarity) => (
          <RarityWrapper>
            <div>
              Level: <b>{Blockchain.NFTCard.RarityLevel[rarity.level]}</b>
            </div>
            <div>
              Supply: <b>{rarity.supply}</b>
            </div>
            <div>
              {rarity.assetsPerPosition.map(({ position, cardAssets }) => (
                <CardsWrapper>
                  <PositionLabel>{position} card assets</PositionLabel>
                  <div>
                    {Array.isArray(cardAssets) !== true ||
                    cardAssets.length === 0 ? (
                      <Typography variant="body1">
                        <i>No assets uploaded</i>
                      </Typography>
                    ) : (
                      cardAssets.map(({ file }) => (
                        <StorageField record={file} />
                      ))
                    )}
                  </div>
                </CardsWrapper>
              ))}
            </div>
          </RarityWrapper>
        ))}
  </Typography>
);

export default ShowRarityAssets;
