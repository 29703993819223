import { Box, Button, Dialog, MenuItem, Select } from '@material-ui/core';
import ImageIcon from '@material-ui/icons/Image';
import DownloadIcon from '@material-ui/icons/SaveAlt';
import Handlebars from 'handlebars';
import * as htmlToImage from 'html-to-image';
import React, { BaseSyntheticEvent, useEffect, useRef, useState } from 'react';
import { linkToRecord, usePermissions } from 'react-admin';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Blockchain, t, User } from 'ultimate-league-common';
import { useBoolean } from 'usehooks-ts';
import { IAthlete } from '~business/athlete/types';
import { ISavedRaritySupply } from '~business/common/assetTypes';
import { ISavedNftBatch, ISavedNftSeason } from '~business/nft-batch/types';

import { getAssetsWithUrl, getAssetUrl } from './asset-helpers';
import { getFontsWithDataUrl } from './font-helpers';
import { prepareIngredients } from './preview-helpers';
import { InputWrapper, StyledInputLabel } from './styles';

/* eslint-disable  no-underscore-dangle */
const getAthleteById = (id: string, athletes: IAthlete[]) =>
  athletes.find((a) => a._id === id);

const Container = styled.div`
  position: relative;
  display: grid;
`;

const PreviewContainer = styled.div`
  pointer-events: none;
  margin-top: 1em;
`;

const Preview = styled.iframe`
  border: none;
  width: 616px;
  height: 998px;
`;

const PreviewImage = styled.div``;

const StyledBox = styled(Box)`
  white-space: pre;
`;

const CARD_WIDTH = 600;
const CARD_HEIGHT = 960;
const MENU_AND_LINKS_HEIGHT = 110;

interface IScalableContentProps {
  scale: number;
}

const ScalableContent = styled.div<IScalableContentProps>`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  margin: 0.5rem;
  padding: 0.5rem;
  width: ${({ scale }: IScalableContentProps) =>
    `${Math.floor(CARD_WIDTH * scale)}px`};
  height: ${({ scale }: IScalableContentProps) =>
    `${Math.floor(CARD_HEIGHT * scale) + MENU_AND_LINKS_HEIGHT}px`};
  border: 2px solid lightgray;
  overflow: hidden;

  & ${PreviewContainer} {
    transform: ${({ scale }: IScalableContentProps) => `scale(${scale})`};
    transform-origin: top;
    will-change: transform;
  }
`;

const EditLinks = styled.div`
  display: flex;
  justify-content: center;

  & > * {
    margin: 0.3rem 0.15rem 0;
  }
`;

const StyledButton = styled(Button)`
  padding-top: 0;
  position: relative;
  top: -8px;
`;

/* Main Component */

interface IProps {
  index: number;
  batch: ISavedNftBatch;
  season: ISavedNftSeason;
  athletes: IAthlete[];
  compiledTemplate: Handlebars.TemplateDelegate<any>;
  edition: number;
  scale: number;
  raritySelected: ISavedRaritySupply;
  templateValues: string[];
}

export const AthletePreview = ({
  index,
  batch,
  season,
  athletes,
  compiledTemplate,
  edition,
  scale,
  raritySelected,
  templateValues,
}: IProps) => {
  const { permissions } = usePermissions();
  const [html, setHtml] = useState<string>();
  const [error, setError] = useState<string>();
  const [athleteSelectedId, setAthleteSelectedId] = useState<string>(
    athletes[index]._id
  );
  const [athleteSelected, setAthleteSelected] = useState<IAthlete>();

  const {
    value: previewImage,
    setTrue: openImagePreview,
    setFalse: closeImagePreview,
  } = useBoolean(false);

  const previewRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const athlete = getAthleteById(athleteSelectedId, athletes);
    setAthleteSelected(athlete);
  }, [athleteSelectedId, athletes]);

  useEffect(() => {
    (async () => {
      if (
        !compiledTemplate ||
        !edition ||
        !batch ||
        !season ||
        !athleteSelected ||
        !raritySelected
      ) {
        setHtml(undefined);
      } else {
        try {
          // 1 - Prepare ingredients in a generic manner, like `server_tools` receive them.
          const { ingredients, assetIds } = await prepareIngredients(
            edition,
            athleteSelected,
            season,
            raritySelected,
            templateValues
          );

          // 2 - Assets must be parsed to be displayable
          // NB: Assets url are valid for a short time only, so tools and card preview
          // have to generate those urls before applying the template
          const [athleteAssetUrl, clubAssetUrl, competitionAssetUrl] =
            await Promise.all(
              [
                assetIds.athleteAssetId,
                assetIds.clubLogoAssetId,
                assetIds.competitionAssetId,
              ].map(getAssetUrl)
            );

          if (!athleteAssetUrl) {
            throw new Error('Missing athlete card asset to build NFT image');
          }
          if (!clubAssetUrl) {
            throw new Error('Missing club logo to build NFT image');
          }
          if (!competitionAssetUrl) {
            throw new Error('Missing competition asset to build NFT image');
          }

          const ingredientsWithAssetsUrl = {
            ...ingredients,
            athleteAsset: athleteAssetUrl,
            clubAsset: clubAssetUrl,
            competitionAsset: competitionAssetUrl,
            assets: (await getAssetsWithUrl(assetIds.assets)).map(
              ({ url }) => url
            ),
            fonts: await getFontsWithDataUrl(season.cardFonts),
          };

          // 3 - Apply template
          setHtml(compiledTemplate(ingredientsWithAssetsUrl));

          setError(undefined);
        } catch (e) {
          setHtml(undefined);
          setError((e as Error).message);
        }
      }
    })();
  }, [
    compiledTemplate,
    batch,
    season,
    edition,
    raritySelected,
    athleteSelected,
    templateValues,
  ]);

  const onAthleteChange = (event: BaseSyntheticEvent) => {
    const newAthleteId = event.target.value;
    setAthleteSelectedId(newAthleteId);
  };

  const downloadImage = async (athleteName: string, rarityLabel: string) => {
    if (!previewRef.current) {
      return;
    }

    const dataUrl = await htmlToImage.toPng(previewRef.current);
    const link = document.createElement('a');
    link.download = `${athleteName}${
      rarityLabel ? ` - ${rarityLabel}` : ''
    }.png`;
    link.href = dataUrl;
    link.click();
  };

  return (
    <Container>
      <ScalableContent scale={scale}>
        <InputWrapper>
          <StyledInputLabel id="select-athlete">Athlete</StyledInputLabel>
          <Select
            labelId="select-athlete"
            value={athleteSelectedId}
            label="Athlete"
            onChange={onAthleteChange}
          >
            {athletes.map((athlete) => (
              <MenuItem key={athlete._id} value={athlete._id}>
                {athlete.matchName}
              </MenuItem>
            ))}
          </Select>
        </InputWrapper>

        {athleteSelected && (
          <EditLinks>
            <span>Edit:</span>
            <Link
              to={linkToRecord('/athlete', athleteSelected._id, 'edit')}
              target="_blank"
            >
              athlete
            </Link>

            {athleteSelected.club && permissions === User.Role.MODERATOR && (
              <Link
                to={linkToRecord('/team', athleteSelected.club._id, 'edit')}
                target="blank_"
              >
                club
              </Link>
            )}

            {athleteSelected.national && permissions === User.Role.MODERATOR && (
              <Link
                to={linkToRecord('/team', athleteSelected.national._id, 'edit')}
                target="blank_"
              >
                national
              </Link>
            )}
          </EditLinks>
        )}
        {error && <StyledBox margin="1em">{error}</StyledBox>}
        {!error && !html && <Box margin="1em">No preview available</Box>}
        {html && (
          <PreviewContainer>
            <Preview srcDoc={html} />
          </PreviewContainer>
        )}
      </ScalableContent>
      {html && (
        <StyledButton
          color="primary"
          onClick={openImagePreview}
          startIcon={<ImageIcon />}
          size="small"
        >
          Preview
        </StyledButton>
      )}
      {athleteSelected && html && (
        <Dialog
          open={previewImage}
          onClose={closeImagePreview}
          maxWidth={false}
        >
          <Button
            color="primary"
            onClick={() =>
              downloadImage(
                athleteSelected?.matchName ?? 'athlete-card',
                t(Blockchain.NFTCard.rarityI18NKeys[raritySelected.level])
              )
            }
            startIcon={<DownloadIcon />}
            size="medium"
          >
            Download
          </Button>
          <PreviewImage
            ref={previewRef}
            dangerouslySetInnerHTML={{ __html: html }}
          />
        </Dialog>
      )}
    </Container>
  );
};
