import * as React from 'react';
import styled from 'styled-components';

import { CardShowImageWrapper } from '../NFTCardShow';

interface IStyledItemProps {
  nftImageURL: string;
}

const StyledItem = styled.div<IStyledItemProps>`
  background: ${(props) =>
    `center / contain no-repeat url(${props.nftImageURL});`}
  cursor: pointer;

  ${CardShowImageWrapper} & {
    height: 700px;
  }
`;

interface IProps {
  nftImageURL: string;
  onClick?: () => void;
}

export const GeneratedCardItem = ({ nftImageURL, onClick }: IProps) => (
  <StyledItem nftImageURL={nftImageURL} onClick={onClick} />
);
