import { alpha, Box, Button, Tab, Tabs } from '@material-ui/core';
import { makeStyles, styled as muiStyled } from '@material-ui/core/styles';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import React, { PropsWithChildren, useState } from 'react';
import { ArrayInput, SelectInput } from 'react-admin';
import { FieldArrayRenderProps } from 'react-final-form-arrays';
import { Blockchain, SoccerData } from 'ultimate-league-common';
import { rarityLevelChoice } from '~business/common/assetTypes';
import { SafeNumberInput } from '~ui/SafeNumberInput';

import { SPORT_CONTEXT } from '../../../constant';
import PositionsInputIterator from '../../common/components/PositionsInputIterator';
import { IRarity } from '../types';

/* Styled Components */

const StyledArrayInput = muiStyled(ArrayInput)({
  '': {
    width: 'auto',
  },
});

const useStyles = makeStyles((theme) => ({
  removeRarityButton: {
    color: theme.palette.error.main,
    '&:hover': {
      backgroundColor: alpha(theme.palette.error.main, 0.12),
    },
    margin: '8px 24px',
  },
}));

/* Utility Components */

interface ITabPanelProps {
  index: number;
  value: number;
}

function TabPanel(props: PropsWithChildren<ITabPanelProps>) {
  const { children, value, index, ...other } = props;

  return (
    <div hidden={value !== index} {...other}>
      {children}
    </div>
  );
}

const RarityInputIterator = ({
  fields,
}: PropsWithChildren<FieldArrayRenderProps<IRarity, HTMLDivElement>>) => {
  const classes = useStyles();
  const [selectedTab, setTab] = React.useState(0);
  const [confirmRemove, setConfirmRemove] = useState(false);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTab(newValue);
    setConfirmRemove(false);
  };

  const handleAdd = () =>
    fields.push({
      assetsPerPosition: SoccerData.Athlete.MAP_SPORT_TO_POSITIONS[
        SPORT_CONTEXT
      ].map((position) => ({
        position,
        cardAssets: [],
      })),
    });

  const handleRemove = (index: number) => () => {
    if (confirmRemove) {
      fields.remove(index);
      setTab(index === 0 ? 0 : index - 1);
      setConfirmRemove(false);
    } else {
      setConfirmRemove(true);
    }
  };

  return (
    <>
      <Tabs
        value={selectedTab}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons="on"
      >
        {fields.value.map(({ level }) => (
          <Tab
            key={String(level)}
            label={
              typeof level === 'undefined'
                ? '?'
                : Blockchain.NFTCard.RarityLevel[level]
            }
          />
        ))}
        <Tab
          label="Add rarity"
          onClick={handleAdd}
          disabled={
            fields.value.some(({ level }) => typeof level === 'undefined') ||
            rarityLevelChoice.length === fields.value.length
          }
        />
      </Tabs>
      {fields.map((value, index) => (
        <TabPanel key={value} value={selectedTab} index={index}>
          <Button
            startIcon={confirmRemove ? undefined : <RemoveCircleOutlineIcon />}
            className={classes.removeRarityButton}
            onClick={handleRemove(index)}
          >
            {confirmRemove ? 'Click again to confirm' : 'Remove rarity'}
          </Button>
          <Box padding="0 24px">
            <Box margin="6px 0">
              <SelectInput
                allowEmpty
                label="Level"
                source={`${value}.level`}
                choices={rarityLevelChoice.filter(
                  ({ id }) =>
                    id === fields.value[index].level ||
                    !fields.value.some(({ level }) => level === id)
                )}
              />
            </Box>
            <Box margin="6px 0">
              <SafeNumberInput label="Supply" source={`${value}.supply`} />
            </Box>
            <StyledArrayInput source={`${value}.assetsPerPosition`} label="">
              {/* @ts-ignore */}
              <PositionsInputIterator />
            </StyledArrayInput>
          </Box>
        </TabPanel>
      ))}
    </>
  );
};

/* Main Component */

export const RaritiesInput = () => (
  <ArrayInput source="rarities" label="">
    {
      // @ts-ignore
      <RarityInputIterator />
    }
  </ArrayInput>
);
