import React, { useState } from 'react';
import {
  Create,
  CreateProps,
  useNotify,
  usePermissions,
  Record,
  useRedirect,
  SimpleForm,
  TextInput,
  BooleanInput,
  FileInput,
  FileField,
} from 'react-admin';
import { User } from 'ultimate-league-common';
import { PermissionedToolbar } from '~business/common/components/PermissionedToolbar';
import { dataProvider } from '~technical/dataProvider';

import { UnmatchedEmails } from './UnmatchedEmails';
import { validateForm } from './validateForm';

function parseUserList(file?: File) {
  if (!file) {
    return undefined;
  }
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => {
      const emails = (reader.result ?? '')
        .toString()
        .split(/[\n\r;,]/g)
        .filter((s) => s);
      resolve(emails);
    });
    reader.addEventListener('error', (err) => {
      reject(err);
    });
    reader.readAsText(file);
  });
}

export function UserCohortCreate(props: CreateProps) {
  const [unmatchedEmails, setUnmatchedEmails] = useState<string[]>([]);
  const { permissions } = usePermissions();
  const hasPermission = permissions === User.Role.MODERATOR;
  const notify = useNotify();
  const redirect = useRedirect();

  const handleSave = async (data: Partial<Record>) => {
    const { userList, ...values } = data as {
      userList?: { rawFile: File };
      name: string;
      publicInvite: boolean;
    };
    const userEmails = await parseUserList(userList?.rawFile);

    try {
      const {
        data: { notFoundEmails },
      } = (await dataProvider.create('usercohort', {
        data: {
          ...values,
          userEmails,
        },
      })) as unknown as { data: { notFoundEmails: string[] | undefined } };

      setUnmatchedEmails(notFoundEmails ?? []);
      if (!notFoundEmails || notFoundEmails.length === 0) {
        notify('Cohort successfully created', 'info');
        redirect('list', '/usercohort');
      }
    } catch (e) {
      notify(
        `Error: Failed to create cohort ${values.name} (${e.message})`,
        'warning'
      );
    }
  };

  return (
    <>
      <UnmatchedEmails emails={unmatchedEmails} />
      <Create {...props}>
        <SimpleForm
          toolbar={
            <PermissionedToolbar
              canSave={hasPermission}
              canDelete={hasPermission}
            />
          }
          validate={validateForm}
          save={handleSave}
        >
          <TextInput source="name" />
          <BooleanInput source="publicInvite" />
          <FileInput
            source="userList"
            accept="text/csv"
            label="List of user emails (csv, optional)"
          >
            <FileField source="src" title="title" />
          </FileInput>
        </SimpleForm>
      </Create>
    </>
  );
}
