import SyncIcon from '@material-ui/icons/Sync';
import React, { useCallback } from 'react';
import { Button, useNotify, useRecordContext, useRefresh } from 'react-admin';
import { fetchApi } from '~technical/api';

export const AssociateMatchesButton = () => {
  const refresh = useRefresh();
  const notify = useNotify();

  const record = useRecordContext();

  const handleSync = useCallback(async () => {
    try {
      const res = await fetchApi(
        `/backoffice/virtual-gameweek/${record.id}/associate-matches`,
        { method: 'POST' }
      );
      const { associatedMatches } = await res.json();
      refresh();
      notify(
        `${associatedMatches} matches associated to ${record.title} gameweek.`,
        {
          type: 'info',
        }
      );
    } catch (e) {
      notify(`Failed to associate matches to ${record.title} gameweek.`, {
        type: 'warning',
      });
    }
  }, [record, refresh, notify]);

  return (
    <>
      <Button label="Sync Matches" onClick={handleSync}>
        <SyncIcon />
      </Button>
    </>
  );
};
