import Jimp from 'jimp';
import { Storage } from 'ultimate-league-common';
import { IMultiAsset } from '~business/athlete/types';
import { uploadToStorage } from '~business/storage/service';

const convertImage = (file: File) =>
  new Promise<Buffer>((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = async () => {
      const img = await Jimp.read(reader.result as any);
      resolve(
        img.quality(70).resize(32, Jimp.AUTO).getBufferAsync(Jimp.MIME_PNG)
      );
    };
    reader.onerror = reject;
    reader.readAsArrayBuffer(file);
  });

interface IAthleteMainUploadParams {
  type: Storage.StorageType.PUBLIC_ATHLETE_MAIN;
  athleteId: string;
}

interface ITeamLogoUploadParams {
  type: Storage.StorageType.PUBLIC_TEAM_LOGO;
  teamId: string;
}

/**
 * This function handles the upload of assets, specifically focusing on the 'medium' asset type.
 * The 'medium' asset represents the version of the asset that is displayed and modified in the Back Office.
 * The function's operation is contingent on the state of the 'medium' asset's value, which determines whether updates to other asset values are necessary.
 *
 * If 'typeof medium' is null, it signifies the absence of any assets.
 * If 'typeof medium' is a string, it indicates that the 'medium' is an existing storage ID, and remains unaltered (the input was not modified in the Back Office).
 * If 'typeof medium' is a File, it means a new file has been provided. In this case, the function proceeds to upload the appropriate files for 'medium', as well as for 'small' and 'mint' asset types.
 */
async function uploadAsset(
  asset: IMultiAsset,
  uploadParams: IAthleteMainUploadParams | ITeamLogoUploadParams
) {
  if (!asset.medium) {
    return {
      mint: null,
      small: null,
      medium: null,
    };
  }

  if (typeof asset.medium === 'string') {
    return asset;
  }

  const bufferConvertedFile = await convertImage(asset.medium.rawFile);

  return {
    mint: await uploadToStorage(
      {
        type: Storage.StorageType.TOOL,
      },
      asset.medium.rawFile
    ),
    small: await uploadToStorage(
      {
        ...uploadParams,
        format: Storage.AssetFormat.SMALL,
      },
      bufferConvertedFile
    ),
    medium: await uploadToStorage(
      {
        ...uploadParams,
        format: Storage.AssetFormat.MEDIUM,
      },
      asset.medium.rawFile
    ),
  };
}

export const uploadTeamAsset = (asset: IMultiAsset, teamId: string) =>
  uploadAsset(asset, {
    type: Storage.StorageType.PUBLIC_TEAM_LOGO,
    teamId,
  });

export const uploadAthleteAsset = (asset: IMultiAsset, athleteId: string) =>
  uploadAsset(asset, {
    type: Storage.StorageType.PUBLIC_ATHLETE_MAIN,
    athleteId,
  });
