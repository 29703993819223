import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { initValidators } from 'ultimate-league-common';

import './base.css';
import { App } from './business';

initValidators(true);

ReactDOM.render(<App />, document.getElementById('root'));
