import React from 'react';
import { DeleteButton, SaveButton, Toolbar } from 'react-admin';
import { User } from 'ultimate-league-common';

export const PermissionedToolbar = ({
  canSave = false,
  canDelete = false,
  ...props
}: { permissions: User.Role } & any) => (
  <Toolbar {...props}>
    <SaveButton disabled={!canSave || props.pristine} />
    <DeleteButton disabled={!canDelete} />
  </Toolbar>
);
