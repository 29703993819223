import { DeleteForever } from '@material-ui/icons';
import React, { useCallback } from 'react';
import { Button, Confirm, useNotify } from 'react-admin';
import styled from 'styled-components';
import { useBoolean } from 'usehooks-ts';
import { fetchApi, prepareSubmitRequestInit } from '~technical/api';

const RedButton = styled(Button)`
  color: red;
  border: 1px solid red;

  &:hover {
    border: 1px solid red;
  }
`;

export const DeleteAccountButton = ({
  userId,
  userName,
  disabled,
}: {
  userId?: string;
  userName?: string;
  disabled?: boolean;
}) => {
  const notify = useNotify();

  const {
    value: mustConfirm,
    setTrue: setMustConfirmTrue,
    setFalse: setMustConfirmFalse,
  } = useBoolean(false);

  const handleDeleteAccount = useCallback(async () => {
    setMustConfirmFalse();

    try {
      await fetchApi(
        `/backoffice/user/delete-account`,
        prepareSubmitRequestInit(
          {
            userId,
          },
          'PATCH'
        )
      );

      notify('Account has been deleted', 'info');
    } catch (e: any) {
      notify(`Account has not been deleted (${e.message})`, 'error');
    }
  }, [userId, notify, setMustConfirmFalse]);

  if (disabled) {
    return null;
  }

  return (
    <>
      <RedButton
        color="primary"
        variant="outlined"
        label="Delete Account"
        onClick={setMustConfirmTrue}
        startIcon={<DeleteForever />}
      />

      <Confirm
        isOpen={mustConfirm}
        title="Delete Account"
        content={`This action cannot be undone. Are you sure you want to delete the account for ${userName}? Please confirm accounts deleting.`}
        onConfirm={handleDeleteAccount}
        onClose={setMustConfirmFalse}
      />
    </>
  );
};
