import {
  Authorization as AuthorizationCommon,
  User,
} from 'ultimate-league-common';

import { Authorization } from './authorization';

const auth = Authorization.getInstance();

interface ILoginParams {
  credentials: AuthorizationCommon.IAuthorization;
  user: User.IUserAccount;
}

async function login(loginParams: ILoginParams) {
  auth.setState(loginParams.credentials, loginParams.user);
}

async function logout() {
  auth.destroy();
}

function checkAuth() {
  return auth.getCredentials() ? Promise.resolve() : Promise.reject();
}

function checkError() {
  return Promise.resolve();
}

function getPermissions() {
  return Promise.resolve(auth.getUser()?.role);
}

export const authProvider = {
  login,
  logout,
  checkAuth,
  checkError,
  getPermissions,
};
