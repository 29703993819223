import { makeStyles } from '@material-ui/core';
import { AddCircleOutline } from '@material-ui/icons';
import React from 'react';
import { ArrayInput, Button, SimpleFormIterator } from 'react-admin';
import { useField, useForm } from 'react-final-form';
import styled from 'styled-components';
import { GameView } from 'ultimate-league-common';

import { PrizePoolInput } from './PrizePoolInput';
import { DivisionIterator } from './tournaments/DivisionIterator';

interface IInputProps {
  source: string;
}

const GroupArea = styled.div`
  margin-bottom: 1rem;
  padding: 0.6rem 1rem;
  border: 1px solid #d9d9d9;
`;
const StyledSimpleFormIterator = styled(SimpleFormIterator)`
  margin-top: 0;
`;

const useStyles = makeStyles(() => ({
  addButton: {
    '&:hover': {
      background: 'none',
      color: 'darkblue',
    },
  },
}));

const LeaguesDivisionInput = ({ source }: IInputProps) => {
  const classes = useStyles();
  const { change } = useForm();
  const { input } = useField(source);

  const handleCreate = () =>
    change(`${source}.positionPrizePool`, {
      type: GameView.PrizePoolType.POSITION,
      brackets: [],
    });

  return (
    <>
      <PrizePoolInput source={`${source}.scorePrizePool`} />
      {input.value.positionPrizePool ? (
        <PrizePoolInput source={`${source}.positionPrizePool`} removable />
      ) : (
        <Button
          startIcon={<AddCircleOutline />}
          label="Create position prize pool"
          onClick={handleCreate}
          className={classes.addButton}
        />
      )}
    </>
  );
};

export const DivisionsInput = ({ source }: IInputProps) => {
  const isLeagues = source.includes('leagues');

  const Container = isLeagues ? React.Fragment : GroupArea;

  return (
    <Container>
      <ArrayInput source={source} label="">
        {isLeagues ? (
          <StyledSimpleFormIterator
            disableReordering
            disableAdd
            disableRemove
            getItemLabel={() => ''}
          >
            {
              // @ts-ignore
              <LeaguesDivisionInput />
            }
          </StyledSimpleFormIterator>
        ) : (
          // @ts-ignore
          <DivisionIterator />
        )}
      </ArrayInput>
    </Container>
  );
};
