import React from 'react';
import {
  ArrayInput,
  AutocompleteInput,
  BooleanInput,
  Edit,
  EditProps,
  FormTab,
  ReferenceInput,
  required,
  SelectArrayInput,
  SimpleFormIterator,
  TabbedForm,
  TextInput,
} from 'react-admin';
import { ColorInput } from 'react-admin-color-input';
import { Storage } from 'ultimate-league-common';
import { ITeam } from '~business/athlete/types';
import { rarityLevelChoice } from '~business/common/assetTypes';
import { uploadTeamAsset } from '~business/common/uploadAsset';
import { StorageImageInput } from '~business/storage/StorageImageInput';
import { uploadToStorage } from '~business/storage/service';
import { CountryField } from '~technical/filters/country';

interface IValidationErrors {
  code?: string;
  shortName?: string;
}

function validate(team: ITeam) {
  const errors: IValidationErrors = {};
  if (!team.code) {
    errors.code = 'field is required';
  }
  if (!team.shortName) {
    errors.shortName = 'field is required';
  }
  return errors;
}

interface ITeamFormProps {
  id: string;
  assets?: ITeam['assets'];
  nftTemplateOverride?: ITeam['nftTemplateOverride'];
}

const TeamForm = (props: any) => {
  const handleSave = async (
    values: ITeamFormProps,
    redirect: string,
    ...params: any[]
  ) => {
    const { nftTemplateOverride, assets, id } = values;
    let uploadedLogo = assets?.logo || props.assets;

    if (assets?.logo?.medium) {
      uploadedLogo = await uploadTeamAsset(assets.logo, id);
    }

    const prs = nftTemplateOverride?.clubAsset?.map(
      async ({ rarities, file }: any) => {
        if (typeof file === 'string') {
          return {
            file,
            rarities,
          };
        }

        if (!file?.rawFile)
          return {
            file: null,
            rarities,
          };

        return {
          file: await uploadToStorage(
            {
              type: Storage.StorageType.TOOL,
            },
            file.rawFile
          ),
          rarities,
        };
      }
    );

    let overrides;
    if (prs) {
      overrides = await Promise.all(prs);
    }

    props.save(
      {
        ...values,
        assets: {
          logo: uploadedLogo,
        },
        nftTemplateOverride: {
          ...nftTemplateOverride,
          clubAsset: overrides,
        },
      },
      redirect,
      ...params
    );
  };

  return (
    <TabbedForm {...props} save={handleSave} validate={validate}>
      <FormTab label="Team">
        <TextInput source="name" disabled />
        <TextInput source="type" disabled />
        <TextInput source="shortName" />
        <TextInput source="shortNameOriginal" disabled />
        <TextInput source="code" />
        <TextInput source="codeOriginal" disabled />
        <BooleanInput source="licensed" />
        <ColorInput source="shirtColor" />
        <ReferenceInput
          source="country"
          reference="country"
          filterToQuery={(search: string) => ({
            [CountryField.JOKER]: search,
          })}
          allowEmpty
        >
          <AutocompleteInput optionText="name" optionValue="id" />
        </ReferenceInput>
        <StorageImageInput source="assets.logo.medium" label="Logo (300x300)" />
      </FormTab>
      <FormTab label="NFT Template overrides">
        <ArrayInput source="nftTemplateOverride.clubAsset">
          <SimpleFormIterator disableReordering>
            <SelectArrayInput
              label="rarities"
              source="rarities"
              validate={required()}
              choices={rarityLevelChoice}
            />
            <StorageImageInput
              validate={required()}
              source="file"
              label="Logo (300x300)"
            />
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>
    </TabbedForm>
  );
};

export const TeamEdit = (props: EditProps) => (
  <Edit {...props}>
    {
      // @ts-ignore
      <TeamForm />
    }
  </Edit>
);
