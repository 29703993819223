import React from 'react';
import {
  ArrayField,
  BooleanField,
  Datagrid,
  NumberField,
  Show,
  SimpleShowLayout,
  TextField,
} from 'react-admin';

const TeamCount = () => (
  <Datagrid>
    <NumberField source="count" />
    <TextField source="position" />
    <BooleanField source="required" />
  </Datagrid>
);

export const FormationShow = (props: any) => (
  <Show {...props} hasEdit={false}>
    <SimpleShowLayout>
      <TextField source="title" />
      <BooleanField source="default" label="Default formation" />

      <ArrayField source="team">
        <TeamCount />
      </ArrayField>

      <ArrayField source="substitutes">
        <TeamCount />
      </ArrayField>
    </SimpleShowLayout>
  </Show>
);
