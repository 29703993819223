import { processGenericFilter, Value } from './generic';

export enum TranslationField {
  KEY = 'key',
}

export function processTranslationFilter(
  field: TranslationField | string,
  value: Value
) {
  switch (field) {
    case TranslationField.KEY:
      return { key: { $regex: value, $options: 'i' } };
    default:
      return processGenericFilter(field, value);
  }
}
