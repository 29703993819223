import { SoccerData, Storage } from 'ultimate-league-common';
import { IRarity } from '~business/season/types';
import { uploadToStorage } from '~business/storage/service';

import { SPORT_CONTEXT } from '../../constant';
import {
  IInputCardAsset,
  IInputRaritySupply,
  ISavedCardAsset,
  ISavedRaritySupply,
} from './assetTypes';

const processCardAssets = async (cardAssets: IInputCardAsset[]) => {
  const assets = await Promise.all(
    cardAssets.map(async (asset): Promise<ISavedCardAsset | null> => {
      if (typeof asset?.file === 'string') {
        return asset as ISavedCardAsset;
      }
      if (asset?.file?.rawFile) {
        return {
          mediaType: asset.file.rawFile.type,
          file: await uploadToStorage(
            {
              type: Storage.StorageType.TOOL,
            },
            asset.file.rawFile
          ),
        };
      }
      return null;
    })
  );
  return assets.filter((asset) => asset) as ISavedCardAsset[];
};

export const processRarities = async (rarities: IRarity[]) => {
  const promises = rarities.map(
    async ({ assetsPerPosition, ...restRarity }) => {
      if (typeof restRarity.supply !== 'number') {
        return null;
      }
      return {
        ...restRarity,
        assetsPerPosition: await Promise.all(
          assetsPerPosition.map(
            async ({ cardAssets, ...restAssetsPerPosition }) => ({
              ...restAssetsPerPosition,
              cardAssets: cardAssets ? await processCardAssets(cardAssets) : [],
            })
          )
        ),
      };
    }
  );

  const result = await Promise.all(promises);
  return result.filter((r) => r) as ISavedRaritySupply[];
};

export function validateRaritySupply(
  rarity: IInputRaritySupply,
  isSeason: boolean
) {
  const rarityErrors: {
    level?: string;
    supply?: string;
  } = {};
  if (!rarity) {
    return rarityErrors;
  }

  if (typeof rarity.level !== 'number') {
    rarityErrors.level = 'Level is mandatory';
  }
  if (typeof rarity.supply !== 'number') {
    if (isSeason) {
      rarityErrors.supply = 'Supply is mandatory';
    }
  } else if (rarity.supply < 0) {
    rarityErrors.supply = "Supply can't be a negative number";
  } else if (rarity.supply > 0) {
    const missingAssetsForPosition = SoccerData.Athlete.MAP_SPORT_TO_POSITIONS[
      SPORT_CONTEXT
    ].filter((iPosition) => {
      if (Array.isArray(rarity.assetsPerPosition) !== true) {
        return true;
      }
      const match = rarity.assetsPerPosition.find(
        ({ position }) => position === iPosition
      );
      if (!match) {
        return true;
      }
      return (
        Array.isArray(match.cardAssets) !== true ||
        match.cardAssets.filter((a) => a && a.file).length === 0
      );
    });
    if (missingAssetsForPosition.length) {
      rarityErrors.supply = `Missing assets for position(s): ${missingAssetsForPosition.join(
        ', '
      )}`;
    }
  }

  return rarityErrors;
}
