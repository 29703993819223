import { processGenericFilter, Value } from './generic';

export enum CronField {
  JOB = 'job',
  CREATION_DATE = 'createdAt',
}

export function processCronFilter(field: CronField | string, value: Value) {
  switch (field) {
    case CronField.JOB:
      return {
        job: { $regex: value, $options: 'i' },
      };

    default:
      return processGenericFilter(field, value);
  }
}
