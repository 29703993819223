import React from 'react';
import { AutocompleteInput, ReferenceInput, required } from 'react-admin';
import { SafeNumberInput } from '~ui/SafeNumberInput';

import { DivisionsInput } from './DivisionInput';
import { FormationsInput } from './FormationsInput';
import { StackingPenaltiesInput } from './StackingPenaltiesInput';

export const GameModeInput = ({ source }: { source: string }) => {
  const isLeagues = source.includes('leagues');

  return (
    <>
      <FormationsInput source={`${source}.formations`} />
      <StackingPenaltiesInput
        source={`${source}.stackingPenaltiesConfig`}
        required={[required()]}
      />
      <SafeNumberInput
        style={{ maxWidth: 300 }}
        source={`${source}.pointCap`}
        label="Point Cap"
        min={0}
      />
      <ReferenceInput
        label="Requirement"
        source={`${source}.requirement`}
        reference="Filter"
        filterToQuery={(search: string) => ({
          reference: search,
        })}
        validate={isLeagues ? [] : [required()]}
      >
        <AutocompleteInput optionText="reference" optionValue="id" />
      </ReferenceInput>
      <DivisionsInput source={`${source}.divisions`} />
    </>
  );
};
