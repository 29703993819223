import { Box, CircularProgress } from '@material-ui/core';
import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import {
  BooleanInput,
  Create,
  DateTimeInput,
  FormTab,
  Record,
  SimpleForm,
  TabbedForm,
  TextInput,
  useListController,
} from 'react-admin';
import { GameView, GameWeek } from 'ultimate-league-common';
import { buildQuery, fetchApi } from '~technical/api';
import { validateDate } from '~technical/form';
import { SafeNumberInput } from '~ui/SafeNumberInput';

import { SPORT_CONTEXT } from '../../constant';
import { GameModes } from './game-modes';
import { useDefaultFormation } from './hooks/useDefaultFormation';
import { Virtual } from './virtual';

const SimpleGameWeekCreateForm = (props: any) => (
  <SimpleForm {...props}>
    <SafeNumberInput source="position" />
    <TextInput source="title" />
    <DateTimeInput
      source="startDate"
      inputProps={{
        step: 3600,
      }}
    />
    <DateTimeInput
      source="endDate"
      inputProps={{
        step: 3600,
      }}
    />
    <Virtual />
  </SimpleForm>
);

const getInitialValues = (
  lastPosition: number,
  defaultFormation?: GameView.IFormation
) => ({
  position: lastPosition + 1,
  title: `GameWeek ${lastPosition + 1}`,
  gameModes: {
    leagues: {
      formations: defaultFormation ? [defaultFormation.id] : [],
      divisions: [
        {
          scorePrizePool: {
            type: GameView.PrizePoolType.SCORE,
            brackets: [{ type: GameView.BracketType.ABSOLUTE, from: 0, to: 0 }],
          },
        },
      ],
      allowedTeamCount: 1,
    },
  },
  isVirtual: false,
  sport: SPORT_CONTEXT,
  virtualMatches: [],
});

export const GameWeekCreate = (props: any) => {
  const listContext = useListController(props);
  const [lastGameWeek, setLastGameWeek] = useState<GameWeek.IGameWeek>();
  const [fallback, setFallback] = useState(false);
  const [isVirtual, setIsVirtual] = useState(false);
  const { defaultFormation } = useDefaultFormation();

  const handleChangeIsVirtual = () => setIsVirtual(!isVirtual);

  const initialValues = useMemo(
    () =>
      typeof lastGameWeek?.position === 'number'
        ? getInitialValues(lastGameWeek?.position, defaultFormation)
        : {},
    [lastGameWeek, defaultFormation]
  );

  useEffect(() => {
    if (listContext.loading) {
      return;
    }

    // Fetch last gameweek
    fetchApi(
      `/backoffice/gameweek?${buildQuery({
        skip: 0,
        limit: 1,
        sort: '-position',
        query: JSON.stringify({ sport: SPORT_CONTEXT }),
      })}`
    )
      .then((res) => res.json())
      .then(([gameweek]) => {
        if (!gameweek) {
          throw new Error('No gameweek exists yet');
        }
        setLastGameWeek(gameweek);
      })
      .catch(() => {
        setLastGameWeek(undefined);
        setFallback(true);
      });
  }, [listContext.total, listContext.loading, setLastGameWeek, setFallback]);

  if (fallback) {
    return (
      <Create {...props}>
        <SimpleGameWeekCreateForm />
      </Create>
    );
  }

  if (listContext.loading || !lastGameWeek) {
    return (
      <Box margin="auto">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Create {...props}>
      <TabbedForm
        {...props}
        record={lastGameWeek as unknown as Record}
        initialValues={initialValues}
      >
        <FormTab label="general">
          <SafeNumberInput source="position" disabled />
          <TextInput label="Title (internal)" source="title" />
          <DateTimeInput
            source="startDate"
            inputProps={{
              min: lastGameWeek.endDate.slice(0, 16),
              step: 3600,
            }}
            validate={[validateDate]}
          />
          <DateTimeInput
            source="endDate"
            inputProps={{
              min: lastGameWeek.endDate.slice(0, 16),
              step: 3600,
            }}
            validate={[validateDate]}
          />
          <BooleanInput source="isVirtual" onChange={handleChangeIsVirtual} />
        </FormTab>
        <FormTab label="game modes">
          <GameModes />
        </FormTab>
        {isVirtual && (
          <FormTab label="Virtual">
            <Virtual />
          </FormTab>
        )}
      </TabbedForm>
    </Create>
  );
};
