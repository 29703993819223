import { Typography } from '@material-ui/core';
import { Info } from '@material-ui/icons';
import React from 'react';
import {
  ArrayField,
  ArrayInput,
  AutocompleteInput,
  BooleanInput,
  Datagrid,
  DateField,
  DateInput,
  Edit,
  EditProps,
  FormTab,
  ReferenceInput,
  SelectArrayInput,
  SelectInput,
  SimpleFormIterator,
  TabbedForm,
  TabbedFormProps,
  TextInput,
  maxLength,
  required,
  usePermissions,
} from 'react-admin';
import styled from 'styled-components';
import { Database, SoccerData, Storage, User } from 'ultimate-league-common';
import { athletePositionChoices, tierChoices } from '~business/athlete/common';
import { rarityLevelChoice } from '~business/common/assetTypes';
import { PermissionedToolbar } from '~business/common/components/PermissionedToolbar';
import { uploadAthleteAsset } from '~business/common/uploadAsset';
import { TemplateValuesInput } from '~business/season/components/TemplateValuesInput';
import { StorageImageInput } from '~business/storage/StorageImageInput';
import { uploadToStorage } from '~business/storage/service';
import { CountryField } from '~technical/filters/country';
import { SafeNumberInput } from '~ui/SafeNumberInput';

import { IAthlete } from './types';

const IgnoreWrapper = styled.div`
  min-width: 40vw;
  margin-top: 0.8rem;
  padding: 0.2rem 1rem 0.5rem;
  border: 1px solid orange;
`;

const IgnoredNotice = styled.div`
  display: flex;
  align-items: center;

  & p {
    margin-left: 1rem;
  }
`;

interface IAthleteFormProps {
  id: string;
  assets: IAthlete['assets'];
  club: Database.DocumentId;
  national: Database.DocumentId;
  nftTemplateOverride?: IAthlete['nftTemplateOverride'];
}

const AthleteForm = (props: TabbedFormProps) => {
  const { save, permissions } = props;
  const hasPermission = [User.Role.ASSET_MANAGER, User.Role.MODERATOR].includes(
    permissions
  );

  const handleSave: TabbedFormProps['save'] = async (values, _, options) => {
    const { id, club, national, assets, nftTemplateOverride } =
      values as IAthleteFormProps;

    let redirectTo: string = '';
    if (club || national) {
      redirectTo = `/athlete/?displayedFilters=${[
        '{"team":true}',
        `filter={"team":"${club || national}"}`,
        'order=ASC',
        'page=1',
        'perPage=25',
        'sort=matchName',
      ].join('&')}`;
    }

    let mainAssets = assets?.main || props.assets;
    if (assets?.main?.medium) {
      mainAssets = await uploadAthleteAsset(assets.main, id);
    } else {
      mainAssets = null;
    }

    let athleteAssetOverride;
    if (nftTemplateOverride?.athleteAsset) {
      const prs = nftTemplateOverride?.athleteAsset?.map(
        async ({ file, rarities }) => {
          // if not a rawfile we can assume it hasn't changed
          if (typeof file === 'string') {
            return {
              file,
              rarities,
            };
          }

          // check if asset has been changed and contains a file to upload
          if (!file?.rawFile)
            return {
              file: null,
              rarities,
            };

          const uploadedAsset = await uploadToStorage(
            {
              type: Storage.StorageType.TOOL,
            },
            file.rawFile
          );

          return {
            file: uploadedAsset,
            rarities,
          };
        }
      );

      athleteAssetOverride = prs && (await Promise.all(prs));
    }

    return save?.(
      {
        ...values,
        assets: {
          main: mainAssets,
        },
        nftTemplateOverride: {
          ...nftTemplateOverride,
          athleteAsset: athleteAssetOverride,
        },
      },
      redirectTo,
      options
    );
  };

  return (
    <TabbedForm {...props} save={handleSave}>
      <FormTab label="summary">
        <TextInput source="firstName" disabled={!hasPermission} />
        <TextInput source="shortFirstName" disabled={!hasPermission} />
        <TextInput source="shortFirstNameOriginal" disabled />
        <TextInput source="lastName" disabled={!hasPermission} />
        <TextInput source="shortLastName" disabled={!hasPermission} />
        <TextInput source="shortLastNameOriginal" disabled />
        <TextInput source="matchName" disabled={!hasPermission} />
        <TextInput
          source="matchNameOriginal"
          label="Match name (Original)"
          disabled
        />

        <SelectInput
          source="position"
          choices={athletePositionChoices}
          disabled={!hasPermission}
        />
        <SelectInput
          source="positionOriginal"
          label="Position (Original)"
          choices={athletePositionChoices}
          disabled
        />

        <ReferenceInput
          source="club"
          reference="team"
          filterToQuery={(search: string) => ({
            name: search,
            type: SoccerData.Team.TeamType.CLUB,
          })}
          allowEmpty
          disabled={!hasPermission}
        >
          <AutocompleteInput optionText="name" optionValue="id" />
        </ReferenceInput>
        <ReferenceInput
          source="clubOriginal"
          label="Club team (Original)"
          reference="team"
          filterToQuery={(search: string) => ({
            name: search,
            type: SoccerData.Team.TeamType.CLUB,
          })}
          disabled
        >
          <AutocompleteInput optionText="name" optionValue="id" disabled />
        </ReferenceInput>

        <ReferenceInput
          source="national"
          reference="team"
          filterToQuery={(search: string) => ({
            name: search,
            type: SoccerData.Team.TeamType.NATIONAL,
          })}
          allowEmpty
          disabled={!hasPermission}
        >
          <AutocompleteInput optionText="name" optionValue="id" />
        </ReferenceInput>
        <ReferenceInput
          source="nationalOriginal"
          label="National team (Original)"
          reference="team"
          filterToQuery={(search: string) => ({
            name: search,
            type: SoccerData.Team.TeamType.NATIONAL,
          })}
          disabled
        >
          <AutocompleteInput optionText="name" optionValue="id" disabled />
        </ReferenceInput>

        <ReferenceInput
          source="country"
          reference="country"
          filterToQuery={(search: string) => ({
            [CountryField.JOKER]: search,
          })}
          allowEmpty
          disabled={!hasPermission}
        >
          <AutocompleteInput optionText="name" optionValue="id" />
        </ReferenceInput>
        <ReferenceInput
          source="countryOriginal"
          label="Country (Original)"
          reference="country"
          disabled
        >
          <AutocompleteInput optionText="name" optionValue="id" disabled />
        </ReferenceInput>

        <StorageImageInput source="assets.main.medium" label="Main (512x512)" />

        <ArrayField source="injuries">
          <Datagrid>
            <DateField source="startDate" />
            <DateField source="endDate" />
            <DateField source="expectedEndDate" />
          </Datagrid>
        </ArrayField>

        <ArrayField source="suspensions">
          <Datagrid>
            <DateField source="startDate" />
            <DateField source="endDate" />
          </Datagrid>
        </ArrayField>

        <IgnoreWrapper>
          <BooleanInput source="ignored" />
          <IgnoredNotice>
            <Info color="primary" />
            <Typography variant="body2">
              The cards of an ignored athlete won&apos;t be minted anymore, nor
              distributed in packs and rewards, nor present in FTUE draft
            </Typography>
          </IgnoredNotice>
          <BooleanInput source="ignoredOverride" />
          <IgnoredNotice>
            <Info color="primary" />
            <Typography variant="body2">
              Manually decide whether this athlete should be ignored instead of
              leaving up to the automated system control
            </Typography>
          </IgnoredNotice>
          <TextInput source="note" validate={maxLength(100)} multiline />
        </IgnoreWrapper>
      </FormTab>

      <FormTab label="NFT Template overrides">
        <ArrayInput
          style={{
            padding: 24,
            boxSizing: 'border-box',
            border: '1px solid grey',
          }}
          label="Those assets will be used in the template to overwrite athlete asset."
          source="nftTemplateOverride.athleteAsset"
        >
          <SimpleFormIterator disableReordering>
            <SelectArrayInput
              label="rarities"
              source="rarities"
              validate={required()}
              choices={rarityLevelChoice}
            />
            <StorageImageInput
              validate={required()}
              source="file"
              label="Asset"
              multiple={false}
            />
          </SimpleFormIterator>
        </ArrayInput>
        <ArrayInput
          style={{
            padding: 24,
            boxSizing: 'border-box',
            border: '1px solid grey',
          }}
          label="Those values will be used in the NFT template to overwrite default template values."
          source="nftTemplateOverride.templateValues"
        >
          <SimpleFormIterator disableReordering>
            <SelectArrayInput
              label="rarities"
              source="rarities"
              validate={required()}
              choices={rarityLevelChoice}
            />
            <TemplateValuesInput
              label="values"
              source="values"
              validate={required()}
            />
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>
      {permissions === User.Role.MODERATOR && (
        <FormTab label="refs">
          <TextInput disabled source="idOriginal" />
        </FormTab>
      )}
      {permissions === User.Role.MODERATOR && (
        <FormTab label="data">
          <SafeNumberInput source="shirtNumber" />
          <SelectInput source="tier" choices={tierChoices} resettable />
          <DateInput source="birth" />
          <DateInput source="birthOriginal" disabled />
          <SafeNumberInput source="height" />
          <SafeNumberInput source="weight" />
        </FormTab>
      )}
    </TabbedForm>
  );
};

export const AthleteEdit = (props: EditProps) => {
  const { permissions } = usePermissions();
  return (
    <Edit {...props}>
      {/* @ts-ignore */}
      <AthleteForm
        permissions={permissions}
        toolbar={
          <PermissionedToolbar
            canSave
            canDelete={permissions === User.Role.MODERATOR}
          />
        }
      />
    </Edit>
  );
};
