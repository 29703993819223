import * as React from 'react';
import { useRedirect } from 'react-admin';
import { buildURL } from '~technical/ipfs';

import { IRecordNFT } from '../interfaces';
import { DraftCardItem } from './DraftCardItem';
import { GeneratedCardItem } from './GeneratedCardItem';

interface ICardItemProps {
  card: IRecordNFT;
  redirectOnClick?: boolean;
}

export const CardItem = ({ card, redirectOnClick }: ICardItemProps) => {
  const redirect = useRedirect();
  const onClickHandler = !redirectOnClick
    ? undefined
    : () => {
        redirect('show', '/nftcard', card.id);
      };

  return card.metadata === undefined ? (
    <DraftCardItem key={card.id} card={card} />
  ) : (
    <GeneratedCardItem
      key={card.id}
      nftImageURL={buildURL(card.metadata.image)}
      onClick={onClickHandler}
    />
  );
};
