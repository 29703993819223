import { makeStyles } from '@material-ui/core';
import React from 'react';
import { Layout as BaseLayout } from 'react-admin';
import { AppBar } from '~business/layout/AppBar';

import { Menu } from './Menu';

const useStyles = makeStyles(() => ({
  root: {
    minWidth: 'initial',
    '& #main-content': {
      overflow: 'hidden',
    },
    '& .MuiDrawer-paper > div': {
      position: 'initial',
    },
  },
}));

export const Layout = (props: unknown) => {
  const classes = useStyles();

  return (
    <BaseLayout
      {...props}
      className={classes.root}
      menu={Menu}
      appBar={AppBar}
    />
  );
};
