import { makeStyles, Typography } from '@material-ui/core';
import { RemoveCircleOutline } from '@material-ui/icons';
import React from 'react';
import { ArrayInput, Button } from 'react-admin';
import { useField, useForm } from 'react-final-form';
import styled from 'styled-components';

import { BracketIterator } from './BracketIterator';

const GroupArea = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1rem 1rem 1rem 0;
  padding: 0.6rem 1rem;
  border: 1px solid #d9d9d9;
`;

const useStyles = makeStyles((theme) => ({
  removeButton: {
    color: theme.palette.error.main,
    '&:hover': {
      background: 'none',
      color: 'darkred',
    },
    margin: 'auto',
  },
}));

export const PrizePoolInput = ({
  source,
  removable = false,
}: {
  source: string;
  removable?: boolean;
}) => {
  const { change } = useForm();
  const { input } = useField(source);
  const classes = useStyles();

  const handleRemove = () => change(source, null);

  return (
    <GroupArea>
      {removable && (
        <Button
          startIcon={<RemoveCircleOutline />}
          label="Remove prize pool"
          onClick={handleRemove}
          className={classes.removeButton}
        />
      )}
      <Typography variant="body1">{input.value.type} Prize Pool</Typography>
      <ArrayInput source={`${source}.brackets`} label="">
        {
          // @ts-ignore
          <BracketIterator />
        }
      </ArrayInput>
    </GroupArea>
  );
};
