import { makeStyles } from '@material-ui/core';
import { FlagOutlined } from '@material-ui/icons';
import AccessAlarmIcon from '@material-ui/icons/AccessAlarm';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import CardGiftcardIcon from '@material-ui/icons/CardGiftcard';
import CasinoIcon from '@material-ui/icons/Casino';
import CropFreeIcon from '@material-ui/icons/CropFree';
import CropPortraitIcon from '@material-ui/icons/CropPortrait';
import DateRangeIcon from '@material-ui/icons/DateRange';
import DirectionsRunIcon from '@material-ui/icons/DirectionsRun';
import FilterListIcon from '@material-ui/icons/FilterList';
import FilterNoneIcon from '@material-ui/icons/FilterNone';
import FlagIcon from '@material-ui/icons/Flag';
import GavelIcon from '@material-ui/icons/Gavel';
import GroupIcon from '@material-ui/icons/Group';
import GroupWorkIcon from '@material-ui/icons/GroupWork';
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import QueueIcon from '@material-ui/icons/Queue';
import StarsIcon from '@material-ui/icons/Stars';
import TodayIcon from '@material-ui/icons/Today';
import TranslateIcon from '@material-ui/icons/Translate';
import DefaultIcon from '@material-ui/icons/ViewList';
import WarningIcon from '@material-ui/icons/Warning';
import * as React from 'react';
import { useState } from 'react';
import { MenuItemLink, usePermissions } from 'react-admin';
import { User } from 'ultimate-league-common';

import { SubMenu } from './SubMenu';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(3),
  },
}));

enum SubMenuName {
  DEVELOPMENT = 'Development',
  CARDS = 'Cards',
  PACKS = 'Packs',
}

interface IProps {
  dense?: boolean;
}

export const Menu = ({ dense = false }: IProps) => {
  const classes = useStyles();
  const { permissions } = usePermissions();
  const [state, setState] = useState<Record<SubMenuName, boolean>>({
    [SubMenuName.DEVELOPMENT]: false,
    [SubMenuName.CARDS]: false,
    [SubMenuName.PACKS]: false,
  });

  const handleToggle = (menu: SubMenuName) => () => {
    setState((oState) => ({ ...oState, [menu]: !oState[menu] }));
  };

  switch (permissions) {
    case User.Role.COMMUNITY:
      return (
        <div className={classes.root}>
          <MenuItemLink
            to="/user"
            primaryText="Users"
            leftIcon={<PermIdentityIcon />}
            dense={dense}
          />
        </div>
      );
    case User.Role.ASSET_RETAKER:
      return (
        <div className={classes.root}>
          <SubMenu
            handleToggle={handleToggle(SubMenuName.CARDS)}
            isOpen={state[SubMenuName.CARDS]}
            name={SubMenuName.CARDS}
            icon={<CropPortraitIcon />}
            dense={dense}
          >
            <MenuItemLink
              to="/nftbatch"
              primaryText="Batches"
              leftIcon={<QueueIcon />}
              dense={dense}
            />
          </SubMenu>
          <MenuItemLink
            to="/athlete"
            primaryText="Athletes"
            leftIcon={<DirectionsRunIcon />}
            dense={dense}
          />
        </div>
      );
    case User.Role.ASSET_MANAGER:
      return (
        <div className={classes.root}>
          <SubMenu
            handleToggle={handleToggle(SubMenuName.CARDS)}
            isOpen={state[SubMenuName.CARDS]}
            name={SubMenuName.CARDS}
            icon={<CropPortraitIcon />}
            dense={dense}
          >
            <MenuItemLink
              to="/nftbatch"
              primaryText="Batches"
              leftIcon={<QueueIcon />}
              dense={dense}
            />
          </SubMenu>
          <MenuItemLink
            to="/team"
            primaryText="Teams"
            leftIcon={<GroupIcon />}
            dense={dense}
          />
          <MenuItemLink
            to="/country"
            primaryText="Countries"
            leftIcon={<FlagOutlined />}
            dense={dense}
          />
          <MenuItemLink
            to="/athlete"
            primaryText="Athletes"
            leftIcon={<DirectionsRunIcon />}
            dense={dense}
          />
        </div>
      );
    case User.Role.SUPPORT:
      return (
        <div className={classes.root}>
          <SubMenu
            handleToggle={handleToggle(SubMenuName.PACKS)}
            isOpen={state[SubMenuName.PACKS]}
            name={SubMenuName.PACKS}
            icon={<CardGiftcardIcon />}
            dense={dense}
          >
            <MenuItemLink
              to="/pack"
              primaryText="Packs"
              leftIcon={<FilterNoneIcon />}
              dense={dense}
            />
          </SubMenu>
          <MenuItemLink
            to="/user"
            primaryText="Users"
            leftIcon={<PermIdentityIcon />}
            dense={dense}
          />
          <MenuItemLink
            to="/sale"
            primaryText="Sales"
            leftIcon={<AttachMoneyIcon />}
            dense={dense}
          />
        </div>
      );
    case User.Role.MODERATOR:
      return (
        <div className={classes.root}>
          <MenuItemLink
            to="/competition"
            primaryText="Competitions"
            leftIcon={<FlagIcon />}
            dense={dense}
          />
          <SubMenu
            handleToggle={handleToggle(SubMenuName.CARDS)}
            isOpen={state[SubMenuName.CARDS]}
            name={SubMenuName.CARDS}
            icon={<CropPortraitIcon />}
            dense={dense}
          >
            <MenuItemLink
              to="/nftcardseason"
              primaryText="Seasons"
              leftIcon={<DateRangeIcon />}
              dense={dense}
            />
            <MenuItemLink
              to="/nftbatch"
              primaryText="Batches"
              leftIcon={<QueueIcon />}
              dense={dense}
            />
            <MenuItemLink
              to="/nftspecialedition"
              primaryText="Special editions"
              leftIcon={<StarsIcon />}
              dense={dense}
            />
            <MenuItemLink
              to="/nftcard"
              primaryText="NFTs"
              leftIcon={<CropPortraitIcon />}
              dense={dense}
            />
            <MenuItemLink
              to="/filter"
              primaryText="Filters"
              leftIcon={<FilterListIcon />}
              dense={dense}
            />
          </SubMenu>
          <SubMenu
            handleToggle={handleToggle(SubMenuName.PACKS)}
            isOpen={state[SubMenuName.PACKS]}
            name={SubMenuName.PACKS}
            icon={<CardGiftcardIcon />}
            dense={dense}
          >
            <MenuItemLink
              to="/lootTable"
              primaryText="Loot Tables"
              leftIcon={<CasinoIcon />}
              dense={dense}
            />
            <MenuItemLink
              to="/packConfig"
              primaryText="Pack Configs"
              leftIcon={<CropFreeIcon />}
              dense={dense}
            />
            <MenuItemLink
              to="/pack"
              primaryText="Packs"
              leftIcon={<FilterNoneIcon />}
              dense={dense}
            />
          </SubMenu>
          <MenuItemLink
            to="/auction"
            primaryText="Auctions"
            leftIcon={<GavelIcon />}
            dense={dense}
          />
          <MenuItemLink
            to="/team"
            primaryText="Teams"
            leftIcon={<GroupIcon />}
            dense={dense}
          />
          <MenuItemLink
            to="/country"
            primaryText="Countries"
            leftIcon={<FlagOutlined />}
            dense={dense}
          />
          <MenuItemLink
            to="/athlete"
            primaryText="Athletes"
            leftIcon={<DirectionsRunIcon />}
            dense={dense}
          />
          <MenuItemLink
            to="/gameweek"
            primaryText="GameWeeks"
            leftIcon={<TodayIcon />}
            dense={dense}
          />
          <MenuItemLink
            to="/formation"
            primaryText="Formations"
            leftIcon={<GroupWorkIcon />}
            dense={dense}
          />
          <MenuItemLink
            to="/match"
            primaryText="Matches"
            leftIcon={<DefaultIcon />}
            dense={dense}
          />
          <MenuItemLink
            to="/user"
            primaryText="Users"
            leftIcon={<PermIdentityIcon />}
            dense={dense}
          />
          <MenuItemLink
            to="/usercohort"
            primaryText="User cohorts"
            leftIcon={<PeopleOutlineIcon />}
            dense={dense}
          />
          <MenuItemLink
            to="/sale"
            primaryText="Sales"
            leftIcon={<AttachMoneyIcon />}
            dense={dense}
          />
          <MenuItemLink
            to="/translation"
            primaryText="Translations"
            leftIcon={<TranslateIcon />}
            dense={dense}
          />
          <SubMenu
            handleToggle={handleToggle(SubMenuName.DEVELOPMENT)}
            isOpen={state[SubMenuName.DEVELOPMENT]}
            name={SubMenuName.DEVELOPMENT}
            icon={<WarningIcon />}
            dense={dense}
          >
            <MenuItemLink
              to="/cron"
              primaryText="Crons"
              leftIcon={<AccessAlarmIcon />}
            />
          </SubMenu>
        </div>
      );
    default:
      return <div className={classes.root} />;
  }
};
