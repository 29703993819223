import React, { VFC } from 'react';

interface IProps<P extends object = object> {
  record?: any;
  component: VFC<{ record: any; source?: string } & P>;
}

export const PrimitiveField = ({
  record,
  component: Component,
  ...props
}: IProps) => (
  <Component source="primitive" record={{ primitive: record }} {...props} />
);
