import { Typography } from '@material-ui/core';
import { Info } from '@material-ui/icons';
import React from 'react';
import {
  ArrayField,
  ChipField,
  SimpleForm,
  SingleFieldList,
  TextInput,
} from 'react-admin';
import styled from 'styled-components';
import { PrimitiveField } from '~ui/PrimitiveField';

import { CountryCodeInput } from './CountryCodeInput';

const Notice = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: 360px;
  margin-top: 2rem;
`;

export const CountryForm = (props: any) => (
  <SimpleForm {...props}>
    <TextInput source="name" />
    <ArrayField source="codes">
      <SingleFieldList linkType={false}>
        <PrimitiveField component={ChipField} />
      </SingleFieldList>
    </ArrayField>
    <CountryCodeInput />

    <Notice>
      <Info color="primary" />
      <Typography>
        You can find a list of FIFA codes{' '}
        <a
          href="https://en.wikipedia.org/wiki/List_of_FIFA_country_codes"
          target="_blank"
          rel="noreferrer"
        >
          here
        </a>
        .
      </Typography>
      <Typography>
        You can find a list of ISO codes{' '}
        <a href="https://countrycode.org/" target="_blank" rel="noreferrer">
          here
        </a>
        .
      </Typography>
    </Notice>
  </SimpleForm>
);
