import { CircularProgress, Typography } from '@material-ui/core';
import { Cached, GetApp } from '@material-ui/icons';
import * as React from 'react';
import { useState } from 'react';
import {
  ArrayInput,
  AutocompleteInput,
  BooleanInput,
  Button,
  DateTimeInput,
  downloadCSV,
  FormDataConsumer,
  ReferenceInput,
  required,
  SimpleFormIterator,
  TextInput,
  useNotify,
  useRefresh,
} from 'react-admin';
import ReactJson from 'react-json-view';
import styled from 'styled-components';
import { GameWeek, SoccerData } from 'ultimate-league-common';
import { fetchApi } from '~technical/api';
import { error } from '~technical/logger';
import { SafeNumberInput } from '~ui/SafeNumberInput';

import { SPORT_CONTEXT } from '../../../constant';

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const SectionContainer = styled.div`
  display: flex;

  > div {
    margin-right: 20px;
  }
`;

const VirtualMatchesActions = styled.div`
  margin-bottom: 20px;

  > :not(:last-child) {
    margin-right: 12px;
  }
`;

interface IVirtualMatchesProps {
  record?: GameWeek.IGameWeek & { id: string };
}

const defaultPreviousGameDataCount = 20;
const defaultGameSelectionCount = 3;

export const Virtual = ({ record }: IVirtualMatchesProps) => {
  const refresh = useRefresh();
  const [loading, setLoading] = useState(false);

  const notify = useNotify();

  const onFilterClubs = (name: string) => ({
    name,
    type: SoccerData.Team.TeamType.CLUB,
  });

  const validationSchema = [required()];

  const [confirmGenerate, setConfirmGenerate] = useState<
    Record<string, ReturnType<typeof setTimeout> | false>
  >({});
  const clearConfirmGenerate = (matchId: string) => {
    const timeout = confirmGenerate[matchId];
    if (timeout) {
      clearTimeout(timeout);
    }
    setConfirmGenerate((oldConfirmRemove) => ({
      ...oldConfirmRemove,
      [matchId]: false,
    }));
  };
  const showConfirmGenerate = (matchId: string) => {
    setConfirmGenerate((oldConfirmRemove) => ({
      ...oldConfirmRemove,
      [matchId]: setTimeout(() => clearConfirmGenerate(matchId), 5_000),
    }));
  };
  const handleGenerateStats = async (matchId: string) => {
    if (record) {
      try {
        if (!confirmGenerate[matchId]) {
          showConfirmGenerate(matchId);
          return;
        }

        setLoading(true);
        clearConfirmGenerate(matchId);

        await fetchApi(
          `/backoffice/virtual-gameweek/${record.id}/generate-stats/${matchId}`,
          {
            method: 'POST',
          }
        );
        refresh();
      } catch (e) {
        error(e as Error);
        notify('An error occurred while generating stats. Aborting.', 'error');
      } finally {
        setLoading(false);
      }
    }
  };

  const handleExportStats = async (matchId: string) => {
    if (record) {
      try {
        setLoading(true);

        const response = await fetchApi(
          `/backoffice/virtual-gameweek/${record.id}/export-stats/${matchId}`,
          {
            method: 'POST',
          }
        );

        downloadCSV((await response.json()).CSV, 'virtual-match-stats');
      } catch (e) {
        error(e as Error);
        notify(
          'An error occurred while generating the CSV. Aborting.',
          'error'
        );
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <>
      <Typography variant="h6">Virtual Matches</Typography>
      <ArrayInput source="virtualMatches" label="Virtual Matches">
        <SimpleFormIterator getItemLabel={(index) => `Match ${index + 1}`}>
          <FormDataConsumer>
            {({ scopedFormData, getSource }) =>
              getSource && (
                <>
                  <Wrapper>
                    <SectionContainer>
                      <ReferenceInput
                        label="Home Contestant"
                        source={getSource('homeContestant')}
                        reference="team"
                        filterToQuery={onFilterClubs}
                        validate={validationSchema}
                      >
                        <AutocompleteInput optionText="name" optionValue="id" />
                      </ReferenceInput>
                      <ReferenceInput
                        label="Away Contestant"
                        source={getSource('awayContestant')}
                        reference="team"
                        filterToQuery={onFilterClubs}
                        validate={validationSchema}
                      >
                        <AutocompleteInput optionText="name" optionValue="id" />
                      </ReferenceInput>
                    </SectionContainer>
                    <SectionContainer>
                      <DateTimeInput
                        label="Match Date"
                        source={getSource('date')}
                        inputProps={{
                          step: 3600,
                        }}
                        validate={validationSchema}
                      />
                      <SafeNumberInput
                        defaultValue={defaultPreviousGameDataCount}
                        label="Previous game data count"
                        source={getSource('previousGameDataCount')}
                        min={0}
                        validate={validationSchema}
                      />
                      <SafeNumberInput
                        defaultValue={defaultGameSelectionCount}
                        label="Game selection count"
                        source={getSource('gameSelectionCount')}
                        min={0}
                        validate={validationSchema}
                      />
                      <TextInput
                        label="Competition"
                        source={getSource('competition')}
                      />
                      <TextInput
                        style={{ display: 'none' }}
                        source={getSource('sport')}
                        defaultValue={SPORT_CONTEXT}
                      />
                    </SectionContainer>
                  </Wrapper>
                  <VirtualMatchesActions>
                    {/* eslint-disable-next-line no-underscore-dangle */}
                    {scopedFormData?._id && (
                      <Button
                        color="primary"
                        variant="outlined"
                        label={
                          // eslint-disable-next-line no-underscore-dangle
                          confirmGenerate[scopedFormData._id]
                            ? 'Click again to confirm'
                            : 'Generate Stats'
                        }
                        onClick={() =>
                          // eslint-disable-next-line no-underscore-dangle
                          handleGenerateStats(scopedFormData._id)
                        }
                        startIcon={
                          loading ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : (
                            <Cached />
                          )
                        }
                        disabled={loading}
                      />
                    )}
                    {scopedFormData?.score && (
                      <Button
                        color="primary"
                        variant="contained"
                        label="Export stats"
                        startIcon={
                          loading ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : (
                            <GetApp />
                          )
                        }
                        // eslint-disable-next-line no-underscore-dangle
                        onClick={() => handleExportStats(scopedFormData._id)}
                        disabled={loading}
                      />
                    )}
                  </VirtualMatchesActions>
                  {scopedFormData?.referenceMatches && (
                    <ReactJson
                      style={{ marginBottom: 24 }}
                      src={scopedFormData.referenceMatches}
                      name="Reference Matches"
                      collapsed={1}
                    />
                  )}
                </>
              )
            }
          </FormDataConsumer>
        </SimpleFormIterator>
      </ArrayInput>
      <Typography variant="h6">Misc</Typography>
      <BooleanInput
        source="virtualUpdateSeasonScore"
        label="Update season score (Use only for testing purpose)"
      />
    </>
  );
};
