import { Box, MenuItem, Select, Typography } from '@material-ui/core';
import PlusIcon from '@material-ui/icons/AddCircle';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import MinusIcon from '@material-ui/icons/RemoveCircle';
import Handlebars from 'handlebars';
import React, {
  BaseSyntheticEvent,
  ChangeEvent,
  FC,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useDataProvider } from 'react-admin';
import { Blockchain } from 'ultimate-league-common';
import { useCounter } from 'usehooks-ts';
import { IAthlete } from '~business/athlete/types';
import { ISavedRaritySupply } from '~business/common/assetTypes';
import { fetchApi } from '~technical/api';

import { ISavedNftBatch, ISavedNftSeason } from '../../types';
import { AthletePreview } from './athlete-preview';
import { mergeRarities } from './preview-helpers';
import {
  AthletesCount,
  Button,
  Count,
  InputWrapper,
  MaxButton,
  PreviewCount,
  PreviewsContainer,
  StyledInput,
  StyledInputLabel,
  StyledSlider,
  TopRow,
  Wrapper,
} from './styles';

interface IProps {
  record?: ISavedNftBatch;
}

const CardPreview: FC<IProps> = ({ record }: IProps) => {
  const dataProvider = useDataProvider();

  // Data
  const [season, setSeason] = useState<ISavedNftSeason>();
  const [athletes, setAthletes] = useState<IAthlete[]>([]);
  const [rarities, setRarities] = useState<ISavedRaritySupply[]>([]);
  const [currentRarity, setCurrentRarity] = useState<ISavedRaritySupply>();

  // Form
  const [scale, setScale] = useState<number>(0.7);
  const [edition, setEdition] = useState<number>();
  const { count, setCount, increment, decrement } = useCounter(1);
  const [customTemplateValues, setCustomTemplateValues] = useState<
    Record<number, string>
  >({});
  const customTemplateValuesArray = useMemo(
    () =>
      Object.keys(customTemplateValues)
        .sort()
        .map((i) => customTemplateValues[i]),
    [customTemplateValues]
  );

  // Errors
  const [templateError, setTemplateError] = useState<string>();
  const [seasonError, setSeasonError] = useState<string>();
  const [athletesError, setAthletesError] = useState<string>();

  const compiledTemplate = useMemo(() => {
    const template = record?.cardTemplate || season?.cardTemplate;
    if (template) {
      try {
        return Handlebars.compile(template);
      } catch (e) {
        setTemplateError(`Failed to compile template: ${(e as Error).message}`);
      }
    }
    return null;
  }, [record, season]);

  useEffect(() => {
    (async () => {
      // Fetch athletes
      try {
        if (record) {
          const query = JSON.stringify({
            _id: {
              $in: record.athletes,
            },
          });
          const populate = JSON.stringify([
            {
              path: 'club',
              populate: {
                path: 'competition',
              },
            },
            { path: 'national' },
            { path: 'country' },
          ]);
          const response = await fetchApi(
            `/backoffice/athlete?query=${query}&populate=${populate}&select=-scores,-highlights&sort=matchName`
          );
          setAthletes(await response.json());
          setAthletesError(undefined);
        }
      } catch (e) {
        setAthletesError((e as Error).message);
      }
    })();
  }, [record]);

  useEffect(() => {
    (async () => {
      // Fetch season
      try {
        if (record) {
          const result = await dataProvider.getOne<ISavedNftSeason>(
            'NftCardSeason',
            {
              id: record.season,
            }
          );
          setSeason(result.data);
          setSeasonError(undefined);

          setCustomTemplateValues({
            ...[
              record?.cardTemplateValues,
              result.data.cardTemplateValues,
            ].find((v) => v?.length),
          });
        }
      } catch (e) {
        setSeasonError((e as Error).message);
      }
    })();
  }, [record, dataProvider]);

  useEffect(() => {
    if (record && season && season.rarities.length) {
      const availableRarities = mergeRarities(record, season);
      setRarities(availableRarities);

      const defaultRarity = availableRarities[0];
      setCurrentRarity(defaultRarity);
      return;
    }
    setCurrentRarity(undefined);
    setRarities([]);
  }, [record, season]);

  useEffect(() => {
    if (currentRarity && typeof edition !== 'number') {
      setEdition(Math.floor(Math.random() * currentRarity.supply) + 1);
    }
  }, [currentRarity, edition]);

  /*
   * Inputs handlers
   */

  const onRarityInputChange = (event: BaseSyntheticEvent) => {
    const newLevel = parseInt(event.target.value, 10);
    setCurrentRarity(rarities?.find((r) => r.level === newLevel));
  };

  const onScaleInputChange = (
    event: ChangeEvent<{}>,
    value: number | Array<number>
  ) => {
    setScale(Array.isArray(value) ? value[0] : value);
  };

  const onEditionInputChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const newEdition = parseInt(event.target.value, 10);
    if (Number.isNaN(newEdition) !== true) {
      setEdition(newEdition);
    }
  };

  const decrementPreviewCount = () => {
    if (count > 1) {
      decrement();
    }
  };
  const incrementPreviewCount = () => {
    if (count < athletes.length) {
      increment();
    }
  };

  const templateValuesCount =
    [record?.cardTemplateValues, season?.cardTemplateValues].find(
      (v) => v?.length
    )?.length ?? 0;

  return (
    <Typography variant="body2" component="div">
      <Wrapper>
        {templateError && (
          <Box color="red">Template error: {templateError}</Box>
        )}
        {seasonError && <Box color="red">Season error: {seasonError}</Box>}
        {athletesError && (
          <Box color="red">Athletes error: {athletesError}</Box>
        )}

        {Array.isArray(rarities) && (
          <TopRow>
            <AthletesCount>
              <InfoIcon />
              This batch contains {athletes.length} athletes
            </AthletesCount>
            {currentRarity && (
              <InputWrapper>
                <StyledInputLabel id="select-rarity-level">
                  Rarity level
                </StyledInputLabel>
                <Select
                  labelId="select-rarity-level"
                  value={currentRarity.level}
                  label="Rarity level"
                  onChange={onRarityInputChange}
                >
                  {rarities.map((rarity) => (
                    <MenuItem key={rarity.level} value={rarity.level}>
                      {Blockchain.NFTCard.RarityLevel[rarity.level]}
                    </MenuItem>
                  ))}
                </Select>
              </InputWrapper>
            )}
            <InputWrapper>
              <StyledInputLabel id="select-rarity-level">
                Preview scale ({scale})
              </StyledInputLabel>
              <StyledSlider
                aria-label="Preview scale"
                step={0.1}
                min={0.3}
                max={2}
                value={scale}
                onChange={onScaleInputChange}
              />
            </InputWrapper>
            <InputWrapper>
              <StyledInputLabel id="select-rarity-level">
                Edition
              </StyledInputLabel>
              <StyledInput
                type="number"
                value={edition || 1}
                onChange={onEditionInputChange}
              />
            </InputWrapper>
            <PreviewCount>
              <div>Preview count:</div>
              <Button>
                <MinusIcon color="secondary" onClick={decrementPreviewCount} />
              </Button>
              <Count>{count}</Count>
              <Button>
                <PlusIcon color="secondary" onClick={incrementPreviewCount} />
              </Button>
              <Button>
                <MaxButton
                  onClick={() => {
                    setCount(athletes.length);
                  }}
                >
                  all
                </MaxButton>
              </Button>
            </PreviewCount>
            {Array.from({ length: templateValuesCount }, (_, i) => (
              <InputWrapper key={i}>
                <StyledInputLabel>Value {i + 1}</StyledInputLabel>
                <StyledInput
                  value={customTemplateValues[i]}
                  onChange={(e) =>
                    setCustomTemplateValues((v) => ({
                      ...v,
                      [i]: e.target.value,
                    }))
                  }
                />
              </InputWrapper>
            ))}
          </TopRow>
        )}

        {currentRarity &&
          typeof edition === 'number' &&
          record &&
          season &&
          athletes.length > 0 &&
          compiledTemplate && (
            <PreviewsContainer>
              {Array.from(Array(count)).map((_value, index) => (
                <AthletePreview
                  /* eslint-disable-next-line react/no-array-index-key */
                  key={index}
                  index={index}
                  athletes={athletes}
                  compiledTemplate={compiledTemplate}
                  edition={edition}
                  scale={scale}
                  batch={record}
                  season={season}
                  raritySelected={currentRarity}
                  templateValues={customTemplateValuesArray}
                />
              ))}
            </PreviewsContainer>
          )}
      </Wrapper>
    </Typography>
  );
};

export default CardPreview;
