import { Database } from 'ultimate-league-common';
import { IFont } from '~business/nft-batch/types';
import { fetchAsset } from '~business/storage/service';

import { getAssetPath } from './asset-helpers';

/**
 * Get a font as data-url
 */
const getFontAsDataUrl = async (id: string, mediaType: string) => {
  const { data } = await fetchAsset({ id });
  return `data:${mediaType};charset=utf-8;base64,${data}`;
};

async function updateFontsData(
  cardFonts: IFont[],
  fn: (id: Database.DocumentId, mediaType: string) => Promise<string>
) {
  let fonts: IFont[] = [];
  if (Array.isArray(cardFonts)) {
    fonts = await Promise.all(
      cardFonts.map(async ({ font, ...fontData }) => ({
        ...fontData,
        font: await fn(font, fontData.mediaType),
      }))
    );
  }
  return fonts;
}

export const getFontsWithPath = (cardFonts: IFont[]) =>
  updateFontsData(cardFonts, getAssetPath);

export const getFontsWithDataUrl = (cardFonts: IFont[]) =>
  updateFontsData(cardFonts, getFontAsDataUrl);
