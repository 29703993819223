import { Typography } from '@material-ui/core';
import React from 'react';
import { ReferenceField, TextField } from 'react-admin';
import { IBatchIngredientError } from 'ultimate-league-common';

import { BatchErrorDetail } from './BatchErrorDetail';

interface IBatchErrorsProps {
  errors: IBatchIngredientError[];
}

export const BatchErrors = ({ errors }: IBatchErrorsProps) => (
  <Typography variant="body1">
    <ReferenceField
      record={{ id: errors[0].athleteId }}
      reference="athlete"
      source="id"
    >
      <TextField source="matchName" />
    </ReferenceField>
    <ul style={{ opacity: 0.5 }}>
      {errors.map((error) => (
        <BatchErrorDetail key={JSON.stringify(error)} error={error} />
      ))}
    </ul>
  </Typography>
);
