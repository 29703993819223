import { IPackConfig } from './types';

export const validateForm = (packConfig: IPackConfig) => {
  const errors: any = {};

  if (!packConfig.lootTable) {
    errors.lootTable = 'Selecting a loot table is mandatory';
  }

  // Check monetization values
  if (packConfig.isMonetizationEnabled && packConfig.monetization) {
    const { monetization: m } = packConfig;
    errors.monetization = {};

    if (typeof m?.price !== 'number') {
      errors.monetization.price = 'Price is mandatory';
    } else if (m.price < 0) {
      errors.monetization.price = 'Price cannot be negative';
    }

    if (!m?.hasInfiniteSupply) {
      if (typeof m?.supply !== 'number') {
        errors.monetization.supply = 'Supply is mandatory';
      } else if (m.supply < 0) {
        errors.monetization.supply = 'Supply cannot be negative';
      } else if (m.supply === 0) {
        errors.monetization.supply =
          'Supply 0 does not make any sense, does it?';
      }
    }

    const announcingDate = m?.announcingDate
      ? new Date(m.announcingDate)
      : null;
    const startDate = m?.startDate ? new Date(m.startDate) : null;
    if (!startDate || Number.isNaN(startDate.getTime())) {
      errors.monetization.startDate = 'Start date is mandatory';
    }
    const endDate = m?.endDate ? new Date(m.endDate) : null;
    if (announcingDate) {
      if (startDate && startDate.getTime() < announcingDate.getTime()) {
        errors.monetization.startDate =
          "Start date can't be before announcing date";
      }
      if (endDate && endDate.getTime() < announcingDate.getTime()) {
        errors.monetization.endDate =
          "End date can't be before announcing date";
      }
    }
    if (startDate && endDate) {
      if (endDate.getTime() < startDate.getTime()) {
        errors.monetization.endDate = "End date can't be before start date";
      }
    }
    if (
      !packConfig.monetization.allowUnaPurchase &&
      !packConfig.monetization.allowUlcPurchase &&
      !packConfig.monetization.allowCreditCardPurchase
    ) {
      errors.monetization.allowCryptosPurchase =
        'Please define at least one payment method';
    }
  }

  return errors;
};
