import React from 'react';
import { Create, CreateProps } from 'react-admin';

import { PackConfigForm } from './PackConfigForm';

export const PackConfigCreate = (props: CreateProps) => (
  <Create {...props}>
    {
      // @ts-ignore
      <PackConfigForm />
    }
  </Create>
);
