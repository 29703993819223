import React, { KeyboardEventHandler, PropsWithChildren } from 'react';
import {
  AutocompleteInput,
  BooleanField,
  Datagrid,
  DateField,
  Filter,
  List,
  ReferenceField,
  ReferenceInput,
  ShowButton,
  TextField,
  TextInput,
  usePermissions,
} from 'react-admin';
import { User } from 'ultimate-league-common';
import { PackConfigField } from '~technical/filters/packConfig';
import { UserField } from '~technical/filters/user';

const PackFilter = ({ children, ...props }: PropsWithChildren<{}>) => {
  const handleKeyPress: KeyboardEventHandler = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  };

  return (
    /* TODO: fix types */
    /* @ts-ignore */
    <Filter {...props} onKeyPress={handleKeyPress}>
      <TextInput source="_id" />
      <ReferenceInput
        reference="user"
        source="owner"
        filterToQuery={(search: string) => ({
          [UserField.USERNAME]: search,
        })}
      >
        <AutocompleteInput optionText="username" optionValue="id" />
      </ReferenceInput>
      <ReferenceInput
        reference="packConfig"
        source="packConfig"
        filterToQuery={(search: string) => ({
          [PackConfigField.NAME]: search,
        })}
      >
        <AutocompleteInput optionText="name" optionValue="id" />
      </ReferenceInput>
      {children}
    </Filter>
  );
};

export const PackList = (props: {}) => {
  const { permissions } = usePermissions();
  return (
    <List
      {...props}
      filters={<PackFilter />}
      sort={{ field: 'updatedAt', order: 'DESC' }}
      bulkActionButtons={false}
    >
      <Datagrid>
        <ReferenceField
          source="packConfig"
          reference="packConfig"
          link={permissions === User.Role.MODERATOR}
        >
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField source="owner" reference="user">
          <TextField source="username" />
        </ReferenceField>
        <DateField showTime source="updatedAt" />
        <BooleanField source="open" />
        <DateField showTime source="openedAt" />
        <BooleanField source="distributed" />
        <ShowButton />
      </Datagrid>
    </List>
  );
};
