import { useEffect, useState } from 'react';
import { useDataProvider, useNotify } from 'react-admin';
import { Score } from 'ultimate-league-common';

import { IModifierMatrix } from './types';

export function useModifiers() {
  const notify = useNotify();
  const dataProvider = useDataProvider();

  const [loading, setLoading] = useState<boolean>(true);
  const [editionModifiers, setEditionModifiers] = useState<
    Score.IEditionItem[]
  >([]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const result = await dataProvider.getList<IModifierMatrix>(
          'modifiermatrix',
          {
            filter: {},
            pagination: {
              page: 1,
              perPage: 1000,
            },
            sort: { field: 'createdAt', order: 'DESC' },
          }
        );
        if (result?.data && result.data.length > 0) {
          setEditionModifiers(result.data[0].matrix.editions);
        } else {
          setEditionModifiers([]);
        }
      } catch (e) {
        notify('Failed to fetch modifier matrix', {
          type: 'warning',
        });
      }
      setLoading(false);
    })();
  }, [notify, dataProvider, setLoading, setEditionModifiers]);

  return {
    loading,
    editionModifiers,
  };
}
