import { Check, ReportProblem } from '@material-ui/icons';
import React, { KeyboardEventHandler, PropsWithChildren } from 'react';
import {
  Datagrid,
  DateField,
  Filter,
  List,
  ShowButton,
  TextField,
  TextInput,
} from 'react-admin';
import styled from 'styled-components';
import { DateRangeInput } from '~business/common/components/DateRangeInput';
import { CronField } from '~technical/filters/cron';
import { PresenceField } from '~ui/PresenceField';

import { DurationField } from './DurationField';

const StyledFilter = styled(Filter)`
  margin-top: 0rem;
  margin-bottom: 0.5rem;
`;

const CronFilter = ({ children, ...props }: PropsWithChildren<{}>) => {
  const handleKeyPress: KeyboardEventHandler = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  };

  return (
    /* TODO: fix types */
    /* @ts-ignore */
    <StyledFilter {...props} onKeyPress={handleKeyPress}>
      <TextInput source={CronField.JOB} label="Job" />
      <DateRangeInput source={CronField.CREATION_DATE} type="datetime-local" />
      {children}
    </StyledFilter>
  );
};

export const CronList = (props: {}) => (
  <List
    sort={{ field: CronField.CREATION_DATE, order: 'DESC' }}
    filters={<CronFilter />}
    {...props}
  >
    <Datagrid>
      <TextField source="job" />
      <DateField source="createdAt" showTime />
      <DurationField source="duration" />
      <TextField source="logs" />
      <PresenceField
        source="error"
        TrueIcon={ReportProblem}
        FalseIcon={Check}
      />
      <ShowButton />
    </Datagrid>
  </List>
);
