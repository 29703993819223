import { Typography } from '@material-ui/core';
import React from 'react';
import {
  BooleanField,
  Datagrid,
  EditButton,
  List,
  ShowButton,
  TextField,
  useListContext,
} from 'react-admin';
import styled from 'styled-components';

const Warning = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem 0;
  padding: 0.5rem;
  color: #ee4f00;
  background-color: #fee6ba;
`;

const DefaultWarning = () => {
  const { data } = useListContext();
  const defaultFormations = Object.values(data).filter((f) => f.default);
  switch (defaultFormations.length) {
    case 0:
      return (
        <Warning>
          <Typography variant="body1">
            ⚠ We should have one default formation.
          </Typography>
        </Warning>
      );
    case 1:
      return null;
    default:
      return (
        <Warning>
          <Typography variant="body1">
            ⚠ We should have only one default formation, found{' '}
            {defaultFormations.length}.
          </Typography>
        </Warning>
      );
  }
};

const Actions = (props: any) => (
  <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
    <ShowButton {...props} />
    <EditButton {...props} />
  </div>
);

export const FormationList = (props: {}) => (
  <List exporter={false} {...props}>
    <>
      <DefaultWarning />
      {/* @ts-ignore */}
      <Datagrid>
        <TextField source="title" />
        <TextField source="sport" />
        <BooleanField source="default" />
        <Actions />
      </Datagrid>
    </>
  </List>
);
