import React from 'react';
import {
  BooleanField,
  Datagrid,
  EditButton,
  List,
  ShowButton,
  TextField,
} from 'react-admin';
import { Storage } from 'ultimate-league-common';
import { AssetField } from '~ui/AssetField';

const Actions = (props: any) => (
  <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
    <ShowButton {...props} />
    <EditButton {...props} />
  </div>
);

export const CompetitionList = (props: {}) => (
  <List {...props} bulkActionButtons={false}>
    <Datagrid>
      <TextField source="name" />
      <TextField source="type" />
      <AssetField
        assetParams={{
          storageType: Storage.StorageType.PUBLIC_COMPETITION_ASSET,
          format: Storage.AssetFormat.SMALL,
        }}
        source="asset"
      />
      <BooleanField source="covered" />
      <Actions />
    </Datagrid>
  </List>
);
