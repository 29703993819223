import { DefaultTheme, makeStyles } from '@material-ui/styles';
import * as React from 'react';
import { FileField as BaseFileField, FileInput } from 'react-admin';
import { useField } from 'react-final-form';
import { getUploadSource } from '~business/storage/service';

const useStyles = makeStyles<DefaultTheme, { hideDropZone: boolean }>({
  dropZone: {
    display: ({ hideDropZone }) => (hideDropZone ? 'none' : 'block'),
  },
});

const FileField = (props: any) => {
  const { record } = props;
  return (
    <>
      {!record?.storage ? null : (
        <div>{`Font storage id: ${record?.storage}`}</div>
      )}
      <BaseFileField {...props} title={record?.rawFile?.name} />
    </>
  );
};

function formatRecord(record: string | object) {
  return typeof record === 'string' ? { storage: record } : record;
}

interface IFileStorageProps {
  source: string;
  label?: string;
  multiple?: boolean;
  accept?: string;
}

export const StorageFileInput = ({
  source,
  label,
  multiple,
  accept,
}: IFileStorageProps) => {
  const uploadSource = getUploadSource(source);
  const field = useField(uploadSource);
  const classes = useStyles({
    hideDropZone: Boolean(
      field.input.value && !(field.input.value instanceof Array)
    ),
  });

  return (
    <FileInput
      source={uploadSource}
      accept={accept || undefined}
      classes={classes}
      label={label}
      format={(record: string | object | Array<string | object>) => {
        if (record instanceof Array) {
          return record.map(formatRecord);
        }
        return formatRecord(record);
      }}
      multiple={multiple}
    >
      <FileField source="src" />
    </FileInput>
  );
};
