import React from 'react';

/* eslint-disable import/no-mutable-exports */

/* eslint-disable global-require */
import { Blockchain } from 'ultimate-league-common';

type IconType =
  | React.ComponentClass<React.SVGProps<SVGSVGElement>, any>
  | undefined;

let icons: {
  Fungible: IconType;
  Rarity4: IconType;
  Rarity3: IconType;
  Rarity2: IconType;
  Rarity1: IconType;
};

if (process.env.BRAND === 'UC') {
  icons = require('./UC');
}

if (process.env.BRAND === 'LFP') {
  icons = require('./LFP');
}

export const { Fungible, Rarity4, Rarity3, Rarity2, Rarity1 } = icons!;

export const RarityIcons = {
  [Blockchain.NFTCard.RarityLevel.FUNGIBLE]: Fungible,
  [Blockchain.NFTCard.RarityLevel.RARITY_4]: Rarity4,
  [Blockchain.NFTCard.RarityLevel.RARITY_3]: Rarity3,
  [Blockchain.NFTCard.RarityLevel.RARITY_2]: Rarity2,
  [Blockchain.NFTCard.RarityLevel.RARITY_1]: Rarity1,
};
