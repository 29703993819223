import { processGenericFilter, Value } from './generic';

export enum BatchFilterFields {
  Label = 'label',
}

export function processNftBatchFilter(
  field: BatchFilterFields | string,
  value: Value
) {
  switch (field) {
    case BatchFilterFields.Label:
      return { label: { $regex: value, $options: 'i' } };
    default:
      return processGenericFilter(field, value);
  }
}
