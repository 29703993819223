import React from 'react';
import {
  Datagrid,
  EditButton,
  Filter,
  List,
  ReferenceField,
  TextField,
  TextInput,
} from 'react-admin';
import { Storage } from 'ultimate-league-common';
import { TeamField } from '~technical/filters/team';
import { AssetField } from '~ui/AssetField';
import { QuickFilter } from '~ui/QuickFilter';

import { TShirtField } from './TShirtField';

export const TeamList = (props: {}) => (
  <List
    {...props}
    filters={
      <Filter>
        <TextInput source={TeamField.JOKER} label="Name" />
        <QuickFilter
          source={TeamField.MISSING_DATA}
          label="Requires manual data"
          defaultValue
        />
        <QuickFilter
          source={TeamField.LICENSED}
          label="Licensed"
          defaultValue
        />
      </Filter>
    }
    bulkActionButtons={false}
  >
    <Datagrid>
      <TextField source="name" />
      <ReferenceField source="country" reference="country" link={false}>
        <TextField source="name" />
      </ReferenceField>
      <TextField source="type" />
      <TextField source="code" />
      <TShirtField source="shirtColor" label="Shirt" />
      <AssetField
        assetParams={{
          storageType: Storage.StorageType.PUBLIC_TEAM_LOGO,
          format: Storage.AssetFormat.SMALL,
        }}
        source="assets.logo"
      />
      <EditButton {...props} />
    </Datagrid>
  </List>
);
