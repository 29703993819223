import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  makeStyles,
  Typography,
} from '@material-ui/core';
import {
  AddCircleOutline,
  ArrowDownward,
  ArrowUpward,
  ExpandLess,
  ExpandMore,
  RemoveCircleOutline,
} from '@material-ui/icons';
import { nanoid } from 'nanoid';
import React, { useCallback, useState } from 'react';
import {
  AutocompleteArrayInput,
  AutocompleteInput,
  BooleanInput,
  Button,
  NumberInput,
  ReferenceArrayInput,
  ReferenceInput,
  SelectInput,
} from 'react-admin';
import { FieldArrayRenderProps } from 'react-final-form-arrays';
import styled from 'styled-components';
import { enumToArray, GameView, SoccerData } from 'ultimate-league-common';
import { useDefaultFormation } from '~business/gameweek/hooks/useDefaultFormation';
import { TranslationField } from '~technical/filters/translation';

import { GameModeInput } from '../GameModeInput';
import { getNewDivision, IDivision } from './DivisionIterator';

const iconChoices = enumToArray(GameView.GameViewIcon).map((icon) => ({
  id: icon,
  name: icon,
}));

const getNewTournament = (
  index?: number,
  defaultFormation?: GameView.IFormation
) => ({
  tempId: nanoid(),
  title: `Tournament ${typeof index === 'number' ? index + 1 : ''}`,
  // eslint-disable-next-line no-underscore-dangle
  formations: defaultFormation ? [defaultFormation.id] : [],
  divisions: [getNewDivision()],
});

const ArrayWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const AddButtonContainer = styled.div``;

const StyledNumber = styled(NumberInput)`
  width: 300px;
`;

const TournamentIcon = styled(SelectInput)`
  width: 300px;
`;

const StyledAutocompleteArrayInput = styled(AutocompleteArrayInput)`
  width: 300px;
`;

const TournamentContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledAccordion = styled(Accordion)`
  margin-bottom: 8px;
  width: 100%;
  pointer-events: none;

  & .MuiAccordionSummary-content {
    cursor: default;
    pointer-events: auto;
  }
  & .MuiCollapse-root {
    pointer-events: auto;
  }
`;

const StyledSummary = styled(AccordionSummary)`
  background: silver;

  & > div {
    display: flex;
    justify-content: space-between;
  }

  & button {
    background-color: #d6d6d6;

    &:hover {
      background-color: #eaeaea;
    }

    &[disabled] {
      cursor: default;
    }
  }
`;

const SummaryButtonsRight = styled.div`
  display: flex;
  align-items: center;

  & button {
    min-height: 35px;
    margin-left: 0.5rem;
  }
`;

const MoveButton = styled(Button)`
  min-width: 50px;
`;

const StyledDetails = styled(AccordionDetails)`
  border: 5px solid silver;
  display: flex;
  flex-direction: column;
`;

const CloneButton = styled(Button)`
  margin-left: auto;
  padding: 2px 6px 0 0;
`;

const useStyles = makeStyles((theme) => ({
  removeButton: {
    color: theme.palette.error.main,
    borderColor: theme.palette.error.main,
    '&:hover': {
      background: 'none',
      color: 'darkred',
      borderColor: theme.palette.error.main,
    },
    margin: 'auto',
  },
  addButton: {
    '&:hover': {
      background: 'none',
      color: 'darkblue',
    },
  },
}));

interface ITournament {
  _id?: string;
  tempId?: string;
  title: string;
  divisions: IDivision[];
}
type Fields = FieldArrayRenderProps<ITournament, HTMLDivElement>['fields'];

interface IProps {
  fields: Fields;
  source: string;
}

export const TournamentIterator = ({ fields, source }: IProps) => {
  const classes = useStyles();
  const { defaultFormation } = useDefaultFormation();
  const [expandedTournaments, setExpandedTournaments] = useState<
    Record<string, boolean | undefined>
  >({});
  const setExpandedTournament = useCallback(
    (tournamentId: string, expanded: boolean) => {
      setExpandedTournaments((e) => ({
        ...e,
        [tournamentId]: expanded,
      }));
    },
    [setExpandedTournaments]
  );

  const handleAdd = (data?: any) => {
    const tournament =
      data ?? getNewTournament(fields?.length, defaultFormation);
    fields.push(tournament);
    setExpandedTournament(tournament.tempId, true);
  };

  const handleRemove = (index: number) => () => {
    fields.remove(index);
  };

  const moveUp = (index: number) => {
    fields.swap(index, index - 1);
  };

  const moveDown = (index: number) => {
    fields.swap(index, index + 1);
  };

  const cloneTournament = (index: number) => {
    if (!fields) {
      return;
    }
    const clonedTournament = { ...fields.value[index] };
    // eslint-disable-next-line no-underscore-dangle
    clonedTournament._id = undefined;
    clonedTournament.tempId = nanoid();
    clonedTournament.title += ' - Cloned';

    // Generate a unique id for each division
    clonedTournament.divisions = clonedTournament.divisions.map(
      (clonedDivision) => ({
        ...clonedDivision,
        _id: undefined,
        tempId: nanoid(),
      })
    );

    handleAdd(clonedTournament);
  };

  return (
    <ArrayWrapper>
      {fields.map((_tournament: any, index: number) => {
        const tournamentId =
          // eslint-disable-next-line no-underscore-dangle
          fields.value[index]._id || fields.value[index].tempId!;
        const isExpanded = !!expandedTournaments[tournamentId];

        return (
          <StyledAccordion key={tournamentId} expanded={isExpanded}>
            <StyledSummary>
              <Button
                variant="outlined"
                onClick={() => setExpandedTournament(tournamentId, !isExpanded)}
              >
                {isExpanded ? <ExpandLess /> : <ExpandMore />}
              </Button>
              <Typography variant="h6"># Tournament {index + 1}</Typography>
              <SummaryButtonsRight>
                <CloneButton
                  label="Clone"
                  variant="outlined"
                  onClick={() => cloneTournament(index)}
                />
                <Button
                  startIcon={<RemoveCircleOutline />}
                  label="Remove"
                  onClick={handleRemove(index)}
                  className={classes.removeButton}
                  variant="outlined"
                />
                <MoveButton
                  variant="outlined"
                  onClick={() => moveUp(index)}
                  disabled={index === 0}
                >
                  <ArrowUpward scale="1.5" />
                </MoveButton>
                <MoveButton
                  variant="outlined"
                  onClick={() => moveDown(index)}
                  disabled={index > (fields.length ?? 0) - 2}
                >
                  <ArrowDownward scale="1.5" />
                </MoveButton>
              </SummaryButtonsRight>
            </StyledSummary>
            <StyledDetails>
              {isExpanded && (
                <TournamentContainer>
                  {SoccerData.BrandConfig.seasonal && (
                    <BooleanInput
                      source={`${source}[${index}].isLegacySeason`}
                      label="Legacy Season"
                    />
                  )}
                  <BooleanInput
                    source={`${source}[${index}].disabled`}
                    label="Disabled"
                  />
                  <ReferenceInput
                    label="Title"
                    source={`${source}[${index}].title`}
                    reference="translation"
                    filterToQuery={(search: string) => ({
                      [TranslationField.KEY]: search,
                    })}
                  >
                    <AutocompleteInput optionText="key" optionValue="id" />
                  </ReferenceInput>
                  <ReferenceInput
                    label="Short Description"
                    source={`${source}[${index}].shortDescription`}
                    reference="translation"
                    filterToQuery={(search: string) => ({
                      [TranslationField.KEY]: search,
                    })}
                  >
                    <AutocompleteInput optionText="key" optionValue="id" />
                  </ReferenceInput>
                  <ReferenceInput
                    label="Description"
                    source={`${source}[${index}].description`}
                    reference="translation"
                    filterToQuery={(search: string) => ({
                      [TranslationField.KEY]: search,
                    })}
                  >
                    <AutocompleteInput optionText="key" optionValue="id" />
                  </ReferenceInput>
                  <TournamentIcon
                    label="Tournament icon"
                    source={`${source}[${index}].icon`}
                    choices={iconChoices}
                  />
                  <StyledNumber
                    label="Max allowed entries"
                    source={`${source}[${index}].allowedTeamCount`}
                    min={0}
                    step={1}
                  />

                  <Typography>
                    Cohorts visibility (Keep empty to make it available to all
                    cohorts)
                  </Typography>
                  <ReferenceArrayInput
                    label="Cohorts"
                    source={`${source}[${index}].cohorts`}
                    reference="usercohort"
                    defaultValue={[]}
                  >
                    <StyledAutocompleteArrayInput
                      optionText="name"
                      optionValue="id"
                    />
                  </ReferenceArrayInput>

                  <GameModeInput source={`${source}[${index}]`} />
                </TournamentContainer>
              )}
            </StyledDetails>
          </StyledAccordion>
        );
      })}
      <AddButtonContainer>
        <Button
          startIcon={<AddCircleOutline />}
          label="Add tournament"
          onClick={() => handleAdd()}
          className={classes.addButton}
        />
      </AddButtonContainer>
    </ArrayWrapper>
  );
};
