import React from 'react';
import { Create, CreateProps } from 'react-admin';

import { CountryForm } from './CountryForm';

export const CountryCreate = (props: CreateProps) => (
  <Create {...props}>
    {/* @ts-ignore */}
    <CountryForm />
  </Create>
);
