import * as React from 'react';
import { useEffect } from 'react';
import {
  BooleanField,
  Datagrid,
  DateField,
  EditButton,
  List,
  TextField,
} from 'react-admin';
import { GameWeek } from 'ultimate-league-common';
import { useConfig } from '~business/ConfigContext';

import { AssociateMatchesButton } from './AssociateMatchesButton';
import { GameWeekBulkActions } from './bulk-actions';

const rowStyle =
  (liveGameWeek?: GameWeek.IGameWeek) => (record: GameWeek.IGameWeek) => {
    if (!liveGameWeek) {
      return {};
    }

    switch (record.position) {
      case liveGameWeek.position:
        return new Date(liveGameWeek.endDate) < new Date()
          ? {
              backgroundColor: 'rgba(0, 0, 0, 0.2)',
            }
          : {
              backgroundColor: 'rgba(200, 0, 0, 0.2)',
            };
      case liveGameWeek.position + 1:
        return {
          backgroundColor: 'rgba(0, 200, 0, 0.2)',
        };
      default:
        return {};
    }
  };

const Actions = (props: any) => (
  <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
    <AssociateMatchesButton {...props} />
    <EditButton {...props} />
  </div>
);

export const GameWeekList = (props: {}) => {
  const { liveGameWeek, fetch } = useConfig();

  useEffect(fetch, [fetch]);

  return (
    <List
      sort={{ field: 'position', order: 'DESC' }}
      exporter={false}
      bulkActionButtons={<GameWeekBulkActions />}
      {...props}
    >
      {/* TODO: Fix types */}
      {/* @ts-ignore */}
      <Datagrid rowStyle={rowStyle(liveGameWeek)}>
        <DateField source="startDate" />
        <DateField source="endDate" />
        <TextField source="title" />
        <BooleanField source="isVirtual" />
        <Actions />
      </Datagrid>
    </List>
  );
};
