import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { AddCircleOutline, RemoveCircleOutline } from '@material-ui/icons';
import { nanoid } from 'nanoid';
import React from 'react';
import {
  AutocompleteInput,
  BooleanInput,
  Button,
  ReferenceInput,
  SelectInput,
} from 'react-admin';
import { FieldArrayRenderProps } from 'react-final-form-arrays';
import styled from 'styled-components';
import { enumToArray, GameView } from 'ultimate-league-common';
import { useBoolean } from 'usehooks-ts';
import { DivisionRequirementInput } from '~business/gameweek/game-modes/DivisionRequirementInput';
import { TranslationField } from '~technical/filters/translation';
import { SafeNumberInput } from '~ui/SafeNumberInput';

import { FormationsInput } from '../FormationsInput';
import { PrizePoolInput } from '../PrizePoolInput';
import { StackingPenaltiesInput } from '../StackingPenaltiesInput';

const iconChoices = enumToArray(GameView.DivisionIcon).map((icon) => ({
  id: icon,
  name: icon,
}));

export interface IDivision {
  _id?: string;
  tempId?: string;
  positionPrizePool: GameView.IPrizePool;
}

export const getNewDivision = () => ({
  tempId: nanoid(),
  positionPrizePool: {
    type: GameView.PrizePoolType.POSITION,
    brackets: [
      {
        type: GameView.BracketType.ABSOLUTE,
        from: 0,
        to: 0,
        loots: [],
      },
    ],
  },
});

const ArrayWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const ArrayItemWrapper = styled.div`
  margin-bottom: 8px;
`;

const AddButtonContainer = styled.div``;

const BracketConfigContainer = styled.div`
  display: flex;

  & > *:not(:last-child) {
    margin-right: 20px;
  }
`;

const useStyles = makeStyles((theme) => ({
  addButton: {
    '&:hover': {
      background: 'none',
      color: 'darkblue',
    },
  },
  removeButton: {
    color: theme.palette.error.main,
    '&:hover': {
      background: 'none',
      color: 'darkred',
    },
    height: 32,
    margin: 'auto 0',
  },
}));

const StyledSummary = styled(AccordionSummary)`
  background: lightsalmon;
`;

const StyledDetails = styled(AccordionDetails)`
  border: 5px solid lightsalmon;
  display: flex;
  flex-direction: column;
`;

export const DivisionIterator = ({
  fields,
  source,
}: FieldArrayRenderProps<IDivision, HTMLDivElement> & { source: string }) => {
  const classes = useStyles();
  const { value: isExpanded, setValue: setExpanded } = useBoolean(false);

  const handleAdd = () => fields.push({ ...getNewDivision() });

  const handleRemove = (index: number) => () => {
    fields.remove(index);
  };

  return (
    <ArrayWrapper>
      {fields.map((_, index) => (
        <ArrayItemWrapper
          // eslint-disable-next-line no-underscore-dangle
          key={fields.value[index]._id || fields.value[index].tempId}
        >
          <Accordion onChange={(__, v) => setExpanded(v)}>
            <StyledSummary>
              <Typography variant="body1"># Division {index + 1}</Typography>
            </StyledSummary>
            <StyledDetails>
              {isExpanded && (
                <>
                  <div>
                    <SelectInput
                      label="Division icon"
                      source={`${source}[${index}].icon`}
                      choices={iconChoices}
                      resettable
                    />
                    <ReferenceInput
                      label="Title"
                      source={`${source}[${index}].title`}
                      reference="translation"
                      filterToQuery={(search: string) => ({
                        [TranslationField.KEY]: search,
                      })}
                    >
                      <AutocompleteInput optionText="key" optionValue="id" />
                    </ReferenceInput>
                  </div>
                  <DivisionRequirementInput
                    source={`${source}[${index}].requirement`}
                  />
                  <BooleanInput
                    source={`${source}[${index}].cascadingRequirements`}
                    label="Cascading Requirements (Cards can fill lower rarity requirements if applicable)"
                  />
                  <BooleanInput
                    source={`${source}[${index}].disableRarityModifier`}
                    label="Disable rarity modifier"
                  />
                  <div>
                    <Typography>Override</Typography>
                    <FormationsInput
                      source={`${source}[${index}].formations`}
                    />
                    <StackingPenaltiesInput
                      source={`${source}[${index}].stackingPenaltiesConfig`}
                    />
                    <SafeNumberInput
                      source={`${source}[${index}].pointCap`}
                      label="Point Cap"
                      min={0}
                    />
                  </div>
                  <BracketConfigContainer>
                    <PrizePoolInput
                      source={`${source}[${index}].positionPrizePool`}
                    />
                    <Button
                      startIcon={<RemoveCircleOutline />}
                      label={`Remove division ${index + 1}`}
                      onClick={handleRemove(index)}
                      className={classes.removeButton}
                    />
                  </BracketConfigContainer>
                </>
              )}
            </StyledDetails>
          </Accordion>
        </ArrayItemWrapper>
      ))}
      <AddButtonContainer>
        <Button
          startIcon={<AddCircleOutline />}
          label="Add division"
          onClick={handleAdd}
          className={classes.addButton}
        />
      </AddButtonContainer>
    </ArrayWrapper>
  );
};
