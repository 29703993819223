import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import { HourglassEmpty } from '@material-ui/icons';
import React, { useCallback, useState } from 'react';
import { Button, useNotify } from 'react-admin';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { IUserCohort } from '~business/usercohort/types';

import { CohortList } from '../CohortList';
import { useCohorts } from '../cohort.hook';

const Panel = styled.div`
  flex-shrink: 1;
  padding: 0.3rem 1rem;
  overflow: auto;
`;
const WaitWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 1rem 0 1.5rem;
`;
const ButtonRow = styled.div`
  display: flex;
  justify-content: center;
  margin: 1rem;
`;

interface IProps {
  onSubmit: (cohortId: IUserCohort['id']) => void;
}

export const CohortSelector = ({ onSubmit }: IProps) => {
  const notify = useNotify();
  const { loading, cohorts } = useCohorts();

  const [selectedCohortId, setSelectedCohortId] = useState<IUserCohort['id']>();
  const [submitting, setSubmitting] = useState<boolean>(false);

  const selectCohort = useCallback(async () => {
    if (!selectedCohortId) {
      notify('No cohort selected', { type: 'info' });
      return;
    }
    setSubmitting(true);
    await onSubmit(selectedCohortId);
    setSubmitting(false);
  }, [selectedCohortId, notify, onSubmit]);

  const isSelected = (id: IUserCohort['id']) => selectedCohortId === id;

  return (
    <>
      <DialogTitle>Select cohort</DialogTitle>
      <Panel>
        {loading && (
          <WaitWrapper>
            <HourglassEmpty />
          </WaitWrapper>
        )}
        {!loading && (!cohorts || cohorts.length === 0) && (
          <Typography variant="body2">
            No existing cohort. <Link to="/usercohort">Go to cohorts list</Link>
          </Typography>
        )}
        {!loading && cohorts && (
          <CohortList
            cohorts={cohorts}
            onSelect={setSelectedCohortId}
            isSelected={isSelected}
          />
        )}
      </Panel>

      <ButtonRow>
        <Button
          size="large"
          label="Submit"
          onClick={selectedCohortId ? selectCohort : undefined}
          disabled={!selectedCohortId || submitting}
        />
      </ButtonRow>
    </>
  );
};
