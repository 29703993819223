import { makeStyles, Theme } from '@material-ui/core/styles';
import React from 'react';
import { ChipField, Record as RARecord } from 'react-admin';
import { Payment } from 'ultimate-league-common';

const MapStatusToColor: Record<
  Payment.Sale.SaleStatus,
  (theme: Theme) => string
> = {
  [Payment.Sale.SaleStatus.CREATED]: (theme) => theme.palette.info.light,
  [Payment.Sale.SaleStatus.ONGOING]: (theme) => theme.palette.info.main,
  [Payment.Sale.SaleStatus.SUCCESS]: (theme) => theme.palette.success.main,
  [Payment.Sale.SaleStatus.REFUNDED]: (theme) => theme.palette.info.dark,
  [Payment.Sale.SaleStatus.ERROR]: (theme) => theme.palette.error.main,
  [Payment.Sale.SaleStatus.CANCELLED]: (theme) => theme.palette.warning.main,
  [Payment.Sale.SaleStatus.STOPPED]: (theme) => theme.palette.info.dark,
};

const useStyles = makeStyles<Theme, { status: string }>((theme) => ({
  chip: { backgroundColor: ({ status }) => MapStatusToColor[status]?.(theme) },
}));

export const SaleStatusField = ({
  record,
  ...props
}: {
  record?: RARecord;
  label?: string;
}) => {
  const status = record?.status;
  const classes = useStyles({ status });

  return (
    <ChipField
      source="status"
      className={classes.chip}
      record={record}
      {...props}
    />
  );
};
