import get from 'lodash/get';
import React from 'react';
import { BooleanField, ImageField, Record } from 'react-admin';
import { getPublicURL, PublicParams } from '~technical/storage';

interface IProps {
  source: string;
  assetParams: Pick<PublicParams, 'storageType' | 'format'>;
  label?: string;
  record?: Record;
}

export const AssetField = ({
  source,
  record,
  assetParams,
  ...props
}: IProps) => {
  if (!record) {
    return null;
  }

  const asset = get(record, source);

  return asset?.medium ? (
    <ImageField
      source={source}
      record={{
        ...record,
        [source]: getPublicURL({
          ...assetParams,
          id: String(record.id),
        }),
      }}
      {...props}
    />
  ) : (
    <BooleanField
      source={source}
      record={{ ...record, [source]: false }}
      {...props}
    />
  );
};
