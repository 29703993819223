import { User } from 'ultimate-league-common';

interface IPayload {
  person?: {
    id: string;
    username?: string;
  };
}

interface IConfig {
  payload: IPayload;
}

declare global {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  interface Window {
    Rollbar: {
      error: (e: Error) => undefined;
      configure: (c: IConfig) => undefined;
    };
  }
}

// todo For now, we don't track current user. Could be worth to do it on backoffice
export const setUser = (user: User.IUser) =>
  window.Rollbar.configure({
    payload: {
      person: user,
    },
  });

export const error = (e: Error) => {
  window.Rollbar.error(e);
};
