import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { AddCircleOutline, RemoveCircleOutline } from '@material-ui/icons';
import React from 'react';
import {
  ArrayInput,
  AutocompleteInput,
  Button,
  minValue,
  ReferenceField,
  ReferenceInput,
  required,
  SelectInput,
  SimpleFormIteratorProps,
  TextField,
} from 'react-admin';
import styled from 'styled-components';
import { enumToArray, Pack } from 'ultimate-league-common';
import { FilterField } from '~technical/filters/filter';
import { SafeNumberInput } from '~ui/SafeNumberInput';

import { SPORT_CONTEXT } from '../../../../constant';

const dropChoices = enumToArray(Pack.LootTable.DropType).map((dropType) => ({
  id: dropType,
  name: dropType,
}));

const StyledSummary = styled(AccordionSummary)`
  display: flex;
  background: lightsalmon;
  > div {
    justify-content: space-between;
  }
`;

const StyledDetails = styled(AccordionDetails)`
  border: 5px solid lightsalmon;
  display: flex;
  flex-direction: column;
`;

const useStyles = makeStyles((theme) => ({
  removeButton: {
    color: theme.palette.error.main,
    '&:hover': {
      background: 'none',
      color: 'darkred',
    },
    margin: 'auto',
  },
  addButton: {
    marginTop: '12px',
    '&:hover': {
      background: 'none',
      color: 'darkblue',
    },
  },
}));

function getPercent(value: number, total: number) {
  return `${((value * 100) / total).toFixed(2)}%`;
}

interface IDropTypeTitleProps {
  drop: {
    dropType: Pack.LootTable.DropType;
    quantity: number;
    filter?: string;
  };
}

const DropTypeTitle = ({ drop }: IDropTypeTitleProps) => {
  switch (drop.dropType) {
    case Pack.LootTable.DropType.NFT_CARD:
      return (
        <Typography variant="body2">
          {drop.quantity} NFT (
          <ReferenceField
            record={{ id: drop.filter! }}
            reference="Filter"
            source="id"
            link={false}
          >
            <TextField source="reference" />
          </ReferenceField>
          )
        </Typography>
      );
    case Pack.LootTable.DropType.UNA_TOKEN:
      return <Typography variant="body2">{drop.quantity} CHAMPs</Typography>;
    case Pack.LootTable.DropType.ULC_TOKEN:
      return <Typography variant="body2">{drop.quantity} MGCs</Typography>;
    default:
      return <Typography variant="body2">INVALID</Typography>;
  }
};

const DropIterator = ({ fields, source }: SimpleFormIteratorProps) => {
  const classes = useStyles();

  const handleAdd = () =>
    fields?.push({
      dropType: Pack.LootTable.DropType.ULC_TOKEN,
      weight: 1,
      quantity: 0,
    });

  const handleRemove = (index: number) => () => {
    fields?.remove(index);
  };

  const totalWeight = fields?.value.reduce(
    (total, { weight }) => total + weight,
    0
  );

  return (
    <Box display="flex" flexDirection="column">
      {fields?.value.map((drop, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <Accordion key={`${index}`}>
          <StyledSummary>
            <DropTypeTitle drop={drop} />
            <Typography>{getPercent(drop.weight, totalWeight)}</Typography>
          </StyledSummary>
          <StyledDetails>
            <Box display="flex" flexDirection="column">
              <SelectInput
                validate={required()}
                label="Type"
                source={`${source}[${index}].dropType`}
                choices={dropChoices}
              />
              <SafeNumberInput
                validate={[required(), minValue(1)]}
                source={`${source}[${index}].weight`}
                label="Weight"
              />
              <SafeNumberInput
                validate={[required(), minValue(1)]}
                source={`${source}[${index}].quantity`}
                label="Quantity"
              />
              {drop.dropType === Pack.LootTable.DropType.NFT_CARD && (
                <ReferenceInput
                  label="Filter"
                  source={`${source}[${index}].filter`}
                  reference="Filter"
                  filterToQuery={(search: string) => ({
                    reference: search,
                    [FilterField.SPORT]: SPORT_CONTEXT,
                  })}
                >
                  <AutocompleteInput optionText="reference" optionValue="id" />
                </ReferenceInput>
              )}
              <Button
                startIcon={<RemoveCircleOutline />}
                label={`Remove ${drop.dropType}`}
                onClick={handleRemove(index)}
                className={classes.removeButton}
              />
            </Box>
          </StyledDetails>
        </Accordion>
      ))}
      <Button
        startIcon={<AddCircleOutline />}
        label="Add drop"
        onClick={handleAdd}
        className={classes.addButton}
      />
    </Box>
  );
};

export const Drops = ({ source }: { source: string }) => (
  <ArrayInput source={`${source}.drops`} label="">
    {
      // @ts-ignore
      <DropIterator />
    }
  </ArrayInput>
);
