import { processGenericFilter, Value } from './generic';

export enum PackConfigField {
  NAME = 'name',
  IS_ARCHIVE = 'isArchive',
  IS_MONETIZATION_ENABLED = 'isMonetizationEnabled',
  IS_GIVEAWAY = 'isGiveaway',
}

export function processPackConfigFilter(
  field: PackConfigField | string,
  value: Value
) {
  switch (field) {
    case PackConfigField.NAME:
      return { name: { $regex: value, $options: 'i' } };

    case PackConfigField.IS_GIVEAWAY:
      return { 'monetization.isGiveaway': true };

    default:
      return processGenericFilter(field, value);
  }
}
