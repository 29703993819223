import { processGenericFilter } from './generic';

export enum AuctionField {
  CARD_ID = 'card_id',
  NFT = 'nft',
  STATUS = 'status',
}

export enum AuctionStatus {
  UPCOMING = 'Upcoming',
  LIVE = 'Live',
  EXPIRED = 'Expired',
}

export function processAuctionFilter(field: string, value: any) {
  switch (field) {
    case AuctionField.CARD_ID:
      return { 'card._id': value };
    case AuctionField.NFT:
      return { 'card.mint.nft': value };
    case AuctionField.STATUS:
      switch (value) {
        case AuctionStatus.UPCOMING:
          return { startsAt: { $gt: new Date() } };
        case AuctionStatus.LIVE:
          return {
            startsAt: { $lte: new Date() },
            expiresAt: { $gte: new Date() },
          };
        case AuctionStatus.EXPIRED:
          return { expiresAt: { $lt: new Date() } };
        default:
          throw new Error('Invalid auction status');
      }
    default:
      return processGenericFilter(field, value);
  }
}
