/* eslint-disable react/no-unused-prop-types */
import { Chip } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React from 'react';

const useQuickFilterStyles = makeStyles(() => ({
  chip: {
    marginBottom: 8,
  },
}));

export const QuickFilter = ({
  label,
}: {
  label: string;
  source: string;
  defaultValue: unknown;
}) => {
  const classes = useQuickFilterStyles();
  return <Chip className={classes.chip} label={label} />;
};
