import { LockTwoTone } from '@material-ui/icons';
import React, { useCallback } from 'react';
import { Button, Confirm, useNotify } from 'react-admin';
import { useBoolean } from 'usehooks-ts';
import { fetchApi, prepareSubmitRequestInit } from '~technical/api';

export const DisconnectEverywhereButton = ({
  userId,
  userName,
  disabled,
}: {
  userId?: string;
  userName?: string;
  disabled?: boolean;
}) => {
  const notify = useNotify();

  const {
    value: mustConfirm,
    setTrue: setMustConfirmTrue,
    setFalse: setMustConfirmFalse,
  } = useBoolean(false);

  const handleDisconnectEverywhere = useCallback(async () => {
    setMustConfirmFalse();

    try {
      await fetchApi(
        `/backoffice/user/${userId}/disconnect`,
        prepareSubmitRequestInit({}, 'PATCH')
      );

      notify('User has been disconnected', 'info');
    } catch (e: any) {
      notify(`User has not been disconnected (${e.message})`, 'error');
    }
  }, [userId, notify, setMustConfirmFalse]);

  if (disabled) {
    return null;
  }

  return (
    <>
      <Button
        color="primary"
        variant="outlined"
        label="Disconnect everywhere"
        onClick={setMustConfirmTrue}
        startIcon={<LockTwoTone />}
      />

      <Confirm
        isOpen={mustConfirm}
        title="Disconnect from any device"
        content={`Are you sure you want to disconnect user ${userName}?`}
        onConfirm={handleDisconnectEverywhere}
        onClose={setMustConfirmFalse}
      />
    </>
  );
};
