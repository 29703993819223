import { SoccerData } from 'ultimate-league-common';

import { SPORT_CONTEXT } from '../../constant';

export const athletePositionChoices = SoccerData.Athlete.MAP_SPORT_TO_POSITIONS[
  SPORT_CONTEXT
].map((position) => ({
  id: position,
  name: position,
}));

export const tierChoices = [1, 2, 3].map((tier) => ({
  id: tier,
  name: String(tier),
}));
