import React from 'react';
import {
  BooleanInput,
  Edit,
  EditProps,
  SimpleForm,
  TextInput,
  usePermissions,
} from 'react-admin';
import { User } from 'ultimate-league-common';
import { PermissionedToolbar } from '~business/common/components/PermissionedToolbar';

import { validateForm } from './validateForm';

export function UserCohortEdit(props: EditProps) {
  const { permissions } = usePermissions();
  const hasPermission = permissions === User.Role.MODERATOR;
  return (
    <Edit {...props}>
      <SimpleForm
        toolbar={
          <PermissionedToolbar
            canSave={hasPermission}
            canDelete={hasPermission}
          />
        }
        validate={validateForm}
      >
        <TextInput source="name" />
        <BooleanInput source="publicInvite" />
      </SimpleForm>
    </Edit>
  );
}
