import { Typography } from '@material-ui/core';
import React from 'react';
import { ArrayInput } from 'react-admin';
import styled from 'styled-components';
import { FeatureFlags } from 'ultimate-league-common';
import { useFlag } from '~technical/feature-flags';

import { GameModeInput } from './GameModeInput';
import { TournamentIterator } from './tournaments/TournamentIterator';

const GroupArea = styled.div`
  margin: 0 0 1rem;
  padding: 0.6em 1em 0;
  border: 1px solid #d9d9d9;

  h6 {
    margin: 0.4em 0 0.5em;
  }
`;

export const GameModes = () => {
  const tournamentsEnabled = useFlag(FeatureFlags.Features.TOURNAMENTS);

  return (
    <>
      <GroupArea>
        <Typography variant="h6">League Settings</Typography>
        <GameModeInput source="gameModes.leagues" />
      </GroupArea>
      {tournamentsEnabled && (
        <GroupArea>
          <Typography variant="h6">Tournament Settings</Typography>
          <ArrayInput source="gameModes.tournaments" label="">
            {
              // @ts-ignore
              <TournamentIterator />
            }
          </ArrayInput>
        </GroupArea>
      )}
    </>
  );
};
