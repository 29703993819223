import React, { KeyboardEventHandler } from 'react';
import {
  AutocompleteInput,
  BooleanInput,
  Filter,
  ReferenceInput,
  SearchInput,
  usePermissions,
} from 'react-admin';
import { User } from 'ultimate-league-common';

export const BatchFilter = (props: any) => {
  const { permissions } = usePermissions();
  const handleKeyPress: KeyboardEventHandler = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  };

  return (
    // @ts-ignore
    <Filter {...props} onKeyPress={handleKeyPress}>
      <SearchInput source="label" />

      <ReferenceInput source="season" reference="nftcardseason">
        <AutocompleteInput optionText="title" optionValue="id" />
      </ReferenceInput>

      {permissions === User.Role.MODERATOR && (
        <ReferenceInput source="specialEdition" reference="nftspecialedition">
          <AutocompleteInput optionText="name" optionValue="id" />
        </ReferenceInput>
      )}

      <BooleanInput label="Published" source="isLive" />
    </Filter>
  );
};
