/* eslint-disable no-underscore-dangle */
import { makeStyles } from '@material-ui/styles';
import moment from 'moment';
import React from 'react';
import {
  ArrayField,
  DateTimeInput,
  DeleteButton,
  Edit,
  EditProps,
  FunctionField,
  Link,
  minValue,
  NumberInput,
  SaveButton,
  SimpleForm,
  SingleFieldList,
  Toolbar,
  usePermissions,
  useRecordContext,
} from 'react-admin';
import { Blockchain, Payment, t, User } from 'ultimate-league-common';
import { SafeNumberInput } from '~ui/SafeNumberInput';

const useStyles = makeStyles({
  inputsContainer: {
    display: 'flex',
    flexDirection: 'column',
    '& > *': {
      width: '400px',
    },
  },
  cardsList: {
    display: 'flex',
    flexDirection: 'column',
    '& > *': { margin: '5px' },
  },
});

const AuctionToolbar = ({ ...props }: { permissions: User.Role } & any) => {
  const record = useRecordContext();
  return (
    <Toolbar {...props}>
      <SaveButton
        disabled={
          (props.permissions === User.Role.MODERATOR && record.isProcessed) ||
          props.pristine
        }
      />
      <DeleteButton
        mutationMode="pessimistic"
        disabled={props.permissions !== User.Role.MODERATOR}
      />
    </Toolbar>
  );
};
const AuctionForm = (props: any) => {
  const classes = useStyles();
  const record = useRecordContext();

  const isLive =
    record && moment().isBetween(record.startsAt, record.expiresAt);

  return (
    <SimpleForm {...props}>
      <ArrayField source="cards" className={classes.cardsList}>
        <SingleFieldList>
          <FunctionField
            label="Card"
            render={(card: any) => {
              const firstName =
                card.athlete.shortFirstName ?? card.athlete.firstName;
              const lastName =
                card.athlete.shortLastName ?? card.athlete.lastName;
              return (
                <Link
                  to={{ pathname: `/nftcard/${card._id}/show` }}
                >{`${firstName} ${lastName} (${t(
                  Blockchain.NFTCard.rarityI18NKeys[card.level]
                )})`}</Link>
              );
            }}
          />
        </SingleFieldList>
      </ArrayField>
      <div className={classes.inputsContainer}>
        <DateTimeInput source="announcedAt" required />
        <DateTimeInput source="startsAt" required />
        <DateTimeInput source="expiresAt" required />
        <SafeNumberInput
          label={`Starting Amount (${Payment.ActiveCurrency})`}
          source="startingAmount.fiat"
          validate={minValue(1)}
          required
          disabled={isLive}
        />
        <NumberInput
          label="Expiry increment minutes (empty = disabled)"
          source="expiryIncrementMinutes"
          validate={minValue(1)}
        />
      </div>
    </SimpleForm>
  );
};

export const AuctionEdit = (props: EditProps) => {
  const { permissions } = usePermissions();
  return (
    <Edit {...props} mutationMode="pessimistic">
      {/* @ts-ignore */}
      <AuctionForm toolbar={<AuctionToolbar permissions={permissions} />} />
    </Edit>
  );
};
