import * as React from 'react';
import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useState,
} from 'react';
import type { GameWeek } from 'ultimate-league-common';
import { buildQuery, fetchApi } from '~technical/api';

import { SPORT_CONTEXT } from '../constant';

async function fetchLiveGameWeek(): Promise<GameWeek.IGameWeek> {
  const res = await fetchApi(
    `/gameweek/live?${buildQuery({ sport: SPORT_CONTEXT })}`
  );
  return res.json();
}

function willThrow() {
  throw new Error('ConfigContext is not initialized');
}

interface IConfigContext {
  liveGameWeek?: GameWeek.IGameWeek;
  fetch: () => void;
}

const configContext = createContext<IConfigContext>({
  fetch: willThrow,
});

export const useConfig = () => useContext(configContext);

export const ConfigProvider = ({ children }: PropsWithChildren<{}>) => {
  const [liveGameWeek, setLiveGameWeek] =
    useState<IConfigContext['liveGameWeek']>();

  const fetch = useCallback(async () => {
    const [sLiveGameWeek] = await Promise.all([fetchLiveGameWeek()]);

    setLiveGameWeek(sLiveGameWeek);
  }, [setLiveGameWeek]);

  return (
    <configContext.Provider value={{ liveGameWeek, fetch }}>
      {children}
    </configContext.Provider>
  );
};
