import * as moment from 'moment';
import React from 'react';
import { TextField } from 'react-admin';

interface IProps {
  sourceStart?: string;
  sourceEnd?: string;
  source: string;
  record?: any;
  addLabel?: boolean;
}

export const DurationField = ({
  record,
  sourceStart: _sourceStart,
  sourceEnd: _sourceEnd,
  ...props
}: IProps) => {
  const sourceStart = _sourceStart || 'createdAt';
  const sourceEnd = _sourceEnd || 'endAt';
  const diff = moment(record[sourceEnd]).diff(moment(record[sourceStart]));

  const duration =
    record && record[sourceStart] && record[sourceEnd]
      ? `${moment.duration(diff).humanize()} (${diff.toString()}ms)`
      : '';

  return (
    <TextField record={{ ...record, [props.source]: duration }} {...props} />
  );
};
