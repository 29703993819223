import { Typography } from '@material-ui/core';
import React, { FC } from 'react';

import { hasCustomTemplate } from '../common';
import { ISavedNftBatch } from '../types';
import { Label, ShowRow, Value } from './styles';

interface IProps {
  record?: ISavedNftBatch;
}

const CustomTemplatesSummary: FC<IProps> = ({ record }: IProps) => (
  <ShowRow>
    <Typography variant="body2" component="div">
      <Label>Has custom template?</Label>
      <Value>{hasCustomTemplate(record) ? 'Yes' : 'No'}</Value>
    </Typography>
  </ShowRow>
);

export default CustomTemplatesSummary;
