import React from 'react';
import { Create, CreateProps } from 'react-admin';

import { NftSpecialEditionForm } from './NftSpecialEditionForm';

export const NftSpecialEditionCreate = (props: CreateProps) => (
  <Create {...props}>
    {/* @ts-ignore */}
    <NftSpecialEditionForm />
  </Create>
);
