import { makeStyles, Typography } from '@material-ui/core';
import { AddCircleOutline, RemoveCircleOutline } from '@material-ui/icons';
import React from 'react';
import {
  AutocompleteInput,
  Button,
  minValue,
  ReferenceInput,
  required,
  SelectInput,
} from 'react-admin';
import { FieldArrayRenderProps } from 'react-final-form-arrays';
import styled from 'styled-components';
import { enumToArray, GameView, Pack } from 'ultimate-league-common';
import { SafeNumberInput } from '~ui/SafeNumberInput';

interface IBracket {
  type: GameView.BracketType;
  from: number;
  to: number;
  packConfig?: Pack.IPackConfig['id'];
}

const ArrayWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const ArrayItemWrapper = styled.div``;

const PackConfigReferenceContainer = styled.div``;

const AddButtonContainer = styled.div``;

const BracketConfigContainer = styled.div`
  display: flex;

  & > *:not(:last-child) {
    margin-right: 20px;
  }
`;

const PackConfigReference = styled(ReferenceInput)`
  div {
    width: 300px;
  }
`;

const useStyles = makeStyles((theme) => ({
  removeButton: {
    color: theme.palette.error.main,
    '&:hover': {
      background: 'none',
      color: 'darkred',
    },
    position: 'relative',
    height: 32,
    top: 16,
    left: -16,
  },
  addButton: {
    '&:hover': {
      background: 'none',
      color: 'darkblue',
    },
  },
}));

const bracketTypeChoices = enumToArray(GameView.BracketType).map(
  (bracketType) => ({
    id: bracketType,
    name: bracketType,
  })
);

export const BracketIterator = ({
  fields,
}: FieldArrayRenderProps<IBracket, HTMLDivElement>) => {
  const classes = useStyles();

  const onFilterPackConfigs = (name: string) => ({
    name,
  });

  const handleAdd = () => {
    const from = (fields.value[fields.value.length - 1]?.to || -1) + 1;
    fields.push({
      type: GameView.BracketType.ABSOLUTE,
      from,
      to: from + 1,
    });
  };

  const handleRemove = (index: number) => () => {
    fields.remove(index);
  };

  return (
    <ArrayWrapper>
      {fields.map((bracket: any, index: number) => (
        <ArrayItemWrapper key={bracket}>
          <Typography variant="body1">Bracket {index + 1}</Typography>
          <SelectInput
            label="Type"
            source={`${bracket}.type`}
            choices={bracketTypeChoices}
          />
          <BracketConfigContainer>
            <SafeNumberInput
              label="From (inclusive)"
              source={`${bracket}.from`}
              validate={[minValue(0), required()]}
              min={0}
            />
            <SafeNumberInput
              label="To (inclusive)"
              source={`${bracket}.to`}
              validate={[minValue(0), required()]}
              min={0}
            />
            <PackConfigReferenceContainer>
              <PackConfigReference
                label="Pack Config"
                source={`${bracket}.packConfig`}
                reference="packConfig"
                filterToQuery={onFilterPackConfigs}
                validate={[required()]}
              >
                <AutocompleteInput optionText="name" optionValue="id" />
              </PackConfigReference>
            </PackConfigReferenceContainer>
            <Button
              startIcon={<RemoveCircleOutline />}
              className={classes.removeButton}
              onClick={handleRemove(index)}
            />
          </BracketConfigContainer>
        </ArrayItemWrapper>
      ))}
      <AddButtonContainer>
        <Button
          startIcon={<AddCircleOutline />}
          label="Add bracket"
          onClick={handleAdd}
          className={classes.addButton}
        />
      </AddButtonContainer>
    </ArrayWrapper>
  );
};
