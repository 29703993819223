import { AddCircleOutline as AddCircleOutlineIcon } from '@material-ui/icons';
import React from 'react';
import {
  ArrayInput,
  BooleanInput,
  Button,
  required,
  SelectInput,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
} from 'react-admin';
import { FieldArrayRenderProps } from 'react-final-form-arrays';
import styled, { css } from 'styled-components';
import { SoccerData } from 'ultimate-league-common';
import { athletePositionChoices } from '~business/athlete/common';
import { SafeNumberInput } from '~ui/SafeNumberInput';

import { SPORT_CONTEXT } from '../../constant';
import { Notice } from './FormationNotice';

const defaultTeamCountValue = {
  count: 1,
  required: true,
};

const initialValues = {
  [SoccerData.Sport.SOCCER]: {
    team: [
      {
        ...defaultTeamCountValue,
        position: SoccerData.Athlete.SoccerPosition.GOAL,
      },
      {
        ...defaultTeamCountValue,
        position: SoccerData.Athlete.SoccerPosition.DEFENDER,
      },
      {
        ...defaultTeamCountValue,
        position: SoccerData.Athlete.SoccerPosition.MIDDLE,
      },
      {
        ...defaultTeamCountValue,
        position: SoccerData.Athlete.SoccerPosition.STRIKER,
      },
    ],
    sport: SoccerData.Sport.SOCCER,
  },
  [SoccerData.Sport.BASKETBALL]: {
    team: [
      {
        ...defaultTeamCountValue,
        position: SoccerData.Athlete.BasketballPosition.CENTER,
      },
      {
        ...defaultTeamCountValue,
        position: SoccerData.Athlete.BasketballPosition.FORWARD,
      },
      {
        ...defaultTeamCountValue,
        position: SoccerData.Athlete.BasketballPosition.GUARD,
      },
    ],
    sport: SoccerData.Sport.BASKETBALL,
  },
};

const StyledArrayInput = styled(ArrayInput)`
  ${(props) =>
    props.disabled &&
    css`
      opacity: 0.6;
      pointer-events: none;
    `}
`;

const TeamCountBlock = styled.div`
  margin: 2rem 1rem 1rem;
  padding: 0.8rem 2rem 1rem;
  border: 3px solid #dbe6f7;

  & section {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;

    & > * {
      margin: 0 1.5rem;
    }
  }
`;

const Count = styled(SafeNumberInput)`
  min-width: 120px;
`;

const Position = styled(SelectInput)`
  min-width: 260px;
`;

interface ITeamCountIteratorProps {
  forcedRequired?: boolean;
  allowEmptyPosition: boolean;
  fields: FieldArrayRenderProps<any, HTMLUListElement>['fields'];
}

const TeamCountIterator = ({
  forcedRequired,
  allowEmptyPosition,
  fields,
  ...props
}: ITeamCountIteratorProps) => {
  const add = () => {
    fields.push(defaultTeamCountValue);
  };

  return (
    <TeamCountBlock>
      <SimpleFormIterator {...props} disableAdd>
        <Count source="count" label="Count" min={0} />

        <Position
          source="position"
          label="Position"
          choices={athletePositionChoices}
          allowEmpty={allowEmptyPosition}
          validate={allowEmptyPosition ? [] : [required()]}
        />

        <BooleanInput
          label="Required"
          source="required"
          disabled={forcedRequired}
          defaultValue={forcedRequired ? true : undefined}
        />
      </SimpleFormIterator>
      <Button startIcon={<AddCircleOutlineIcon />} label="Add" onClick={add} />
    </TeamCountBlock>
  );
};

export const FormationForm = ({ isEdit, ...props }: any) => (
  <>
    <Notice />
    <SimpleForm {...props} initialValues={initialValues[SPORT_CONTEXT]}>
      <TextInput source="title" />

      <BooleanInput source="default" label="Default formation" />

      <StyledArrayInput source="team" fullWidth disableAdd disabled={isEdit}>
        {
          /* @ts-ignore */
          <TeamCountIterator forcedRequired allowEmptyPosition={false} />
        }
      </StyledArrayInput>

      <StyledArrayInput
        source="substitutes"
        fullWidth
        disableAdd
        disabled={isEdit}
      >
        {
          /* @ts-ignore */
          <TeamCountIterator allowEmptyPosition />
        }
      </StyledArrayInput>
    </SimpleForm>
  </>
);
