import React, { useMemo } from 'react';
import { ReferenceField, TextField } from 'react-admin';
import { IBatchIngredientError } from 'ultimate-league-common';

interface IBatchErrorDetailProps {
  error: IBatchIngredientError;
}

export const BatchErrorDetail = ({ error }: IBatchErrorDetailProps) => {
  const [id, resource] = useMemo<[string, string]>(() => {
    if (error.teamId) {
      return [error.teamId, 'team'];
    }
    if (error.countryId) {
      return [error.countryId, 'country'];
    }
    if (error.competitionId) {
      return [error.competitionId, 'country'];
    }
    return ['', ''];
  }, [error]);

  if (!id) {
    return <li>{error.reason}</li>;
  }

  return (
    <li>
      <span>{resource} </span>
      <ReferenceField reference={resource} record={{ id }} source="id">
        <TextField source="name" />
      </ReferenceField>
      {error.reason}
    </li>
  );
};
