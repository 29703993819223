import Jimp from 'jimp';
import { SoccerData, Storage } from 'ultimate-league-common';

const { GOOGLE_PUBLIC_BUCKET_NAME } = process.env;

if (!GOOGLE_PUBLIC_BUCKET_NAME) {
  throw new Error('Missing env GOOGLE_PUBLIC_BUCKET_NAME');
}

const GOOGLE_PUBLIC_BUCKET_URL = `https://storage.googleapis.com/${GOOGLE_PUBLIC_BUCKET_NAME}`;

export interface IAssetValue {
  small?: string;
  medium?:
    | {
        rawFile: File;
      }
    | string;
}

interface ITeamParams {
  storageType: Storage.StorageType.PUBLIC_TEAM_LOGO;
  id: SoccerData.Team.ITeam['id'];
  format: Storage.AssetFormat;
}

interface ICompetitionParams {
  storageType: Storage.StorageType.PUBLIC_COMPETITION_ASSET;
  id: SoccerData.Competition.ICompetition['id'];
  format: Storage.AssetFormat;
}

interface IAthleteMainParams {
  storageType: Storage.StorageType.PUBLIC_ATHLETE_MAIN;
  id: SoccerData.Athlete.IAthlete['id'];
  format: Storage.AssetFormat;
}

export type PublicParams =
  | ITeamParams
  | IAthleteMainParams
  | ICompetitionParams;

export function getPublicURL(params: PublicParams) {
  switch (params.storageType) {
    case Storage.StorageType.PUBLIC_TEAM_LOGO:
      return `${GOOGLE_PUBLIC_BUCKET_URL}/team/${params.id}-${params.format}.png`;

    case Storage.StorageType.PUBLIC_ATHLETE_MAIN:
      return `${GOOGLE_PUBLIC_BUCKET_URL}/athlete/${params.id}-main-${params.format}.png`;

    case Storage.StorageType.PUBLIC_COMPETITION_ASSET:
      return `${GOOGLE_PUBLIC_BUCKET_URL}/competition/${params.id}-${params.format}.png`;

    default:
      throw new Error('Invalid storage type');
  }
}

export function convertAsset(file: File) {
  return new Promise<Buffer>((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = async () => {
      const img = await Jimp.read(reader.result as any);
      resolve(
        img.quality(70).resize(32, Jimp.AUTO).getBufferAsync(Jimp.MIME_PNG)
      );
    };

    reader.onerror = reject;
    reader.readAsArrayBuffer(file);
  });
}
