import { Blockchain } from 'ultimate-league-common';
import { fetchApi } from '~technical/api';

import { IDraftCard } from './interfaces';

export const SCAN_URL: Record<Blockchain.Blockchain, string | undefined> = {
  polygon: process.env.POLYGON_SCAN_URL,
  bsc: process.env.BSC_SCAN_URL,
  chiliz: process.env.CHILIZ_SCAN_URL,
  base: process.env.BASE_SCAN_URL,
};

export function mintNFT(nftId: IDraftCard['id']) {
  return fetchApi(`/backoffice/nftcard/${nftId}/mint`, { method: 'POST' });
}
