import React from 'react';
import { ReferenceArrayInput, SelectArrayInput } from 'react-admin';

export const FormationsInput = ({ source }: { source: string }) => (
  <ReferenceArrayInput
    label="Available formations"
    source={source}
    reference="formation"
  >
    <SelectArrayInput
      style={{ maxWidth: 300, marginRight: 30 }}
      fullWidth
      optionText="title"
      optionValue="id"
    />
  </ReferenceArrayInput>
);
