import React, { KeyboardEventHandler, PropsWithChildren } from 'react';
import {
  Datagrid,
  EditButton,
  Filter,
  List,
  TextField,
  TextInput,
} from 'react-admin';
import { CountryField } from '~technical/filters/country';

import { Flag } from './Flag';
import { ICountry } from './types';

interface IFlagFieldProps {
  record: ICountry;
}

const CountryFilter = (props: PropsWithChildren<{}>) => {
  const handleKeyPress: KeyboardEventHandler = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  };

  return (
    /* @ts-ignore */
    <Filter {...props} onKeyPress={handleKeyPress}>
      <TextInput source={CountryField.JOKER} label="Country" />
    </Filter>
  );
};

const FlagField = ({ record }: IFlagFieldProps) => (
  <Flag countryCode={record.isoAlpha2Code} />
);

const Actions = (props: any) => (
  <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
    <EditButton {...props} />
  </div>
);

export const CountryList = (props: {}) => (
  <List {...props} exporter={false} filters={<CountryFilter />}>
    <Datagrid>
      <TextField source="name" />
      <TextField source="isoAlpha2Code" />
      {/* @ts-ignore */}
      <FlagField label="Flag" />
      <Actions />
    </Datagrid>
  </List>
);
