import { Typography } from '@material-ui/core';
import { Warning } from '@material-ui/icons';
import React from 'react';
import styled from 'styled-components';

const MessageWrapper = styled.div`
  margin: 1rem 1rem 0.5rem;
  padding: 1rem 1rem 0.5rem;
  color: #ff5000;
  border: 1px solid #ff5000;

  div {
    display: flex;
  }
`;

interface IProps {
  emails: string[];
}

export const UnmatchedEmails = ({ emails }: IProps) =>
  Array.isArray(emails) !== true || emails.length === 0 ? null : (
    <MessageWrapper>
      <div>
        <Warning />
        <Typography variant="subtitle1">
          &nbsp;Cohort created but these {emails.length} email addresses were
          not found in database:
        </Typography>
      </div>
      <ul>
        {emails.map((address) => (
          <li>
            <Typography variant="subtitle1">{address}</Typography>
          </li>
        ))}
      </ul>
    </MessageWrapper>
  );
