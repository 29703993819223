import { useCallback, useEffect, useState } from 'react';
import { useNotify, useRefresh } from 'react-admin';
import { fetchApi } from '~technical/api';

import { IPackConfig } from './types';

const useSupply = (packConfigId: IPackConfig['id'] | undefined) => {
  const notify = useNotify();
  const [hasExistingPacks, setHasExistingPacks] = useState<boolean>(true);
  const [count, setCount] = useState<{
    available: number;
    created: number;
  }>();

  const refresh = useCallback(async () => {
    if (!packConfigId) {
      return;
    }

    setCount(undefined);
    try {
      await Promise.all([
        fetchApi(`/backoffice/pack-config/${packConfigId}/pack-exist`).then(
          (res) => res.json()
        ),
        fetchApi(`/backoffice/pack-config/${packConfigId}/packs-count`).then(
          (res) => res.json()
        ),
      ]).then(
        ([{ hasExistingPacks: sHasExistingPacks }, { available, created }]) => {
          setHasExistingPacks(sHasExistingPacks);
          setCount({
            available,
            created,
          });
        }
      );
    } catch (e) {
      notify('Failed to fetch pack supply', { type: 'warning' });
      throw e;
    }
  }, [packConfigId, notify, setCount, setHasExistingPacks]);

  return {
    count,
    hasExistingPacks,
    refresh,
  };
};

export const useMonetization = ({
  isMonetizationEnabled,
  monetization,
  id: packConfigId,
}: IPackConfig) => {
  const hasInfiniteSupply = monetization?.hasInfiniteSupply;
  const {
    count,
    refresh: refreshSupply,
    hasExistingPacks,
  } = useSupply(packConfigId);
  const [canCreatePacks, setCanCreatePacks] = useState(false);
  const [canClearPacks, setCanClearPacks] = useState(false);
  const refresh = useRefresh();
  const notify = useNotify();

  const createPacks = useCallback(async () => {
    setCanCreatePacks(false);
    try {
      await fetchApi(
        `/backoffice/pack-config/${packConfigId}/create-monetized-packs${
          hasInfiniteSupply ? '?count=1' : ''
        }`
      );
      notify('Packs created', { type: 'success' });
    } catch (e: any) {
      notify(`Failed to create packs (${e.message})`, { type: 'warning' });
    }
    refresh();
    refreshSupply();
  }, [packConfigId, hasInfiniteSupply, notify, refresh, refreshSupply]);

  const clearPacks = useCallback(async () => {
    setCanClearPacks(false);
    try {
      await fetchApi(`/backoffice/pack-config/${packConfigId}/clear-packs`);
      notify('Packs cleared', { type: 'success' });
    } catch (e: any) {
      notify(`Failed to clear packs (${e.message})`, { type: 'warning' });
    }
    refresh();
    refreshSupply();
  }, [packConfigId, notify, refresh, refreshSupply]);

  useEffect(() => {
    if (!isMonetizationEnabled || !monetization || count === undefined) {
      return;
    }

    const hasMaxSupply = monetization.hasInfiniteSupply
      ? false
      : count.created >= monetization.supply;

    setCanCreatePacks(!hasMaxSupply);
    setCanClearPacks(count.available > 0);
  }, [
    count,
    monetization,
    isMonetizationEnabled,
    setCanCreatePacks,
    setCanClearPacks,
  ]);

  useEffect(() => {
    refreshSupply();
  }, [refreshSupply]);

  return {
    isMonetizationEnabled,
    count,
    hasExistingPacks,
    canClearPacks,
    canCreatePacks,
    clearPacks,
    createPacks,
  };
};
