import { Blockchain } from 'ultimate-league-common';

import { processGenericFilter, Value } from './generic';

const { ROOT_USER_ID } = process.env;

export enum CardField {
  SEARCH_SPORT = 'sport',
  SEARCH_LEVEL = 'level',
  SEARCH_ATHLETE = 'athlete',
  SEARCH_ATHLETE_POSITION = 'search_athletePosition',
  SEARCH_ATHLETE_AGE = 'search_athleteAge',
  SEARCH_FTUE_PRICE = 'search_ftuePrice',
  SEARCH_TEAM = 'search_team',
  SEARCH_SEASON = 'season',
  SEARCH_BATCH = 'batch',
  SEARCH_SPECIAL_EDITION = 'search_specialEdition',
  SEARCH_COMPETITION = 'search_competition',
  SEARCH_CONDITION = 'condition',
  SEARCH_TIER = 'search_athleteTier',
  AUCTIONABLE = 'auctionable',
}

function buildConditionFilter(
  value: Blockchain.NFTCard.ConditionBonus.ConditionBonus
) {
  if (value === Blockchain.NFTCard.ConditionBonus.ConditionBonus.GENESIS) {
    return processGenericFilter('metadata.specialEdition', 'Genesis');
  }

  if (value === Blockchain.NFTCard.ConditionBonus.ConditionBonus.STANDARD) {
    return {
      $or: [
        {
          level: Blockchain.NFTCard.RarityLevel.FUNGIBLE,
        },
        {
          tournamentEntries:
            Blockchain.NFTCard.ConditionBonus.getTournamentEntriesFromCondition(
              Blockchain.NFTCard.ConditionBonus.ConditionBonus.STANDARD
            ),
          level: { $ne: Blockchain.NFTCard.RarityLevel.FUNGIBLE },
        },
      ],
    };
  }

  return {
    tournamentEntries: {
      $gt: Blockchain.NFTCard.ConditionBonus.getTournamentEntriesFromCondition(
        Blockchain.NFTCard.ConditionBonus.PREVIOUS_CONDITION[value]
      ),
      $lte: Blockchain.NFTCard.ConditionBonus.getTournamentEntriesFromCondition(
        value
      ),
    },
  };
}

export function processCardFilter(field: CardField | string, value: Value) {
  switch (field) {
    case '_id':
      return processGenericFilter(field, value);
    case 'nft':
      return {
        'mint.nft': value,
      };
    case CardField.SEARCH_ATHLETE_AGE:
      return {
        'search.athleteAge': {
          $gte: value[0],
          $lte: value[1],
        },
      };
    case CardField.SEARCH_FTUE_PRICE:
      return {
        'search.ftuePrice': {
          $gte: value[0],
          $lte: value[1],
        },
        'search.ftueActive': { $exists: true },
        owner: ROOT_USER_ID,
        reserved: null,
      };
    case CardField.SEARCH_CONDITION:
      return {
        $or: (value as Blockchain.NFTCard.ConditionBonus.ConditionBonus[]).map(
          buildConditionFilter
        ),
      };
    case CardField.SEARCH_TEAM:
      return {
        $or: [
          {
            'search.club': value,
          },
          {
            'search.national': value,
          },
        ],
      };
    case CardField.AUCTIONABLE:
      return {
        owner: ROOT_USER_ID,
        reserved: null,
      };
    default:
      return processGenericFilter(field.replace('_', '.'), value);
  }
}
