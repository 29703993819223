import { Storage } from 'ultimate-league-common';
import { fetchApi, submitData } from '~technical/api';
import { dataProvider } from '~technical/dataProvider';

export function getUploadSource(source: string) {
  return source;
}

function uploadFile(
  fileOrBuffer: File | Buffer,
  post: Storage.BackofficeRequestUpload['response']['post']
) {
  const formData = new FormData();
  Object.keys(post.fields).forEach((field) => {
    formData.set(field, post.fields[field]);
  });

  if (fileOrBuffer instanceof Buffer) {
    formData.set('file', new Blob([fileOrBuffer]), 'temp');
  } else {
    formData.set('file', fileOrBuffer);
  }

  return fetch(post.url, {
    method: 'POST',
    mode: 'no-cors',
    body: formData,
  });
}

function requestUpload(
  data: Storage.BackofficeRequestUpload['body']
): Promise<Storage.BackofficeRequestUpload['response']> {
  return submitData('/backoffice/storage/requestUpload', data);
}

export async function uploadToStorage(
  requestUploadData: Storage.BackofficeRequestUpload['body'],
  fileOrBuffer: File | Buffer
) {
  const upload = await requestUpload(requestUploadData);
  await uploadFile(fileOrBuffer, upload.post);
  await dataProvider.update('storage', {
    id: upload.id,
    data: {
      confirmed: true,
    },
  });

  return upload.id;
}

export async function fetchGetURL({
  id,
}: Storage.IGetURL['params']): Promise<Storage.IGetURL['response']> {
  const response = await fetchApi(`/backoffice/storage/${id}/getURL`);
  return response.json();
}

export async function fetchAssetPath({
  id,
}: Storage.IGetPath['params']): Promise<Storage.IGetPath['response']> {
  const response = await fetchApi(`/backoffice/storage/${id}/getPath`);
  return response.json();
}

export async function fetchAsset({
  id,
}: Storage.IGetAsset['params']): Promise<Storage.IGetAsset['response']> {
  const response = await fetchApi(`/backoffice/storage/${id}/getAsset`);
  return response.json();
}
