import React from 'react';
import { Record, SelectField } from 'react-admin';

import { paymentMethodChoices } from './common';

export const SalePaymentMethodField = ({
  record,
  ...props
}: {
  record?: Record;
  label?: string;
}) => {
  if (!record) {
    return null;
  }

  return (
    <SelectField
      {...props}
      source="paymentMethod"
      choices={paymentMethodChoices}
      record={record}
    />
  );
};
