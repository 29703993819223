import SendIcon from '@material-ui/icons/Send';
import React, { useCallback, useState } from 'react';
import {
  Button,
  Confirm,
  Datagrid,
  EditButton,
  FunctionField,
  List,
  ReferenceField,
  ShowButton,
  TextField,
  useListContext,
  useNotify,
  usePermissions,
  useRedirect,
} from 'react-admin';
import styled from 'styled-components';
import { IBatchIngredientError, User } from 'ultimate-league-common';
import { useBoolean } from 'usehooks-ts';
import { submitData } from '~technical/api';

import { BatchFilter } from './BatchFilter';
import { getAthletesCount, hasCustomAssets, hasCustomTemplate } from './common';
import { PublicationErrors } from './components/publication-errors';
import { ISavedNftBatch } from './types';

const ActionsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

interface IActionsProps {
  record: ISavedNftBatch;
  permissions: User.Role;
  publish: (batch: ISavedNftBatch) => Promise<void>;
}

const Actions = ({
  publish,
  record,
  permissions,
  ...restProps
}: IActionsProps) => {
  const [publishing, setPublishing] = useState(false);

  const {
    value: publishConfirmation,
    setTrue: openPublishConfirmation,
    setFalse: closePublishConfirmation,
  } = useBoolean(false);

  const performPublish = useCallback(async () => {
    closePublishConfirmation();
    setPublishing(true);
    await publish(record);
    setPublishing(false);
  }, [closePublishConfirmation, publish, record]);

  return (
    <ActionsWrapper>
      <ShowButton record={record} {...restProps} />
      {record.isLive !== true && permissions === User.Role.MODERATOR && (
        <>
          <EditButton record={record} {...restProps} />
          <Button
            label={publishing ? 'Publishing...' : 'Publish'}
            disabled={publishing}
            onClick={openPublishConfirmation}
          >
            <SendIcon />
          </Button>
          <Confirm
            isOpen={publishConfirmation}
            title="Batch publication"
            content={`This could slow our servers. Are you sure you want to publish ${record.label} now?`}
            onConfirm={performPublish}
            onClose={closePublishConfirmation}
          />
        </>
      )}
    </ActionsWrapper>
  );
};

const CustomIterator = ({ permissions }: any) => {
  const { refetch } = useListContext();
  const redirect = useRedirect();
  const notify = useNotify();

  const [errors, setErrors] = useState<IBatchIngredientError[]>([]);
  const [erroredBatch, setErroredBatch] = useState<
    ISavedNftBatch | undefined
  >();

  const publish = useCallback(
    async (batch: ISavedNftBatch) => {
      try {
        const simulationResult = await submitData(
          `/backoffice/nftbatch/${batch.id}/publish`,
          {
            simulate: true,
          }
        );
        if (
          Array.isArray(simulationResult?.errors) &&
          simulationResult.errors.length
        ) {
          notify(
            'Some athletes in this batch have data missing. Please fix it or remove those athletes from the batch before publishing.',
            { type: 'error' }
          );

          setErroredBatch(batch);
          setErrors(simulationResult.errors);
          window.setTimeout(refetch, 500);
          return;
        }

        const publishResult = await submitData(
          `/backoffice/nftbatch/${batch.id}/publish`,
          {}
        );
        // Since we just simulate the publication, this case should never happens
        if (
          Array.isArray(publishResult?.errors) &&
          publishResult.errors.length
        ) {
          setErroredBatch(batch);
          setErrors(publishResult.errors);
          window.setTimeout(refetch, 500);
        } else {
          notify('Successfully published.', { type: 'success' });
          redirect(
            'list',
            `/nftcard?filter=${JSON.stringify({ batch: [batch.id] })}`
          );
        }
      } catch (e) {
        setErroredBatch(batch);
        setErrors([{ athleteId: 'global', reason: (e as Error).message }]);
      }
    },
    [refetch, redirect, setErrors, setErroredBatch, notify]
  );

  return (
    <>
      <PublicationErrors erroredBatch={erroredBatch} errors={errors} />

      <Datagrid>
        <TextField source="label" />

        <ReferenceField
          source="season"
          reference="nftcardseason"
          link={permissions === User.Role.MODERATOR}
        >
          <TextField source="title" />
        </ReferenceField>

        <FunctionField
          label="Athletes count"
          render={(batch: any) => getAthletesCount(batch)}
        />
        <FunctionField
          label="Has custom assets"
          render={(batch: any) => (hasCustomAssets(batch) ? 'Yes' : 'No')}
        />
        <FunctionField
          label="Has custom template"
          render={(batch: any) => (hasCustomTemplate(batch) ? 'Yes' : 'No')}
        />
        <FunctionField
          label="Published"
          render={(batch: any) => (batch.isLive === true ? 'Yes' : 'No')}
        />

        {/* @ts-ignore */}
        <Actions publish={publish} permissions={permissions} />
      </Datagrid>
    </>
  );
};

export const NftBatchList = (props: {}) => {
  const { permissions } = usePermissions();
  return (
    <List
      {...props}
      exporter={false}
      filters={<BatchFilter />}
      hasCreate={[User.Role.MODERATOR, User.Role.ASSET_MANAGER].includes(
        permissions
      )}
    >
      <CustomIterator permissions={permissions} />
    </List>
  );
};
