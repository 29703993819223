import React from 'react';
import { Labeled, TextField } from 'react-admin';
import { Blockchain } from 'ultimate-league-common';

import { IRecordWallet } from './type';

interface IProps {
  record?: IRecordWallet;
  short?: boolean;
  source: string;
}

export const AddressField = ({ record, short, ...props }: IProps) => {
  const walletAddress =
    record?.[props.source!] && Blockchain.withPrefix(record[props.source]);

  if (!walletAddress) {
    return null;
  }

  const Wrapper = short ? React.Fragment : Labeled;

  return (
    <Wrapper label="Address">
      <TextField
        {...props}
        source="address"
        record={{
          id: record!.id,
          address: short
            ? `${walletAddress.slice(0, 6)}...${walletAddress.slice(-6)}`
            : walletAddress,
        }}
      />
    </Wrapper>
  );
};
