import React from 'react';
import { Create, CreateProps } from 'react-admin';

import { LootTableForm } from './LootTableForm';

export const LootTableCreate = (props: CreateProps) => (
  <Create {...props}>
    {/* @ts-ignore */}
    <LootTableForm />
  </Create>
);
