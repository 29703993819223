import React from 'react';
import { Edit, EditProps } from 'react-admin';

import { PackConfigForm } from './PackConfigForm';

export const PackConfigEdit = (props: EditProps) => (
  <Edit {...props}>
    {
      // @ts-ignore
      <PackConfigForm />
    }
  </Edit>
);
