import { Link } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { TextField } from 'react-admin';
import { Blockchain } from 'ultimate-league-common';
import { fromWei } from 'web3-utils';
import { SCAN_URL } from '~business/nft-card/service';

import { IRecordWallet } from './type';

interface IProps {
  record?: IRecordWallet;
  blockchain: Blockchain.Blockchain;
  source: string;
}

export const ChampField = ({ record, blockchain, source }: IProps) => {
  const address =
    record && record?.[source!] && Blockchain.withPrefix(record[source]);
  const [balance, setBalance] = useState('...');

  useEffect(() => {
    if (!address) {
      return;
    }

    Blockchain.get(blockchain)
      .UnagiTokenContract.methods.balanceOf(address)
      .call()
      .then((b) => {
        setBalance(`${fromWei(b)} CHAMPs`);
      });
  }, [address, blockchain, setBalance]);

  return (
    <Link
      href={`${SCAN_URL[blockchain]}address/${address}`}
      target="_blank"
      rel="noreferrer"
    >
      <TextField
        source="balance"
        record={{
          id: address || '',
          balance,
        }}
      />
    </Link>
  );
};
