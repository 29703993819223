import { Blockchain } from 'ultimate-league-common';

export type KeyValueObject = { [key: string]: string | number } | null;

export interface IMetadata {
  title: string;
  description: string;
  attributes: Array<{
    type: string;
    value: string | number;
  }>;
}

export interface INFTMetadata extends IMetadata {
  image: string;
}

export interface IRecipe {
  metadata: IMetadata;
}

interface IBaseToken {
  id: string;
  blockchain: Blockchain.Blockchain;
  level: Blockchain.NFTCard.RarityLevel;
}

export interface IGeneratedCard extends IBaseToken {
  metadata: {
    CID: string;
    image: string;
  };
  mint: { nft: Blockchain.NFTCard.NftId } | undefined;
  recipe: undefined;
  mintStatus: undefined;
}

export interface IDraftCard extends IBaseToken {
  metadata: undefined;
  recipe: IRecipe;
  mint: undefined;
  mintStatus?: 'BUILDING' | 'MINTING';
}

export type IRecordNFT = IDraftCard | IGeneratedCard;

export const isGeneratedCard = (card: IRecordNFT): card is IGeneratedCard =>
  typeof card.metadata?.CID === 'string';
