import { Button, CircularProgress } from '@material-ui/core';
import { GetApp } from '@material-ui/icons';
import * as React from 'react';
import {
  KeyboardEventHandler,
  PropsWithChildren,
  useCallback,
  useState,
} from 'react';
import {
  AutocompleteArrayInput,
  Datagrid,
  DateField,
  downloadCSV,
  EditButton,
  Filter,
  List,
  ReferenceArrayInput,
  ReferenceField,
  SelectArrayInput,
  TextField,
  useNotify,
} from 'react-admin';
import styled from 'styled-components';
import { enumToArray, SoccerData } from 'ultimate-league-common';
import { DateRangeInput } from '~business/common/components/DateRangeInput';
import { buildQuery, fetchApi } from '~technical/api';
import { MatchField } from '~technical/filters/match';
import { TeamField } from '~technical/filters/team';
import { error } from '~technical/logger';

import { SPORT_CONTEXT } from '../../constant';

const matchStatusChoice = enumToArray(SoccerData.Match.MatchStatus).map(
  (status) => ({
    id: status,
    name: status,
  })
);

const StyledFilter = styled(Filter)`
  margin-top: 0rem;
`;

const StyledSelectArrayInput = styled(SelectArrayInput)`
  & .MuiSelect-select {
    min-width: 3.5rem;
  }
`;

const MatchFilter = ({ children, ...props }: PropsWithChildren<{}>) => {
  const handleKeyPress: KeyboardEventHandler = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  };

  return (
    /* TODO: fix types */
    /* @ts-ignore */
    <StyledFilter {...props} onKeyPress={handleKeyPress}>
      <ReferenceArrayInput
        label="Team"
        source={MatchField.TEAM}
        reference="team"
        filterToQuery={(search: string) => ({ [TeamField.JOKER]: search })}
      >
        <AutocompleteArrayInput optionText="name" optionValue="id" />
      </ReferenceArrayInput>
      <ReferenceArrayInput
        label="GameWeek"
        source="gameWeek"
        reference="gameweek"
        filterToQuery={(search: string) => ({ position: search })}
      >
        <AutocompleteArrayInput
          optionText="position"
          optionValue="id"
          matchSuggestion={(filter: string, choice: { position: number }) =>
            `${choice.position}`.match(filter)
          }
        />
      </ReferenceArrayInput>
      <StyledSelectArrayInput
        label="Status"
        source={MatchField.STATUS}
        choices={matchStatusChoice}
      />
      <DateRangeInput source={MatchField.DATE} />
      {children}
    </StyledFilter>
  );
};

interface IBulkActionProps {
  selectedIds: string[];
}

const DownloadCSV = ({ selectedIds }: IBulkActionProps) => {
  const [loading, setLoading] = useState(false);
  const notify = useNotify();

  const downloadStats = useCallback(async () => {
    setLoading(true);
    const response = await fetchApi(
      `/backoffice/match-stats?${buildQuery({
        matches: selectedIds,
        sport: SPORT_CONTEXT,
      })}`,
      { method: 'POST' }
    );
    try {
      downloadCSV((await response.json()).CSV, 'match-stats');
    } catch (e) {
      error(e);
      notify('An error occurred while generating the CSV. Aborting.', 'error');
    }

    setLoading(false);
  }, [selectedIds, setLoading, notify]);

  return (
    <Button
      color="primary"
      variant="contained"
      startIcon={
        loading ? <CircularProgress color="inherit" size={20} /> : <GetApp />
      }
      disabled={loading}
      onClick={downloadStats}
    >
      Download Stats
    </Button>
  );
};

const MatchBulkActionButtons = (props: any) => (
  <>
    <DownloadCSV {...props} />
  </>
);

export const MatchList = (props: {}) => (
  <List
    sort={{ field: 'date', order: 'ASC' }}
    filters={<MatchFilter />}
    exporter={false}
    bulkActionButtons={<MatchBulkActionButtons />}
    {...props}
  >
    <Datagrid>
      <DateField source="date" showTime />
      <TextField source="status" />
      <ReferenceField source="homeContestant" reference="team" link={false}>
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField source="awayContestant" reference="team" link={false}>
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField source="gameWeek" reference="gameweek">
        <TextField source="position" />
      </ReferenceField>
      <EditButton />
    </Datagrid>
  </List>
);
