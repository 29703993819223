import Typography from '@material-ui/core/Typography';
import React from 'react';
import styled from 'styled-components';
import { IUserCohort } from '~business/usercohort/types';

const List = styled.ul`
  margin: 0 20px;
  padding: 0;
  list-style-type: none;
`;

interface ICohortElementProps {
  selected: boolean;
}
const CohortElement = styled.li<ICohortElementProps>`
  margin: 5px;
  padding: 5px 10px;
  line-height: 40px;
  list-style-type: none;
  cursor: pointer;
  ${({ selected }) => (selected ? 'background-color: #caffca;' : '')}

  & > p {
    line-height: 30px;
  }
`;

interface IProps {
  cohorts: IUserCohort[];
  onSelect: (cohortId: IUserCohort['id']) => void;
  isSelected: (cohortId: IUserCohort['id']) => boolean;
}

export const CohortList = ({ cohorts, onSelect, isSelected }: IProps) => (
  <List>
    {cohorts?.map(({ id, name }) => (
      <CohortElement
        key={id}
        onClick={() => {
          onSelect(id);
        }}
        selected={isSelected(id)}
      >
        <Typography variant="body2">{name}</Typography>
      </CohortElement>
    ))}
  </List>
);
