import Dialog from '@material-ui/core/Dialog';
import React, { useCallback, useState } from 'react';
import { Button, useListContext, useNotify, useUnselectAll } from 'react-admin';
import { IPackConfigWithPacksCount } from '~business/pack/pack-config/types';
import { fetchApi, prepareSubmitRequestInit } from '~technical/api';

import { PackConfigSelector } from '../PackConfigSelector';

export const GivePackButton = () => {
  const notify = useNotify();
  const { resource, selectedIds } = useListContext();
  const unselectAll = useUnselectAll();
  const [modalVisible, setModalVisible] = useState<boolean>(false);

  const submit = useCallback(
    async (packConfigId: IPackConfigWithPacksCount['id']) => {
      try {
        await fetchApi(
          `/backoffice/pack-config/give-pack`,
          prepareSubmitRequestInit({
            packConfigId,
            users: selectedIds,
          })
        );
        notify('Operation successful 👍', { type: 'success' });
        setModalVisible(false);
        unselectAll(resource);
      } catch (error: any) {
        notify(`Failed to give pack(s)! ${error.message || error}`, {
          type: 'error',
        });
      }
    },
    [selectedIds, notify, unselectAll, resource]
  );

  return (
    <>
      <Button
        label="Give pack"
        disabled={false}
        onClick={() => {
          setModalVisible(true);
        }}
      />
      <Dialog
        open={modalVisible}
        onClose={() => {
          setModalVisible(false);
        }}
      >
        <PackConfigSelector
          onSubmit={submit}
          amountNeeded={selectedIds.length}
        />
      </Dialog>
    </>
  );
};
