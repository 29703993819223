/* eslint-disable no-underscore-dangle */
import {
  Box,
  CircularProgress,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputLabel,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import {
  CloudUpload,
  KeyboardArrowDown,
  KeyboardArrowUp,
} from '@material-ui/icons';
import Papa from 'papaparse';
import React, { useCallback, useState } from 'react';
import { Button, FileField, FileInput, useNotify } from 'react-admin';
import { Form } from 'react-final-form';
import { Auction, Payment } from 'ultimate-league-common';
import { submitData } from '~technical/api';

import { SPORT_CONTEXT } from '../../constant';

type IAuctionField =
  | 'id'
  | 'announcedAt'
  | 'startsAt'
  | 'expiresAt'
  | 'expiryIncrementMinutes'
  | 'startingAmountFiat';

type ICardField = 'cardId' | 'athleteId' | 'rarity';

type IAuctionRow = Record<IAuctionField | ICardField, 'string'>;

interface IBulkCreateAuction extends Auction.ICreateAuction {
  id: string;
  cards: Array<Auction.ICreateAuctionCard & { row: number }>;
}

interface IAuctionTableProps {
  auction: IBulkCreateAuction;
  error?: { cardIndex?: number; message: string };
}

const AuctionTable = ({ auction, error }: IAuctionTableProps) => {
  const [open, setOpen] = useState(false);

  const handleToggle = useCallback(() => {
    if (!error) {
      setOpen((prev) => !prev);
    }
  }, [error, setOpen]);

  const currencySign = Payment.CurrencySigns[Payment.ActiveCurrency];
  const label = `Auction ${auction.id} - Announced at ${auction.announcedAt} - From ${auction.startsAt} To ${auction.expiresAt} - Starting ${currencySign}${auction.startingAmount.fiat}`;
  return (
    <>
      <Button onClick={handleToggle} size="large" label={label}>
        {open ? <KeyboardArrowDown /> : <KeyboardArrowUp />}
      </Button>
      <Collapse in={open || !!error}>
        <Box marginBottom={5}>
          {error && error.cardIndex === undefined && (
            <Typography variant="subtitle1" style={{ color: 'red' }}>
              {error.message}
            </Typography>
          )}
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Row</TableCell>
                  <TableCell>Rarity</TableCell>
                  <TableCell>Athlete ID</TableCell>
                  <TableCell>ID</TableCell>
                  <TableCell>Error</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {auction.cards.map((card, index) => (
                  <TableRow key={card.row}>
                    <TableCell component="th" scope="row">
                      {card.row}
                    </TableCell>
                    <TableCell>{card.level}</TableCell>
                    <TableCell>{card.athlete?._id}</TableCell>
                    <TableCell>{card._id}</TableCell>
                    <TableCell style={{ color: 'red' }}>
                      {error?.cardIndex === index && error.message}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Collapse>
    </>
  );
};

export const AuctionBulkCreate = () => {
  const [open, setOpen] = useState(false);
  const [auctions, setAuctions] = useState<IBulkCreateAuction[]>();
  const [parseError, setParseError] = useState<string>();
  const [submissionError, setSubmissionError] = useState<any>();
  const [submitting, setSubmitting] = useState(false);
  const notify = useNotify();

  const handleFileSelection = useCallback(
    (file: File) => {
      if (!file) {
        setAuctions(undefined);
        setParseError(undefined);
        setSubmissionError(undefined);
        return;
      }
      Papa.parse<IAuctionRow>(file, {
        header: true,
        complete: (results) => {
          const grouped: Record<string, IBulkCreateAuction> = {};
          results.data.forEach((value, index) => {
            if (!value.id) {
              return;
            }
            if (!grouped[value.id]) {
              grouped[value.id] = {
                id: value.id,
                sport: SPORT_CONTEXT,
                announcedAt: value.announcedAt,
                startsAt: value.startsAt,
                expiresAt: value.expiresAt,
                startingAmount: { fiat: Number(value.startingAmountFiat) },
                expiryIncrementMinutes: Number(value.expiryIncrementMinutes),
                cards: [],
              };
            }
            grouped[value.id].cards.push({
              row: index + 1,
              _id: value.cardId || undefined,
              athlete: value.athleteId ? { _id: value.athleteId } : undefined,
              level: value.rarity || undefined,
            });
          });
          const ordered = Object.keys(grouped)
            .map((id) => Number(id))
            .sort((a, b) => a - b)
            .map((id) => grouped[id]);
          if (ordered.length) {
            setAuctions(ordered);
          } else {
            setParseError('No auctions with correct format found in file');
          }
        },
        error: (error: any) => {
          setParseError(error.message);
        },
      });
    },
    [setAuctions, setParseError, setSubmissionError]
  );

  const handleSubmit = useCallback(async () => {
    setSubmissionError(undefined);
    setSubmitting(true);
    try {
      const created = await submitData('/backoffice/auction', auctions);
      notify(`Created ${created.length} auctions`, 'success');
      setOpen(false);
      setAuctions(undefined);
    } catch (err: any) {
      if (err.body.auctionIndex !== undefined) {
        setSubmissionError(err.body);
      }
      notify(err.body?.message ?? `${err.body}`, 'error');
    } finally {
      setSubmitting(false);
    }
  }, [auctions, setSubmitting, setSubmissionError, notify]);

  const handleClose = useCallback(() => {
    if (!submitting) {
      setOpen(false);
    }
  }, [submitting, setOpen]);

  return (
    <>
      <Button label="Bulk Create" onClick={() => setOpen(true)}>
        <CloudUpload />
      </Button>
      <Form onSubmit={handleSubmit}>
        {(form) => (
          <Dialog open={open} onClose={handleClose} maxWidth="lg" fullWidth>
            <DialogTitle>Bulk Create</DialogTitle>
            <DialogContent>
              <InputLabel>Upload CSV</InputLabel>
              <FileInput
                source="file"
                accept="text/csv"
                onChange={handleFileSelection}
                required
              >
                <FileField source="src" title="title" />
              </FileInput>
              {parseError && (
                <Typography variant="subtitle1" style={{ color: 'red' }}>
                  {parseError}
                </Typography>
              )}
              {auctions && (
                <>
                  <Typography variant="h6">Auctions</Typography>
                  {auctions.map((auction, index) => (
                    <AuctionTable
                      auction={auction}
                      error={
                        submissionError?.auctionIndex === index
                          ? submissionError
                          : null
                      }
                    />
                  ))}
                </>
              )}
            </DialogContent>
            <DialogActions>
              <Button
                size="large"
                label="Cancel"
                onClick={() => setOpen(false)}
                color="secondary"
                disabled={submitting}
              />
              <Button
                size="large"
                label="Submit"
                onClick={form.handleSubmit}
                disabled={submitting || !auctions?.length}
              >
                {submitting ? (
                  <CircularProgress color="inherit" size={20} />
                ) : (
                  <CloudUpload />
                )}
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </Form>
    </>
  );
};
