import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField as MUITextField,
  Typography,
} from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import {
  ArrayField,
  Datagrid,
  DateField,
  Labeled,
  Record,
  ReferenceField,
  SelectField,
  Show,
  SimpleShowLayout,
  TextField,
  TopToolbar,
  UrlField,
  useNotify,
  usePermissions,
  useRefresh,
} from 'react-admin';
import ReactJson from 'react-json-view';
import { Link } from 'react-router-dom';
import { Payment, User, Blockchain } from 'ultimate-league-common';
import { SCAN_URL } from '~business/nft-card/service';
import { SaleAmount } from '~business/sale/SaleAmount';
import { SalePaymentMethodField } from '~business/sale/SalePaymentMethodField';
import { SaleStatusField } from '~business/sale/SaleStatusField';
import { saleTypeChoices } from '~business/sale/common';
import { submitData } from '~technical/api';

const PaymentIntentField = ({ record, ...props }: { record?: Record }) => {
  if (
    record?.paymentMethod !== Payment.Method.FIAT ||
    !record.payment?.intentId
  ) {
    return null;
  }

  return (
    <Labeled label="Payment Intent">
      <UrlField
        source="url"
        record={{
          ...record,
          url: `https://dashboard.stripe.com/payments/${record.payment.intentId}`,
        }}
        {...props}
        target="_blank"
      />
    </Labeled>
  );
};

const PaymentTransactionsField = ({
  record,
  ...props
}: {
  record?: Record;
}) => {
  if (
    record?.paymentMethod !== Payment.Method.UNA_TOKEN ||
    !record.payment?.transactions
  ) {
    return null;
  }

  const urls = record.payment.transactions
    .filter((t: any) => t.transactionHash)
    .map((t: any) => `${SCAN_URL[t.blockchain]}/tx/${t.transactionHash}`);

  if (!urls.length) {
    return null;
  }

  return (
    <Labeled label="Transactions">
      <>
        {urls.map((url: string) => (
          <UrlField
            source="url"
            record={{
              ...record,
              url,
            }}
            {...props}
            target="_blank"
          />
        ))}
      </>
    </Labeled>
  );
};

const NftField = ({ record }: { record?: Record }) => {
  if (!record?.nft) {
    return null;
  }

  return (
    <Button
      color="primary"
      component={Link}
      to={{
        pathname: '/nftcard',
        search: `filter=${JSON.stringify({ nft: record.nft })}`,
      }}
    >
      Show NFT #{record.nft}
    </Button>
  );
};

const PacksField = ({ record }: { record?: Record }) => {
  if (!record?.packs?.length) {
    return null;
  }
  return (
    <Labeled label="Packs">
      <>
        {record.packs.map((p: any, index: number) => (
          <ReferenceField
            key={p}
            source={`packs.${index}`}
            reference="pack"
            link="show"
          >
            <TextField source="_id" />
          </ReferenceField>
        ))}
      </>
    </Labeled>
  );
};

const PaymentField = ({ record }: { record?: Record }) => {
  if (!record?.payment) {
    return null;
  }

  return (
    <ReactJson
      style={{ marginTop: 24 }}
      src={record.payment}
      name="payment"
      collapsed={1}
    />
  );
};

const StoppedReasonField = ({ record }: { record?: Record }) => {
  if (!record?.stoppedReason) {
    return null;
  }

  return (
    <Typography>
      Reason (
      <ReferenceField source="stoppedReason.moderator" reference="user">
        <TextField source="username" />
      </ReferenceField>
      ):{` `}
      <TextField source="stoppedReason.message" />
    </Typography>
  );
};

const SaleShowActions = ({ data }: { data?: Record }) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [message, setMessage] = useState('');
  const [canRefund, setCanRefund] = useState(false);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMessage(event.target.value);
  };

  const showDialog = useCallback(() => {
    setShowConfirmation(true);
  }, [setShowConfirmation]);

  const closeDialog = useCallback(() => {
    setShowConfirmation(false);
  }, [setShowConfirmation]);

  const handleStop = useCallback(async () => {
    if (!data?.id) {
      throw new Error('Resource id missing');
    }
    setFetching(true);
    try {
      await submitData(`/backoffice/sale/${data?.id}/stop`, { message });
      refresh();
      closeDialog();
      setTimeout(() => setFetching(false), 0);
    } catch (e) {
      notify(e.message, 'error');
      setFetching(false);
      throw e;
    }
  }, [data?.id, message, refresh, closeDialog, notify]);

  const handleRefund = useCallback(async () => {
    if (!data?.id) {
      throw new Error('Resource id missing');
    }
    setFetching(true);
    try {
      await submitData(`/backoffice/sale/${data?.id}/refund`, { message });
      refresh();
      setTimeout(() => setFetching(false), 0);
    } catch (e) {
      notify(e.message, 'error');
      setFetching(false);
      throw e;
    }
  }, [data?.id, message, refresh, notify]);

  useEffect(() => {
    if (
      data?.paymentMethod === Payment.Method.UNA_TOKEN ||
      data?.paymentMethod === Payment.Method.NFCHAMP_TOKEN
    ) {
      const { transactions } = data.payment;
      Promise.all(
        transactions.map((tx: any) =>
          Blockchain.get(tx.blockchain)
            .TokenTransferRelayContract.methods.isTransferReserved(
              Blockchain.get(tx.blockchain).web3.utils.keccak256(
                data.id.toString()
              ),
              Blockchain.withPrefix(tx.from)
            )
            .call()
        )
      ).then((areTxsReserved) => {
        if (areTxsReserved.includes(true)) {
          setCanRefund(true);
        }
      });
    }
  });

  return (
    <TopToolbar>
      <Dialog open={showConfirmation} onClose={closeDialog}>
        <DialogTitle>Stop this sale ?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please provide a reason to stop this sale:
          </DialogContentText>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <MUITextField
              multiline
              minRows={2}
              value={message}
              onChange={handleChange}
            />
          </div>
        </DialogContent>
        <DialogActions>
          {fetching ? (
            <CircularProgress style={{ margin: 24 }} />
          ) : (
            <>
              <Button
                onClick={closeDialog}
                color="primary"
                variant="contained"
                autoFocus
              >
                Cancel
              </Button>
              <Button
                onClick={handleStop}
                disabled={!message}
                color="secondary"
              >
                Proceed
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>
      {data &&
        [
          Payment.Sale.SaleStatus.CREATED,
          Payment.Sale.SaleStatus.ONGOING,
          Payment.Sale.SaleStatus.ERROR,
        ].includes(data.status) && (
          <Button color="primary" variant="contained" onClick={showDialog}>
            Mark as STOPPED
          </Button>
        )}
      {canRefund && (
        <Button color="primary" variant="contained" onClick={handleRefund}>
          Refund Payment
        </Button>
      )}
    </TopToolbar>
  );
};

export const SaleShow = (props: any) => {
  const { permissions } = usePermissions();
  return (
    <Show
      actions={
        permissions === User.Role.MODERATOR ? <SaleShowActions /> : undefined
      }
      {...props}
    >
      <SimpleShowLayout>
        <SaleStatusField />

        <StoppedReasonField />

        <SelectField source="type" choices={saleTypeChoices} />

        <ReferenceField source="user" reference="user">
          <TextField source="username" />
        </ReferenceField>

        <Labeled label="Method">
          <SalePaymentMethodField />
        </Labeled>
        <Labeled label="Amount">
          <SaleAmount />
        </Labeled>

        <PaymentIntentField />
        <PaymentTransactionsField />

        <NftField />
        <PacksField />

        <DateField showTime source="createdAt" />
        <DateField showTime source="updatedAt" />

        <ArrayField source="saleErrors">
          <Datagrid>
            <DateField showTime source="at" />
            <TextField source="message" />
          </Datagrid>
        </ArrayField>

        <PaymentField />
      </SimpleShowLayout>
    </Show>
  );
};
