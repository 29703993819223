import DeleteIcon from '@material-ui/icons/Delete';
import React from 'react';
import { Button, Confirm } from 'react-admin';
import { useBoolean } from 'usehooks-ts';

import { useMonetization } from '../hooks';
import { IPackConfig } from '../types';

const noop = () => {};

export const ClearPacksButton = (props: { record: IPackConfig }) => {
  const { record: packConfig } = props;
  const { isMonetizationEnabled, canClearPacks, clearPacks } =
    useMonetization(packConfig);

  const {
    value: mustConfirm,
    setTrue: setMustConfirmTrue,
    setFalse: setMustConfirmFalse,
  } = useBoolean(false);

  if (!isMonetizationEnabled) {
    return null;
  }

  const handleClearPacks = () => {
    setMustConfirmFalse();
    clearPacks();
  };

  return (
    <>
      <Button
        disabled={!canClearPacks}
        label="Clear packs"
        onClick={!canClearPacks ? noop : setMustConfirmTrue}
      >
        <DeleteIcon />
      </Button>

      <Confirm
        isOpen={mustConfirm}
        title="Clear packs"
        content="Please confirm packs clearing."
        onConfirm={handleClearPacks}
        onClose={setMustConfirmFalse}
      />
    </>
  );
};
