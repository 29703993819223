import { Typography } from '@material-ui/core';
import React from 'react';
import { ReferenceField, TextField } from 'react-admin';
import { User } from 'ultimate-league-common';

import { Label, ShowRow } from './styles';

const SeasonTitle = ({ record, permissions }: any) => (
  <ShowRow>
    <Typography variant="body2" component="div">
      <Label>Season</Label>
      <ReferenceField
        source="season"
        reference="nftcardseason"
        record={record}
        link={permissions === User.Role.MODERATOR}
      >
        <TextField source="title" />
      </ReferenceField>
    </Typography>
  </ShowRow>
);

export default SeasonTitle;
