import { useFlag as useFlagUnleash } from '@unleash/proxy-client-react';
import { FeatureFlags, Brand } from 'ultimate-league-common';
import { Authorization, CurrentUser } from '~technical/authorization';

const unleashApiUrl = process.env.UNLEASH_API_URL;
const unleashToken = process.env.UNLEASH_TOKEN;

if (!unleashApiUrl || !unleashToken) {
  throw new Error('Missing env UNLEASH_*');
}

export function getFeatureFlagContext(user?: CurrentUser) {
  return {
    userId: user?.id,
    userRole: user?.role,
  };
}

export function useFlag(feature: FeatureFlags.Features.TOURNAMENTS) {
  return useFlagUnleash(`${Brand.BRAND}_${feature}`);
}

export const unleashConfig = {
  url: `${unleashApiUrl}/frontend`,
  appName: FeatureFlags.getAppName(),
  environment: process.env.NODE_ENV,
  clientKey: unleashToken,
  context: getFeatureFlagContext(Authorization.getInstance().getUser()),
};
