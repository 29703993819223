import React from 'react';
import { DateField, Show, SimpleShowLayout, TextField } from 'react-admin';

import { DurationField } from './DurationField';

export const CronShow = (props: {}) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="job" />
      <DateField source="createdAt" showTime />
      <DateField source="endAt" showTime />
      <DurationField source="duration" addLabel />
      <TextField source="logs" />
      <TextField source="error" />
    </SimpleShowLayout>
  </Show>
);
