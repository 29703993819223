import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Blockchain, User } from 'ultimate-league-common';
import { IRecordWallet } from '~business/wallet/type';
import { fetchApi } from '~technical/api';

async function fetchUserByAddress(
  walletAddress: Blockchain.Address
): Promise<User.IMinimalUser> {
  const response = await fetchApi(
    `/user/address/${Blockchain.withoutPrefix(walletAddress)}`
  );
  return response.json();
}

interface IProps {
  record?: IRecordWallet;
}

export const MgcField = ({ record }: IProps) => {
  const wallet = record as IRecordWallet;
  const address = wallet?.address
    ? Blockchain.withPrefix(wallet.address)
    : undefined;
  const [user, setUser] = useState<User.IMinimalUser>();

  useEffect(() => {
    if (!address) {
      return;
    }

    fetchUserByAddress(address).then(setUser);
  }, [setUser, address]);

  if (!record) {
    return null;
  }

  return (
    <Link
      to={{
        pathname: '/ulctransfer',
        search: `filter=${JSON.stringify({
          user: user?.id,
          status: 'SUCCESS',
        })}`,
      }}
    >
      {(record.ulcBalanceCent / 100).toFixed(2)} MGCs
    </Link>
  );
};
