import { Typography } from '@material-ui/core';
import React, { FC } from 'react';

import { hasCustomAssets } from '../common';
import { ISavedNftBatch } from '../types';
import { Label, ShowRow, Value } from './styles';

interface IProps {
  record?: ISavedNftBatch;
}

const CustomAssetsSummary: FC<IProps> = ({ record }: IProps) => (
  <ShowRow>
    <Typography variant="body2" component="div">
      <Label>Has custom assets?</Label>
      <Value>{hasCustomAssets(record) ? 'Yes' : 'No'}</Value>
    </Typography>
  </ShowRow>
);

export default CustomAssetsSummary;
