import { validate as validateCommon } from 'ultimate-league-common';

const unstackErrors = (
  errors: string[] | object[] | object
): string | object => {
  if (errors instanceof Array) {
    if (typeof errors[0] === 'string') {
      return errors[0];
    }

    return (errors as object[]).map(unstackErrors);
  }

  return Object.keys(errors).reduce(
    (acc, field) => ({
      ...acc,
      [field]: unstackErrors(errors[field]),
    }),
    {}
  );
};

export const validateDate = (dateArg: any) => {
  const date = new Date(dateArg);

  return date instanceof Date && !Number.isNaN(date.valueOf())
    ? undefined
    : 'Date required';
};

export const validate = (constraints: object) => (values: object) => {
  const errors = validateCommon(values, constraints);
  if (errors) {
    return unstackErrors(errors);
  }

  return errors;
};

export type FormError = string | { message: string; args: object };
