import { Box, CircularProgress } from '@material-ui/core';
import * as React from 'react';
import { useEffect, useState } from 'react';
import {
  BooleanInput,
  DateTimeInput,
  Edit,
  EditProps,
  FormTab,
  FormWithRedirectSave,
  Record,
  SimpleFormProps,
  TabbedForm,
  TextInput,
} from 'react-admin';
import { GameWeek } from 'ultimate-league-common';
import { fetchApi } from '~technical/api';
import { validateDate } from '~technical/form';
import { SafeNumberInput } from '~ui/SafeNumberInput';

import { SPORT_CONTEXT } from '../../constant';
import { GameModes } from './game-modes';
import { Virtual } from './virtual';

interface IFormProps extends SimpleFormProps {
  record?: GameWeek.IGameWeek;
}

const Form = ({ record, save, ...props }: IFormProps) => {
  const [previousGameWeek, setPreviousGameWeek] =
    useState<GameWeek.IGameWeek>();

  const [isVirtual, setIsVirtual] = useState(record?.isVirtual || false);

  const handleChangeIsVirtual = () => setIsVirtual(!isVirtual);

  useEffect(() => {
    if (!record?.position) {
      return;
    }

    fetchApi(`/gameweek/${record.position - 1}?sport=${SPORT_CONTEXT}`)
      .then((res) => res.json())
      .then(setPreviousGameWeek);
  }, [setPreviousGameWeek, record?.position]);

  if (!previousGameWeek) {
    return (
      <Box margin="auto">
        <CircularProgress />
      </Box>
    );
  }

  const handleSave: FormWithRedirectSave = async (values, ...params) => {
    const data = { ...values };
    if (!data.gameModes.tournaments?.length) {
      delete data.gameModes.tournaments;
    }
    return save(data, ...params);
  };

  const isVirtualToggleDisabled =
    !!record?.startDate && new Date(record.startDate) <= new Date(Date.now());

  return (
    <TabbedForm
      {...props}
      save={handleSave}
      record={record as unknown as Record}
    >
      <FormTab label="general">
        <SafeNumberInput source="position" disabled />
        <TextInput label="Title (internal)" source="title" />
        <DateTimeInput
          source="startDate"
          inputProps={{
            min: previousGameWeek.endDate.slice(0, 16),
            step: 3600,
          }}
          validate={[validateDate]}
        />
        <DateTimeInput
          source="endDate"
          inputProps={{
            min: previousGameWeek.endDate.slice(0, 16),
            step: 3600,
          }}
          validate={[validateDate]}
        />
        <BooleanInput
          source="isVirtual"
          disabled={isVirtualToggleDisabled}
          onChange={handleChangeIsVirtual}
        />
      </FormTab>
      <FormTab label="game modes">
        <GameModes />
      </FormTab>
      {isVirtual && (
        <FormTab label="Virtual">
          <Virtual />
        </FormTab>
      )}
    </TabbedForm>
  );
};

export const GameWeekEdit = (props: EditProps) => (
  <Edit {...props} mutationMode="pessimistic">
    {/* @ts-ignore */}
    <Form />
  </Edit>
);
