import { Typography } from '@material-ui/core';
import React from 'react';
import {
  AutocompleteInput,
  BooleanInput,
  DateTimeInput,
  FormTab,
  ReferenceArrayInput,
  ReferenceInput,
  SelectArrayInput,
  TabbedForm,
  TabbedFormProps,
  TextInput,
} from 'react-admin';
import { useFormState } from 'react-final-form';
import styled from 'styled-components';
import { Storage } from 'ultimate-league-common';
import { IAsset } from '~business/common/assetTypes';
import { StorageImageInput } from '~business/storage/StorageImageInput';
import { uploadToStorage } from '~business/storage/service';
import { LootTableField } from '~technical/filters/lootTable';
import { TranslationField } from '~technical/filters/translation';
import { SafeNumberInput } from '~ui/SafeNumberInput';

import { SPORT_CONTEXT } from '../../../constant';
import { validateForm } from './editService';
import { useMonetization } from './hooks';
import { IPackConfig } from './types';

const GroupArea = styled.div`
  margin: 0 0 1rem;
  padding: 0.6em 1em 0;
  border: 1px solid #d9d9d9;

  h6 {
    margin: 0.4em 0 0.5em;
  }
`;

const MonetizationTab = (props: {}) => {
  const { values: packConfig } = useFormState<IPackConfig>();
  const { isMonetizationEnabled, hasExistingPacks } =
    useMonetization(packConfig);

  if (!isMonetizationEnabled) {
    return null;
  }

  return (
    <FormTab {...props} label="Monetization">
      <SafeNumberInput source="monetization.price" label="Price" />
      <BooleanInput
        source="monetization.hasInfiniteSupply"
        label={`Infinite supply ${hasExistingPacks ? '(not editable)' : ''}`}
        disabled={hasExistingPacks}
      />
      {!packConfig?.monetization?.hasInfiniteSupply && (
        <SafeNumberInput
          source="monetization.supply"
          label={`Supply ${hasExistingPacks ? '(not editable)' : ''}`}
          disabled={hasExistingPacks}
        />
      )}
      <GroupArea>
        <Typography variant="h6">Payment methods</Typography>
        <BooleanInput
          source="monetization.allowUnaPurchase"
          label="CHAMP Tokens"
          initialValue
        />
        <BooleanInput
          source="monetization.allowUlcPurchase"
          label="MGC Tokens"
          initialValue
        />
        <BooleanInput
          source="monetization.allowCreditCardPurchase"
          label="Credit Card"
          initialValue
        />
      </GroupArea>
      <GroupArea>
        <Typography variant="h6">Maximum purchase per player</Typography>
        <ReferenceInput
          label="Share with pack config"
          source="monetization.sharedMaximumPurchasePerPlayer"
          reference="packConfig"
          filterToQuery={(name) => ({
            name,
            'monetization.maximumPurchasePerPlayer': { $gt: 0 },
          })}
          allowEmpty
        >
          <AutocompleteInput optionText="name" optionValue="id" />
        </ReferenceInput>
        {!packConfig.monetization?.sharedMaximumPurchasePerPlayer && (
          <SafeNumberInput
            source="monetization.maximumPurchasePerPlayer"
            label="Maximum purchase per player"
          />
        )}
      </GroupArea>
      <DateTimeInput
        source="monetization.announcingDate"
        label="Announcing date"
      />
      <TextInput
        source="monetization.customSticker"
        label="Custom Live Sticker"
      />
      <StorageImageInput
        source="monetization.majorPreview.medium"
        label="Major preview"
      />
      <StorageImageInput
        source="monetization.minorPreview.medium"
        label="Minor preview"
      />
      <DateTimeInput source="monetization.startDate" label="Start date" />
      <DateTimeInput source="monetization.endDate" label="End date" />
      <GroupArea>
        <Typography variant="h6">Requirements</Typography>
        <SafeNumberInput
          source="monetization.requirements.collectionScoreMax"
          label="Maximum collection score"
        />
        <SafeNumberInput
          source="monetization.requirements.collectionScore"
          label="Minimum collection score"
        />
        <SafeNumberInput
          source="monetization.requirements.unaAmount"
          label="CHAMP amount"
        />
      </GroupArea>
      <Typography>
        Hide pack once user cannot buy it anymore (Pack expired or max purchase
        limit is met).
      </Typography>
      <BooleanInput
        label="Hide when unavailable"
        source="monetization.hideWhenUnavailable"
      />
      <Typography>
        Cohorts visibility (Keep empty to make it available to all cohorts)
      </Typography>
      <ReferenceArrayInput
        label="Cohorts"
        source="monetization.cohorts"
        reference="usercohort"
        defaultValue={[]}
      >
        <SelectArrayInput optionText="name" />
      </ReferenceArrayInput>
      <BooleanInput
        label="Giveaway? 🎁 (Hide this pack from the pack store and enable the 'Giveaway' status)"
        source="monetization.isGiveaway"
        defaultValue={false}
      />
    </FormTab>
  );
};

export const PackConfigForm = (props: TabbedFormProps) => {
  const { record: packConfig, save } = props;

  const handleSave: TabbedFormProps['save'] = async (
    values,
    redirect,
    ...params
  ) => {
    const { id } = values as { id: string };

    const visuals = id
      ? await Promise.all(
          values.visuals.map(async (visual: IAsset, index: number) => {
            if (typeof visual === 'string') {
              return visual;
            }

            return uploadToStorage(
              {
                type: Storage.StorageType.PUBLIC_PACK_VISUAL,
                packConfigId: String(id),
                index,
              },
              visual.rawFile
            );
          })
        )
      : [];

    let monetization;
    if (
      values.isMonetizationEnabled &&
      values.monetization &&
      Object.keys(values.monetization).length
    ) {
      const { hasInfiniteSupply, supply, ...restMonetization } =
        values.monetization;
      monetization = {
        ...restMonetization,
        hasInfiniteSupply,
        supply: hasInfiniteSupply ? 0 : supply,
      };
    }

    return save?.(
      {
        ...values,
        visuals,
        monetization,
        sport: SPORT_CONTEXT,
      },
      packConfig?.id
        ? redirect
        : (_, packConfigId) => `/packConfig/${packConfigId}`,
      ...params
    );
  };

  return (
    <TabbedForm {...props} validate={validateForm} save={handleSave}>
      <FormTab label="Pack summary">
        <TextInput source="name" label="(internal) Name" />
        <ReferenceInput
          source="lootTable"
          reference="lootTable"
          filterToQuery={(search: string) => ({
            [LootTableField.TITLE]: search,
          })}
        >
          <AutocompleteInput
            optionText={LootTableField.TITLE}
            optionValue="id"
          />
        </ReferenceInput>
        <BooleanInput label="Monetization" source="isMonetizationEnabled" />
        <BooleanInput label="Is Archive" source="isArchive" />
      </FormTab>
      {packConfig?.id && (
        <FormTab label="Content">
          <ReferenceInput
            source="displayName"
            reference="translation"
            filterToQuery={(search: string) => ({
              [TranslationField.KEY]: search,
            })}
            allowEmpty
          >
            <AutocompleteInput optionText="key" optionValue="id" />
          </ReferenceInput>
          <TextInput
            label="Override display name"
            source="displayNameOverride"
          />
          <StorageImageInput label="Visuals" source="visuals" multiple />
          <ReferenceInput
            source="content"
            reference="translation"
            filterToQuery={(search: string) => ({
              [TranslationField.KEY]: search,
            })}
          >
            <AutocompleteInput optionText="key" optionValue="id" />
          </ReferenceInput>
          <ReferenceInput
            source="details"
            reference="translation"
            filterToQuery={(search: string) => ({
              [TranslationField.KEY]: search,
            })}
          >
            <AutocompleteInput optionText="key" optionValue="id" />
          </ReferenceInput>
        </FormTab>
      )}
      {packConfig?.id && <MonetizationTab />}
    </TabbedForm>
  );
};
