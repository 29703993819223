import { Typography } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';

import { ICountry } from './types';

interface IFlagProps {
  countryCode: ICountry['isoAlpha2Code'];
}

const SFlag = styled.span`
  width: 60px;
  aspect-ratio: 4/3;
`;

export const Flag = ({ countryCode }: IFlagProps) =>
  !countryCode ? (
    <Typography variant="subtitle2">No country code</Typography>
  ) : (
    <SFlag className={`fi fi-${countryCode}`} />
  );
