import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { ComponentProps } from 'react';
import { AppBar as RaAppBar } from 'react-admin';
import styled from 'styled-components';
import { Brand, SoccerData } from 'ultimate-league-common';
import { EnvBanner } from '~business/env-warnings/EnvBanner';

import { SPORT_CONTEXT } from '../../constant';

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1100;
`;

const APP_BAR_COLOR = Brand.switchBrand({
  UC: {
    [SoccerData.Sport.SOCCER]: '#2FC659',
    [SoccerData.Sport.BASKETBALL]: '#FF7D2C',
  },
  LFP: {
    [SoccerData.Sport.SOCCER]: '#091c3e',
    [SoccerData.Sport.BASKETBALL]: '#091c3e',
  },
});

const useStyles = makeStyles({
  'sportified-ra-appbar': {
    backgroundColor: APP_BAR_COLOR[SPORT_CONTEXT] || 'blue',
  },
});

export const AppBar = (props: ComponentProps<typeof RaAppBar>) => {
  const classes = useStyles();
  return (
    <Container>
      <EnvBanner />
      <RaAppBar
        className={classes['sportified-ra-appbar']}
        position="relative"
        {...props}
      />
    </Container>
  );
};
