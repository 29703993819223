import React from 'react';
import { Edit, EditProps, SaveButton, Toolbar } from 'react-admin';

import { FormationForm } from './FormationForm';

const FormationEditToolbar = (props: any) => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
);

export const FormationEdit = (props: EditProps) => (
  <Edit {...props} mutationMode="pessimistic">
    {
      // @ts-ignore
      <FormationForm toolbar={<FormationEditToolbar />} isEdit />
    }
  </Edit>
);
