import { processGenericFilter, Value } from './generic';

export enum LootTableField {
  NFT_CARD_FILTER = 'NFT_CARD_FILTER',
  TITLE = 'title',
}

export function processLootTableFilter(
  field: LootTableField | string,
  value: Value
) {
  switch (field) {
    case LootTableField.NFT_CARD_FILTER:
      return {
        'loots.drops.filter': value,
      };
    case LootTableField.TITLE:
      return { title: { $regex: value, $options: 'i' } };
    default:
      return processGenericFilter(field, value);
  }
}
