import { processGenericFilter, Value } from './generic';

export enum SaleField {
  PAYMENT_INTENT_ID = 'payment_intentId',
}

export function processSaleFilter(field: SaleField | string, value: Value) {
  switch (field) {
    case SaleField.PAYMENT_INTENT_ID:
      return {
        $or: [
          {
            'payment.intentId': value,
          },
          {
            'payment.transactions.transactionHash': value,
          },
        ],
      };

    default:
      return processGenericFilter(field, value);
  }
}
