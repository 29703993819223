import React from 'react';
import { Record, TextField } from 'react-admin';
import { Payment } from 'ultimate-league-common';
import { fromWei } from 'web3-utils';

export const SaleAmount = ({
  record,
  ...props
}: {
  record?: Record;
  label?: string;
}) => {
  const amount = (() => {
    if (record?.payment?.fiatPrice) {
      return `${(record.payment.fiatPrice / 100).toFixed(2)} ${
        record.payment.currency
      }`;
    }
    if (record?.payment?.weiPrice) {
      const token =
        record.paymentMethod === Payment.Method.ULC_TOKEN ? 'MGC' : 'CHAMP';
      return `${fromWei(record.payment.weiPrice, 'ether')} ${token}`;
    }
    if (record?.equivalentFiat) {
      return `${record.equivalentFiat} ${Payment.ActiveCurrency}`;
    }
    return null;
  })();

  return (
    <TextField
      {...props}
      source="amount"
      record={record ? { ...record, amount } : record}
    />
  );
};
