import { Typography } from '@material-ui/core';
import * as React from 'react';
import styled from 'styled-components';
import { Blockchain } from 'ultimate-league-common';

import { GenerateCardConfirmButton } from '../buttons/GenerateCardConfirmButton';
import { IDraftCard } from '../interfaces';

interface IDraftCardItemProps {
  card: IDraftCard;
  onClick?: () => void;
}

const StyledDraftItem = styled.div`
  background-color: #d1d1d1;
  justify-content: center;
`;

const getAttribute = (nft: IDraftCard, type: string) =>
  nft.recipe.metadata.attributes.find((a) => a.type === type)?.value;

export const DraftCardItem = ({ card, onClick }: IDraftCardItemProps) => (
  <StyledDraftItem onClick={onClick}>
    <Typography variant="body1">
      <div style={{ fontWeight: 'bold' }}>{card.recipe.metadata.title}</div>
      <div>{card.recipe.metadata.description}</div>
      <div>
        Rarity:
        {
          Blockchain.NFTCard.RarityLevel[
            getAttribute(card, 'rarity') as Blockchain.NFTCard.RarityLevel
          ]
        }
      </div>
      <div>
        Edition: {getAttribute(card, 'edition')}/{getAttribute(card, 'supply')}
      </div>
      <div className={`actions ${card.mintStatus ? 'minting' : ''}`}>
        <GenerateCardConfirmButton card={card} />
      </div>
    </Typography>
  </StyledDraftItem>
);
