import RichTextInput from 'ra-input-rich-text';
import React, { useMemo } from 'react';
import {
  AutocompleteArrayInput,
  BooleanInput,
  InputProps,
  required,
  SaveButton,
  SimpleForm,
  SimpleFormProps,
  TextInput,
  Toolbar,
  TranslatableInputs,
  useGetList,
} from 'react-admin';
import { useField } from 'react-final-form';
import { enumToArray, LANG } from 'ultimate-league-common';

const TagsInput = ({
  createdTags,
  ...props
}: Omit<InputProps, 'source'> & { createdTags: string[] }) => {
  const { input } = useField<string[]>('tags');

  const choices = useMemo(() => {
    const set = new Set(createdTags);
    input.value.forEach?.((id) => set.add(id));
    return Array.from(set).map((name) => ({
      id: name,
      name,
    }));
  }, [createdTags, input.value]);

  return (
    <AutocompleteArrayInput
      source="tags"
      translateChoice={false}
      choices={choices}
      createLabel="+ Create tag"
      onCreate={(tag) => {
        const name = tag || global.prompt('Create a new tag') || '';
        return { id: name, name };
      }}
      {...props}
    />
  );
};

const ValueInput = (props: Omit<InputProps, 'source'>) => {
  const {
    input: { value: rich },
  } = useField<boolean>('rich');

  if (rich) {
    return (
      <TranslatableInputs {...props} locales={enumToArray(LANG)}>
        <RichTextInput source="value" validate={required()} />
      </TranslatableInputs>
    );
  }

  return (
    <>
      {enumToArray(LANG).map((lang) => (
        <TextInput
          {...props}
          style={{ margin: '0 12px' }}
          key={lang}
          label={lang}
          source={`value.${lang}`}
          validate={required()}
        />
      ))}
    </>
  );
};

export const TranslationForm = ({
  edit,
  ...props
}: SimpleFormProps & { edit?: boolean }) => {
  const { ids: createdTags, loading } = useGetList('translationTag') as {
    ids: string[];
    loading: boolean;
  };

  const handleSave = async (
    values: { tags: string[] },
    ...params: Parameters<SimpleFormProps['save']>
  ) => {
    const tags = values.tags
      .filter((t) => !!t)
      .map(
        (tag) =>
          createdTags.find(
            (createdTag?: string) =>
              createdTag?.toLowerCase() === tag.toLowerCase()
          ) || tag
      );
    const set = new Set(tags);

    return props.save(
      {
        ...values,
        tags: Array.from(set),
      },
      ...params
    );
  };

  if (loading) {
    return null;
  }

  return (
    <SimpleForm
      {...props}
      initialValues={{
        key: '',
        tags: [],
        rich: false,
        value: {},
      }}
      save={handleSave}
      toolbar={
        <Toolbar>
          <SaveButton />
        </Toolbar>
      }
    >
      <TextInput
        disabled={edit}
        parse={(v: string) => (v || '').toUpperCase().replace(/[\s\W]/g, '_')}
        validate={required()}
        source="key"
      />
      <TagsInput createdTags={createdTags} />
      <BooleanInput source="rich" />
      <ValueInput />
    </SimpleForm>
  );
};
