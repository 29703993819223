import React from 'react';
import { ReferenceInput, SelectInput, Validator } from 'react-admin';

export const StackingPenaltiesInput = ({
  source,
  required,
}: {
  source: string;
  required?: Validator[];
}) => (
  <ReferenceInput
    label="Stacking Penalties Config"
    source={source}
    reference="StackingPenaltiesConfig"
    validate={required}
  >
    <SelectInput
      style={{ maxWidth: 300, marginRight: 30 }}
      fullWidth
      optionText="name"
      optionValue="id"
    />
  </ReferenceInput>
);
