import { useEffect, useState } from 'react';
import { useDataProvider, useNotify } from 'react-admin';
import { GameView } from 'ultimate-league-common';

import { SPORT_CONTEXT } from '../../../constant';

export const useDefaultFormation = () => {
  const dataProvider = useDataProvider();
  const notify = useNotify();

  const [defaultFormation, setDefaultFormation] =
    useState<GameView.IFormation>();

  useEffect(() => {
    // Fetch default formation
    (async () => {
      const result = await dataProvider.getList('Formation', {
        sort: { field: 'title', order: 'ASC' },
        filter: { default: true, sport: SPORT_CONTEXT },
        pagination: { page: 1, perPage: 2 },
      });
      if (result.data.length !== 1) {
        notify(
          `Was expecting one default formation but got ${
            result.data.length
          } (${result.data.map(({ title }) => title).join(', ')})`,
          { type: 'warning' }
        );
      }
      setDefaultFormation(result.data[0] as unknown as GameView.IFormation);
    })();
  }, [dataProvider, notify]);

  return {
    defaultFormation,
  };
};
