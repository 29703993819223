import { Button } from '@material-ui/core';
import InsertPhotoIcon from '@material-ui/icons/InsertPhoto';
import React, { useState } from 'react';
import { buildURL } from '~technical/ipfs';

import { IGeneratedCard } from '../interfaces';
import { ButtonLabel } from './ButtonLabel';

const label = 'See image on IPFS';

interface IProps {
  card: IGeneratedCard;
}

export const IPFSButton = ({ card }: IProps) => {
  const [nftImageURL] = useState(
    card.metadata.image ? buildURL(card.metadata.image) : null
  );

  return !nftImageURL ? null : (
    <Button
      title={label}
      color="secondary"
      href={nftImageURL}
      target="_blank"
      size="small"
      variant="contained"
    >
      <InsertPhotoIcon />
      <ButtonLabel label={label} />
    </Button>
  );
};
