import { processAthleteFilter } from '~technical/filters/athlete';
import { processNftBatchFilter } from '~technical/filters/batch';
import { processCompetitionFilter } from '~technical/filters/competition';
import { processCountryFilter } from '~technical/filters/country';
import { processCronFilter } from '~technical/filters/cron';
import { processFilterFilter } from '~technical/filters/filter';
import { processGenericFilter } from '~technical/filters/generic';
import { processLootTableFilter } from '~technical/filters/lootTable';
import { processMatchFilter } from '~technical/filters/match';
import { processCardFilter } from '~technical/filters/nftCard';
import { processPackConfigFilter } from '~technical/filters/packConfig';
import { processSaleFilter } from '~technical/filters/sale';
import { processNftSeasonFilter } from '~technical/filters/season';
import { processTeamFilter } from '~technical/filters/team';
import { processTranslationFilter } from '~technical/filters/translation';
import { processUserFilter } from '~technical/filters/user';

import { SPORT_CONTEXT } from '../constant';
import { buildQuery, fetchApi, submitData } from './api';
import { processAuctionFilter } from './filters/auction';

const SportDependentResources = [
  'gameweek',
  'competition',
  'sport',
  'formation',
  'leagues',
  'match',
  'modifiermatrix',
  'scorematrix',
  'nftbatch',
  'NftCard',
  'nftcardseason',
  'nftmarketplacesale',
  'nftspecialedition',
  'NftCardStatsView',
  'packConfig',
  'pack',
  'prize',
  'team',
  'athlete',
  'lootTable',
  'Filter',
  'auction',
];

type Value = string | number | object;

interface IMongoRecord {
  _id: Value;
  [field: string]: Value;
}

interface IReactAdminRecord {
  id: Value;
  [field: string]: Value;
}

function mapId(
  data: IMongoRecord | IMongoRecord[]
): IReactAdminRecord | IReactAdminRecord[] {
  if (data instanceof Array) {
    return data.map(mapId) as IReactAdminRecord[];
  }

  return {
    ...data,
    // eslint-disable-next-line no-underscore-dangle
    id: data._id,
  };
}

interface IPagination {
  page: number;
  perPage: number;
}

function getPagination({ perPage, page }: IPagination) {
  return {
    skip: perPage * (page - 1),
    limit: perPage,
  };
}

interface ISort {
  field: string;
  order: string | 'ASC' | 'DESC';
}

function getSort({ field, order }: ISort) {
  return {
    sort: `${order === 'ASC' ? '' : '-'}${field === 'id' ? '_id' : field}`,
  };
}

interface IFilters {
  [field: string]: Value;
}

function processFilter(resource: string, field: string, value: Value) {
  switch (resource) {
    case 'athlete':
      return processAthleteFilter(field, value);
    case 'cron':
      return processCronFilter(field, value);
    case 'match':
      return processMatchFilter(field, value);
    case 'competition':
      return processCompetitionFilter(field, value);
    case 'country':
      return processCountryFilter(field, value);
    case 'team':
      return processTeamFilter(field, value);
    case 'NftCard':
      return processCardFilter(field, value);
    case 'nftcardseason':
      return processNftSeasonFilter(field, value);
    case 'nftbatch':
      return processNftBatchFilter(field, value);
    case 'Filter':
      return processFilterFilter(field, value);
    case 'lootTable':
      return processLootTableFilter(field, value);
    case 'user':
      return processUserFilter(field, value);
    case 'sale':
      return processSaleFilter(field, value);
    case 'packConfig':
      return processPackConfigFilter(field, value);
    case 'auction':
      return processAuctionFilter(field, value);
    case 'translation':
      return processTranslationFilter(field, value);
    default:
      return processGenericFilter(field, value);
  }
}

export function processFilters(resource: string, filter: IFilters) {
  return Object.keys(filter).reduce(
    (acc, field) => ({
      ...acc,
      ...processFilter(resource, field, filter[field]),
    }),
    {}
  );
}

export function getQuery(resource: string, filter: IFilters) {
  const processedFilter = processFilters(resource, filter);
  const fullQuery =
    Object.keys(processedFilter).length === 0
      ? {}
      : {
          $and: Object.keys(processedFilter).map((field) => ({
            [field]: processedFilter[field],
          })),
        };

  return {
    query: JSON.stringify(fullQuery),
  };
}

function getSelect(resource: string) {
  const select: Record<string, 1 | 0> = {};

  switch (resource) {
    case 'gameweek':
      select['virtualMatches.score'] = 0;
      break;
    default:
  }

  return {
    select: JSON.stringify(select),
  };
}

interface IGetListParams {
  pagination: IPagination;
  sort: ISort;
  filter: IFilters;
}

function getTotal(response: Response) {
  const total = response.headers.get('X-Total-Count');

  return total ? parseInt(total, 10) : 0;
}

function isSportDependentResource(resource: string) {
  return SportDependentResources.includes(resource);
}

async function getList(
  resource: string,
  { pagination, sort, filter: rawFilter }: IGetListParams
) {
  const filter = rawFilter;
  if (isSportDependentResource(resource)) {
    filter.sport = SPORT_CONTEXT;
  }

  const query = {
    ...getPagination(pagination),
    ...getSort(sort),
    ...getQuery(resource, filter),
    ...getSelect(resource),
  };

  const response = await fetchApi(
    `/backoffice/${resource}?${buildQuery(query)}`
  );

  return {
    data: mapId(await response.json()),
    total: getTotal(response),
  };
}

interface IGetOneParams {
  id: Value;
}

async function getOne(resource: string, { id }: IGetOneParams) {
  const response = await fetchApi(`/backoffice/${resource}/${id}`);
  return {
    data: mapId(await response.json()),
  };
}

interface IGetManyParams {
  ids: Value[];
}

async function getMany(resource: string, { ids }: IGetManyParams) {
  const query = {
    query: JSON.stringify({
      _id: { $in: ids },
    }),
  };

  const response = await fetchApi(
    `/backoffice/${resource}?${buildQuery(query)}`
  );
  return {
    data: mapId(await response.json()),
  };
}

interface IGetManyReferenceParams {
  target: string;
  id: Value;
  pagination: IPagination;
  sort: ISort;
  filter: IFilters;
}

async function getManyReference(
  resource: string,
  { target, id, pagination, sort, filter }: IGetManyReferenceParams
) {
  const query = {
    ...getPagination(pagination),
    ...getSort(sort),
    ...processFilters(resource, {
      ...filter,
      [target]: id,
    }),
  };

  const response = await fetchApi(
    `/backoffice/${resource}?${buildQuery(query)}`
  );
  return {
    data: mapId(await response.json()),
    total: getTotal(response),
  };
}

interface ICreateParams {
  data: object;
}

async function create(resource: string, { data }: ICreateParams) {
  return {
    data: mapId(
      await submitData(`/backoffice/${resource}`, { ...data, _id: undefined })
    ),
  };
}

interface IUpdateParams {
  id: Value;
  data: object;
  previousData?: object;
}

async function update(resource: string, { id, data }: IUpdateParams) {
  return {
    data: mapId(
      await submitData(
        `/backoffice/${resource}/${id}`,
        { ...data, _id: undefined },
        'PATCH'
      )
    ),
  };
}

interface IUpdateManyParams {
  ids: Value[];
  data: object;
}

async function updateMany(resource: string, { ids, data }: IUpdateManyParams) {
  return {
    data: mapId(
      await Promise.all(
        ids.map((id) =>
          submitData(`/backoffice/${resource}/${id}`, data, 'PATCH')
        )
      )
    ),
  };
}

interface IDeleteParams {
  id: Value;
  previousData?: object;
}

async function deleteF(resource: string, { id, previousData }: IDeleteParams) {
  await fetchApi(`/backoffice/${resource}/${id}`, { method: 'DELETE' });

  return {
    data: {
      id,
      ...previousData,
    },
  };
}

interface IDeleteManyParams {
  ids: Value[];
}

async function deleteMany(resource: string, { ids }: IDeleteManyParams) {
  await Promise.all(ids.map((id) => deleteF(resource, { id })));

  return {
    data: ids.map((id) => ({ id })),
  };
}

export const dataProvider = {
  getList,
  getOne,
  getMany,
  getManyReference,
  create,
  update,
  updateMany,
  delete: deleteF,
  deleteMany,
};
