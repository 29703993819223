import { Typography } from '@material-ui/core';
import React from 'react';
import { TextInput } from 'react-admin';
import { useField } from 'react-final-form';

import { Flag } from './Flag';

export const CountryCodeInput = (props: {}) => {
  const state = useField('isoAlpha2Code');

  return (
    <>
      <TextInput {...props} source="isoAlpha2Code" />

      <Typography variant="subtitle1">Flag preview</Typography>
      <Flag countryCode={state.input.value} />
    </>
  );
};
