import { Button } from '@material-ui/core';
import React from 'react';
import {
  BooleanField,
  Datagrid,
  EditButton,
  List,
  TextField,
} from 'react-admin';
import { Link } from 'react-router-dom';

/* eslint-disable  no-underscore-dangle */

const Actions = (props: any) => {
  const { record } = props;
  return (
    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
      <EditButton {...props} />
      <Button
        color="primary"
        component={Link}
        to={{
          pathname: 'user',
          search: `filter=${JSON.stringify({
            cohorts: record._id,
          })}`,
        }}
      >
        Show users
      </Button>
    </div>
  );
};

export const UserCohortList = (props: {}) => (
  <List {...props} exporter={false}>
    <Datagrid>
      <TextField source="name" />
      <BooleanField source="publicInvite" />
      <Actions />
    </Datagrid>
  </List>
);
