import React from 'react';
import { Create, CreateProps } from 'react-admin';

import { TranslationForm } from './TranslationForm';

export const TranslationCreate = (props: CreateProps) => (
  <Create {...props}>
    {/* @ts-ignore */}
    <TranslationForm />
  </Create>
);
