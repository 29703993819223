import { Typography } from '@material-ui/core';
import React, { FC } from 'react';

import { getAthletesCount } from '../common';
import { ISavedNftBatch } from '../types';
import { Label, ShowRow, Value } from './styles';

interface IProps {
  record?: ISavedNftBatch;
}

const AthletesCount: FC<IProps> = ({ record }: IProps) => (
  <ShowRow>
    <Typography variant="body2" component="div">
      <Label>Athletes count</Label>
      <Value>{getAthletesCount(record)}</Value>
    </Typography>
  </ShowRow>
);

export default AthletesCount;
