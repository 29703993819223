/* eslint-disable no-underscore-dangle */
import { Typography } from '@material-ui/core';
import React, { useCallback } from 'react';
import {
  ArrayInput,
  AutocompleteInput,
  Create,
  CreateProps,
  DateTimeInput,
  InputProps,
  NumberInput,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  SimpleFormIterator,
  SimpleFormProps,
  TextInput,
  minValue,
  required,
  useDataProvider,
  useNotify,
  useRedirect,
} from 'react-admin';
import { useForm } from 'react-final-form';
import { Blockchain, Payment } from 'ultimate-league-common';
import { rarityLevelChoice } from '~business/common/assetTypes';
import { AthleteField } from '~technical/filters/athlete';
import { SafeNumberInput } from '~ui/SafeNumberInput';

import { SPORT_CONTEXT } from '../../constant';

const CardInput = ({ source }: InputProps) => {
  const { getFieldState, getState } = useForm();

  const { submitErrors } = getState();
  const idState = getFieldState(`${source}._id`);

  return (
    <>
      <ReferenceInput
        source={`${source}._id`}
        reference="NftCard"
        label="Card ID"
      >
        <TextInput source="_id" />
      </ReferenceInput>
      <ReferenceInput
        source={`${source}.athlete._id`}
        reference="athlete"
        label="Athlete"
        filterToQuery={(filter) => ({
          [AthleteField.JOKER]: filter,
        })}
        disabled={!!idState?.value}
        required={!idState?.value}
      >
        <AutocompleteInput
          optionText="matchName"
          optionValue="id"
          matchSuggestion={(filter: string, choice: any) =>
            !filter ||
            choice.firstName?.includes(filter) ||
            choice.lastName?.includes(filter) ||
            choice.matchName?.includes(filter)
          }
        />
      </ReferenceInput>
      <SelectInput
        source={`${source}.level`}
        choices={rarityLevelChoice.filter(
          (c) => c.id !== Blockchain.NFTCard.RarityLevel.FUNGIBLE
        )}
        label="Rarity"
        disabled={!!idState?.value}
        required={!idState?.value}
      />
      {submitErrors?.[source] && (
        <Typography variant="subtitle1" style={{ color: 'red' }}>
          {submitErrors?.[source]}
        </Typography>
      )}
    </>
  );
};

const AuctionForm = ({ ...props }: SimpleFormProps) => {
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const redirect = useRedirect();

  const handleSave = useCallback(
    async (data: any) => {
      try {
        const auction = await dataProvider.create('auction', {
          data: {
            sport: SPORT_CONTEXT,
            ...data,
          },
        });
        return redirect('edit', '/auction', auction.data.id);
      } catch (err: any) {
        if (err.body.cardIndex !== undefined) {
          return { [`cards[${err.body.cardIndex}]`]: err.body.message };
        }
        return notify(err.body?.message ?? `${err.body}`, 'error');
      }
    },
    [dataProvider, notify, redirect]
  );

  return (
    <SimpleForm {...props} save={handleSave}>
      <ArrayInput source="cards" validate={required()}>
        <SimpleFormIterator>
          {
            // @ts-ignore
            <CardInput />
          }
        </SimpleFormIterator>
      </ArrayInput>
      <DateTimeInput source="announcedAt" validate={required()} />
      <DateTimeInput source="startsAt" validate={required()} />
      <DateTimeInput source="expiresAt" validate={required()} />
      <SafeNumberInput
        label={`Starting Amount (${Payment.ActiveCurrency})`}
        source="startingAmount.fiat"
        validate={[minValue(1), required()]}
      />
      <NumberInput source="expiryIncrementMinutes" />
    </SimpleForm>
  );
};

export const AuctionCreate = (props: CreateProps) => (
  <Create {...props}>
    {
      // @ts-ignore
      <AuctionForm {...props} />
    }
  </Create>
);
