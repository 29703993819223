import React from 'react';
import styled from 'styled-components';

const StyledLabel = styled.span`
  margin-left: 0.5rem;
`;

export const ButtonLabel = ({ label }: { label: string }) => (
  <StyledLabel>{label}</StyledLabel>
);
