import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import React, { useCallback, useState } from 'react';
import {
  useListContext,
  useNotify,
  useRefresh,
  useUnselectAll,
} from 'react-admin';
import { Field, Form } from 'react-final-form';
import { tierChoices } from '~business/athlete/common';
import { dataProvider } from '~technical/dataProvider';

interface IValues {
  tier: number;
}

export const AthleteBulkEditButton = () => {
  const [showBulkEdit, setShowBulkEdit] = useState(false);
  const showDialog = useCallback(() => {
    setShowBulkEdit(true);
  }, [setShowBulkEdit]);

  const { selectedIds } = useListContext();

  const closeDialog = useCallback(() => {
    setShowBulkEdit(false);
  }, [setShowBulkEdit]);

  const refresh = useRefresh();
  const notify = useNotify();
  const unselectAll = useUnselectAll();
  const handleSubmit = useCallback(
    async (values: IValues) => {
      try {
        await dataProvider.updateMany('athlete', {
          ids: selectedIds,
          data: values,
        });
        unselectAll('athlete');
        refresh();
        notify('Athletes updated', 'info');
      } catch (e) {
        notify(`Error: Athletes not updated (${e.message})`, 'warning');
      }
    },
    [selectedIds, notify, refresh, unselectAll]
  );

  return (
    <>
      <Form onSubmit={handleSubmit}>
        {(form) => (
          <Dialog open={showBulkEdit} onClose={closeDialog}>
            <DialogTitle>
              Bulk edit
              {selectedIds.length} athletes
            </DialogTitle>
            <DialogContent style={{ minWidth: 300 }}>
              <InputLabel id="tier">Tier</InputLabel>
              <Field name="tier" type="number">
                {({ input }) => (
                  <Select labelId="tier" style={{ margin: 8 }} {...input}>
                    {tierChoices.map(({ id }) => (
                      <MenuItem key={id} value={id}>
                        {id}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              </Field>
            </DialogContent>
            <DialogActions>
              {form.submitting ? (
                <CircularProgress style={{ margin: 12 }} />
              ) : (
                <>
                  <Button onClick={closeDialog} color="secondary">
                    Cancel
                  </Button>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={form.handleSubmit}
                  >
                    Submit
                  </Button>
                </>
              )}
            </DialogActions>
          </Dialog>
        )}
      </Form>
      <Button color="primary" onClick={showDialog} startIcon={<EditIcon />}>
        Bulk edit
      </Button>
    </>
  );
};
