import { Database } from 'ultimate-league-common';
import { ISavedCardAsset } from '~business/common/assetTypes';
import { fetchAssetPath, fetchGetURL } from '~business/storage/service';

/**
 * Get the storage path of an asset
 */
export const getAssetPath = async (id: Database.DocumentId) =>
  (await fetchAssetPath({ id })).path;

/**
 * Get the url to load/display an asset
 */
export const getAssetUrl = async (id: Database.DocumentId) =>
  (await fetchGetURL({ id })).url;

export const getAssetsWithPath = (cardAssets: ISavedCardAsset[]) =>
  Promise.all(
    cardAssets.map(async ({ file, ...restAsset }) => ({
      ...restAsset,
      path: await getAssetPath(file),
    }))
  );

export const getAssetsWithUrl = (cardAssets: ISavedCardAsset[]) =>
  Promise.all(
    cardAssets.map(async ({ file, ...restAsset }) => ({
      ...restAsset,
      url: await getAssetUrl(file),
    }))
  );
