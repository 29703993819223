import React, { PropsWithChildren } from 'react';
import {
  ArrayField,
  AutocompleteArrayInput,
  ChipField,
  CloneButton,
  Datagrid,
  EditButton,
  Filter,
  List,
  ReferenceArrayInput,
  SingleFieldList,
  TextField,
  TextInput,
} from 'react-admin';
import { PrimitiveField } from '~ui/PrimitiveField';

const TranslationFilter = ({ children, ...props }: PropsWithChildren<{}>) => (
  <Filter {...props}>
    <TextInput source="key" />
    <ReferenceArrayInput label="Tags" source="tags" reference="translationTag">
      <AutocompleteArrayInput optionText="name" optionValue="name" />
    </ReferenceArrayInput>
    {children}
  </Filter>
);

const Actions = (props: {}) => (
  <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
    <CloneButton {...props} />
    <EditButton {...props} />
  </div>
);

export const TranslationList = (props: {}) => (
  <List
    filters={<TranslationFilter />}
    sort={{ field: '_id', order: 'DESC' }}
    exporter={false}
    {...props}
  >
    <Datagrid>
      <TextField source="key" />
      <ArrayField source="tags">
        <SingleFieldList linkType={false}>
          <PrimitiveField component={ChipField} />
        </SingleFieldList>
      </ArrayField>
      <Actions />
    </Datagrid>
  </List>
);
