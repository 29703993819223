import { Box, Typography } from '@material-ui/core';
import React from 'react';
import { Show, SimpleShowLayout } from 'react-admin';
import { Blockchain } from 'ultimate-league-common';

import { AddressField } from './AddressField';
import { ChampField } from './ChampField';
import { MgcField } from './MgcField';

const BlockchainField = (props: {}) => (
  <Box display="flex" style={{ marginBottom: 12 }}>
    {Blockchain.BrandConfig.activatedBlockchains.map((blockchain) => (
      <Box
        key={blockchain}
        display="flex"
        flexDirection="column"
        style={{
          padding: 12,
          border: '1px solid grey',
          marginRight: '12px',
          minWidth: 125,
        }}
      >
        <Typography variant="h6" style={{ marginBottom: 12 }}>
          {blockchain.toUpperCase()}
        </Typography>
        <ChampField {...props} source="address" blockchain={blockchain} />
      </Box>
    ))}
  </Box>
);

export const WalletShow = (props: {}) => (
  <Show {...props}>
    <SimpleShowLayout>
      <AddressField source="address" />
      <BlockchainField />
      <MgcField />
    </SimpleShowLayout>
  </Show>
);
