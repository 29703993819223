export interface IConfig {
  title: string;
  background: string;
  color: string;
}

const allConfig: Array<{ hosts: string[]; config: IConfig }> = [
  {
    hosts: ['localhost'],
    config: {
      title: '👨‍💻 Development 👨‍💻',
      background: 'white',
      color: 'black',
    },
  },
  {
    hosts: [
      'backoffice.develop.ultimate-champions.com',
      'api-2-goat-staging.uwl.fr',
    ],
    config: {
      title: '⚠️ Pre-production ⚠️',
      background: 'orange',
      color: 'black',
    },
  },
  {
    hosts: ['backoffice.production.ultimate-champions.com'],
    config: {
      title: '🚨 Production 🚨',
      background: 'black',
      color: 'red',
    },
  },
];

const serverURL = new URL(process.env.SERVER_BACKOFFICE || '');

export const config: IConfig | null =
  allConfig.find(({ hosts }) => hosts.includes(serverURL.hostname))?.config ??
  null;
