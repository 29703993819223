import { processGenericFilter, Value } from './generic';

export enum MatchField {
  TEAM = 'team',
  STATUS = 'status',
  // NB: DATE is handled by processGenericFilter
  DATE = 'date',
}

export function processMatchFilter(field: MatchField | string, value: Value) {
  switch (field) {
    case MatchField.TEAM:
      return {
        $or: [
          {
            homeContestant: {
              $in: value,
            },
          },
          {
            awayContestant: {
              $in: value,
            },
          },
        ],
      };
    case MatchField.STATUS:
      return {
        status: { $in: value },
      };
    default:
      return processGenericFilter(field, value);
  }
}
